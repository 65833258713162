// Spinners
.spining-avatar__matching__container--motion {
  border: .1rem solid dark($accent--1);
  border-radius: $radius--default;
  background-color: $color__white;
}

/*Animations*/
.spin-players {
    animation: spin-players .5s linear infinite;

    @media screen and (max-width: $break-point--768) {
        animation: spin-players-mobile .5s linear infinite;
    }
    @media only screen and (max-height: $break-point--480) {
        animation: spin-players-mobile .5s linear infinite;
    }
}

.stop-players-spin {
    animation: stop-players-spin .5s linear;

    @media screen and (max-width: $break-point--768) {
        animation: stop-players-spin-mobile .5s linear;
    }
    @media only screen and (max-height: $break-point--480) {
        animation: stop-players-spin-mobile .5s linear;
    }
}

@keyframes spin-players {
    0% {
        transform: translate3d(0, -800px, 0);
    }
    100% {
        transform: translate3d(0, -100px, 0);
    }
}

@keyframes spin-players-mobile {
    0% {
        transform: translate3d(0, -400px, 0);
    }
    100% {
        transform: translate3d(0, -100px, 0);
    }
}

@keyframes stop-players-spin {
    0% {
        transform: translate3d(0, -10px, 0);
    }
    20% {
        transform: translate3d(0, 5px, 0);
    }
    30% {
        transform: translate3d(0, -5px, 0);
    }
    60% {
        transform: translate3d(0, 2px, 0);
    }
    80% {
        transform: translate3d(0, -1px, 0);
    }
    100% {
        transform: translate3d(0, 0px, 0);
    }
}
