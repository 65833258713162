.custom-blitz-room__container {
    width: 44rem;
    height: 25rem;
    display: flex;
    flex-direction: column;
    @include container--colored($container--4, 0.9);
    overflow: hidden;
    padding: 0.2rem 0;
    position: relative;
    margin: 0 auto;
    max-width: calc(100% - 2rem);

    &.isDestroyed {
        border: none !important;
    }
}

.custom-blitz-room__container__destroyed--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($color__black, .3);
    border-radius: $radius--default;
}

.custom-blitz-room__info__container {
    height: 45%;
    width: 100%;
    display: flex;
}

.custom-blitz-room__img__container {
    width: 25%;
    height: 100%;
}

.custom-blitz-room__img {
    width: calc(100% - 2rem);
    margin: 1rem;
    @media screen and (max-width: $break-point--320) {
        width: calc(100% - 1rem);
        margin: .5rem;
    }
}

.custom-blitz-room__main-info {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: .5rem;
}

.custom-blitz-room__tournament--counter__container {
    width: 15%;
    height: 100%;
    position: relative;
}

.custom-blitz-room__tournament--counter {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: $black__transparent--03;
    padding: .5rem 1rem;
    border-radius: 2rem;
    text-align: center;
}

.custom-blitz-room__transaction--container {
    height: calc(35% - 2rem);
    width: calc(100% - 2rem);
    display: flex;
    background-color: transparentize($color__black, .7);
    border-radius: $border--radius;
    margin: 1rem;
}

.custom-blitz-room__rewards--container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.custom-blitz-room__reward {
    display: flex;
    align-items: center;
    height: 50%;
    > * {
        margin: .2rem 1rem;
        @media screen and (max-width: $break-point--360) {
            margin: .2rem .4rem;
        }
    }
}

.custom-blitz-room__entry--fee {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-blitz-room__controls {
    height: 20%;
    width: calc(100% - 1rem);
    display: flex;
    margin: 0 0.5rem;
}

.custom-blitz-room__control--rules {
    width: 40%;
    padding: 0 .5rem;
}

.custom-blitz-room__control--view {
    width: 60%;
    padding: 0 .5rem;
}

.custom-blitz-room__tournament--name {
    color: $color--chips;
    font-size: $text__size--large;
    @media screen and (max-width: $break-point--440) {
        font-size: $text__size--normal;
    }
}

.custom-blitz-room__tournament--expiry--date {
    @extend %text;
    text-align: left;

    > span {
        color: $color--chips;
        margin-right: .5rem;
    }
}

.custom-blitz-room__tournament--creator {
    @extend %text;
    text-align: left;

    > span {
        color: $color--chips;
    }
}

.custom-blitz-room__place {
    font-size: $text__size--large;
}

.custom-blitz-room__reward--value {
    font-size: $text__size--large;
}

.currency-component-host {
    font-size: 2.5rem;
}

.custom-blitz-room__entry--fee__text {
    font-size: $text__size--large;
}

.custom-blitz-room__tournament--club__name {
    @extend %text;
    text-align: left;

    > span {
        color: $color--chips;
    }
}

.custom-blitz-room__transaction--separator {
    display: none;
}