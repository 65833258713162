.progress-bar-color-animation-inner {
    animation: barColorAnimation 2s infinite;
}

@keyframes barColorAnimation {
    0% {
        background-image:
            linear-gradient(to right, $white__transparent--07, $accent--1, $accent--1, $accent--1, $accent--1);
    }
    5% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $accent--1, $accent--1, $white__transparent--07);
    }
    10% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $accent--1, $white__transparent--07, $accent--1);
    }
    15% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $white__transparent--07, $accent--1, $accent--1);
    }
    20% {
        background-image:
            linear-gradient(to right, $accent--1, $white__transparent--07, $accent--1, $accent--1, $accent--1);
    }
    25% {
        background-image:
            linear-gradient(to right, $white__transparent--07, $accent--1, $accent--1, $accent--1, $accent--1);
    }
    30% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $accent--1, $accent--1, $white__transparent--07);
    }
    35% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $accent--1, $white__transparent--07, $accent--1);
    }
    40% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $white__transparent--07, $accent--1, $accent--1);
    }
    45% {
        background-image:
            linear-gradient(to right, $accent--1, $white__transparent--07, $accent--1, $accent--1, $accent--1);
    }
    50% {
        background-image:
            linear-gradient(to right, $white__transparent--07, $accent--1, $accent--1, $accent--1, $accent--1);
    }
    55% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $accent--1, $accent--1, $white__transparent--07);
    }
    60% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $accent--1, $white__transparent--07, $accent--1);
    }
    65% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $white__transparent--07, $accent--1, $accent--1);
    }
    70% {
        background-image:
            linear-gradient(to right, $accent--1, $white__transparent--07, $accent--1, $accent--1, $accent--1);
    }
    75% {
        background-image:
            linear-gradient(to right, $white__transparent--07, $accent--1, $accent--1, $accent--1, $accent--1);
    }
    80% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $accent--1, $accent--1, $white__transparent--07);
    }
    85% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $accent--1, $white__transparent--07, $accent--1);
    }
    90% {
        background-image:
            linear-gradient(to right, $accent--1, $accent--1, $white__transparent--07, $accent--1, $accent--1);
    }
    95% {
        background-image:
            linear-gradient(to right, $accent--1, $white__transparent--07, $accent--1, $accent--1, $accent--1);
    }
    100% {
        background-image:
            linear-gradient(to right, $white__transparent--07, $accent--1, $accent--1, $accent--1, $accent--1);
    }
}