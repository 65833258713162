/*************************************
*    PromoTournament skin            *
*************************************/
// Styles
.pl__wrapper {
  @include scrollbar();
}

.pl__container--professionals {
  &:after {
    @include separator( orange );
  }
}
