.user-input__input {
  @include scrollbar();
  background: $black__transparent--03;
  color: $white__transparent--05;
  border: .1rem solid transparentize($accent--1, .5);

  &:active, &:focus {
    color: $color__white;
    border: .1rem solid $accent--1;

    ~ .user-input__icon {
      color: $color--selected !important;
    }
  }

    &.dirty {
        color: $color__white;
    }
}

.user-input__icon {
  font-size: $text__size--normal;
  color: $white__transparent--05;
}

.user-input__error {
  @extend %text;
  background: $color__negative;
  color: $color__white;

  &:before {
    border-color: transparent transparent #b80e0e;
    border-style: solid;
    border-width: 0 .5rem .5rem;
  }
}

.user--input__host {
  height: 4rem;
  width: 100%;
  text-align: left;
  @media only screen and (max-width: $break-point--768) {
      height: 3rem;
      .user-input__input {
          font-size: $text__size--normal;

          &.input--has-icon {
              padding-left: 3.5rem;
          }
      }
      .user-input__icon {
          font-size: $text__size--normal;
          height: 3rem;
          width: 2rem;

          &:after {
              font-size: $text__size--large;
          }
      }
      .user-input__clear {
          width: 3rem;
          height: 3rem;
          right: .3rem;
      }
  }

}