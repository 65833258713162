.casino--room__text--container {
    background-color: $black__transparent--07;
}

.casino--room__text--name {
    @extend %text;
}

.casino--room__wrapper {
    border-radius: $border--radius;
    background-image: url(imageUrl("lobby/BettingWheel.jpg"));
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}