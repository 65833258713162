.avatar-image-notification__container--title {
    color: $accent--1;
}

.avatar-image-notification__text--notification-info {
    @extend %text;
    // TODO check if needed
    //@at-root .lang-ar &,
    //    .lang-fa & {
    //    padding-right: 2rem;
    //}

    > span {
        color: $color__chips;
    }
}

.avatar-image-notification__container--image-circle-inner {
    border: 0.2rem solid $accent--1;
    background-color: $black__transparent--03;
}

.avatar-image-notification__username {
    &.verified {
        position: relative;
        padding-right: 1.3em;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 1.2em;
            height: 1.2em;
            transform: translateY(-50%);
            background-image: url(imageUrl("username/VerifiedIcon.svg"));
        }
    }
}
