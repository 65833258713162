/***********************
*    Gifts Tab skin    *
***********************/

// Styles
.gifts-tab__container--premium-gifts {
    border-radius: $border--radius;
    border: .1em solid $accent--1;
}

.gifts-tab__wrapper--gift-item {
    .price-container {
        .price-container-inner {
            > p {
                @extend %text;

                &:not(.multiple-receivers) {
                    @include chip-sign(1.4rem);
                }
            }
        }
    }
}

.gifts-tab__wrapper {
    border-radius: $radius--default;
}

.gifts-tab__container {
    @include scrollbar();
}
