.popup-container__wrapper--content {
    &.progressive-ads {
        background-color: #273a84;
    }

    &.free-rewards-popup__popup-container {
        background: #273a68 $background__pattern repeat center;
        background-size: 15rem;
    }
}

.popup__title-bar {
    &.progressive-ads {
        background-color: #264889;
    }
}