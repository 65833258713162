/***************************
*   Settings popup skin    *
***************************/

// Styles
.filter-popup__container--reward {
    .filter-popup__text--reward-value {
        @include chip-sign( 2.4rem );
        &:before {
            left: -2.6rem;
        }
    }
    .filter-popup__text--reward-label {
        @extend %text;
    }
}

.filter-popup__container--options {
    @include scrollbar();
}

.filter-popup__container--toggle {
    .filter-popup__text--toggle-label {
        @extend %text;
        @include label-icon( 2.4rem );
        text-align: left;
    }
}

.filter--popup--cmp-class {
    max-height: 55rem !important;
    &.ios {
        @media screen and (max-width: $break-point--568) {
            max-height: calc(100% - 12rem) !important;
        }
    }
}