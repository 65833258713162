.level-up-rewards-img--wrapper {
    display: flex;

    &.claim-animation {
        animation: claimScale 1s ease-in-out;
    }
}

@keyframes claimScale {
    0% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.level-up-rewards-img__overlay {
    filter: brightness(0.4);
}

.level-up-rewards-img--animation {
    transition: filter 0.2s linear;
    filter: brightness(1);
}

.level-up-rewards-img__level {
    position: absolute;
    left: 48%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.7rem;
    font-weight: $font-weight-bold;
    font-style: italic;
}

.level-up-rewards--level-bronze {
    text-shadow: 0 2px 1px #933d14, 0px 4px 8px black;
}

.level-up-rewards--level-silver {
    text-shadow: 0 2px 1px #3c3c3c, 0px 3px 3px black;
    font-size: 2.5rem;
    top: 57%;
}

.level-up-rewards--level-gold {
    text-shadow: 0 2px 1px #ac3c00, 0px 3px 4px black;
}

.level-up-rewards--level-diamond {
    text-shadow: 0 2px 1px #0090ff, 0px 4px 5px black;
}

.level-up-rewards--level-amethyst {
    text-shadow: 0 2px 1px #be007a, 0px 3px 3px black;
}

.particle-animation-canvas {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.level-up-rewards-img {
    @include aspect-ratio(0.725, 1);
}

.level-up-rewards-img__under-level,
.level-up-rewards-img__claim-border {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.level-up-rewards-img__claim-border {
    &.fade-out-animation {
        animation: fadeOutClaimBorder 0.2s ease-in-out;
    }
}

@keyframes fadeOutClaimBorder {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@media screen and (max-width:$break-point--480) {
    .level-up-rewards-img__level {
        font-size: 2.5rem;
    }

    .level-up-rewards--level-silver {
        font-size: 2.3rem;
    }
}

@media screen and (max-width:$break-point--380) {
    .level-up-rewards-img__level {
        font-size: 2.3rem;
    }

    .level-up-rewards--level-silver {
        font-size: 2.2rem;
    }
}

@keyframes levelUpScale {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.1);
    }

    80%{
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}

.level-up-rewards-img-wrapper-scale {
    animation: levelUpScale 0.8s ease-in-out;
}