/**************************
*   Default placeholders  *
**************************/
$black__global: #111111;
$white__global: #ffffff;

%link {
    @extend %text;
    text-transform: initial;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}

// Placeholders
%stroked {
    text-shadow: -.1rem -.1rem 0 $black__global,
    .1rem -.1rem 0 $black__global,
    -.1rem .1rem 0 $black__global,
    .1rem .1rem 0 $black__global;
}

%text {
    @at-root .lang-ar &,
    .lang-fa & {
        direction: rtl;
        font-style: normal;
    }
    color: #ffffff;
    text-orientation: upright;
    user-select: none;
}

%number {
    color: $white__global;
    text-orientation: upright;
    user-select: none;
}

%background--image {
    background: no-repeat center;
    background-size: 100% 100%;
}

%direction-change {
    @at-root .lang-ar &,
    .lang-fa & {
        direction: rtl;
    }
}

$itemRays: imageUrl("game-summary/rays-effect.png");

:root {
    --rays-animation: url(#{$itemRays});
}

%rays-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    height: 200%;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: var(--rays-animation);
        background-position: 50%;
        animation: rotateRays 10s linear infinite;
        will-change: transform;
    }

    @keyframes rotateRays {
        from {
            transform: rotateZ(0deg);
        }
        to {
            transform: rotateZ(360deg);
        }
    }
}
