.balance-box__animate {
    animation: zoomInAndOutAndDisappear 3s linear forwards;
}

@keyframes zoomInAndOutAndDisappear {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    99% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
    }
}