@use "sass:math";

$bullet-point-width: 0.8rem;

$title-font-size: $text__size--large + 0.2rem;
$bullet-font-size: $text__size--normal + 0.2rem;

$title-font-size-mobile: $text__size--large;
$bullet-font-size-mobile: $text__size--normal;

$bullet-text-min-height: 2rem;
$bullet-text-min-height-mobile: 1.6rem;

.text-parser__content {
    @include scrol-container();
    @include touch-scroll();
    @include scrollbar();
    @extend %text;
}

.text-parser__section--title {
    font-size: $title-font-size;
    color: $color__chips;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $break-point--568) {
        font-size: $title-font-size-mobile;
    }
}

.text-parser__separator {
    position: relative;

    &:before {
        @include separator(yellow);
    }

    &.text-parser-section__separator {
        &:before {
            top: 50%;
            bottom: initial;
            transform: translateY(-50%);
        }
    }
}

.text-parser__bullet {
    width: $bullet-point-width;
    height: $bullet-point-width;
    margin: math.div($bullet-text-min-height, 2) - math.div($bullet-point-width, 2) .5rem 0 .5rem;
    background-color: $color__chips;

    @media screen and (max-width: $break-point--568) {
        margin-top: math.div($bullet-text-min-height-mobile, 2) - math.div($bullet-point-width, 2);
    }

    @if ($skin-font-family == "Cairo") {
        margin-top: $bullet-text-min-height - $bullet-point-width;

        @media screen and (max-width: $break-point--568) {
            margin-top: math.div($bullet-text-min-height, 2);
        }
    }
}

.text-parser__bullet-text, .text-parser__text {
    font-size: $bullet-font-size;
    min-height: $bullet-text-min-height;

    > span {
        color: $color__chips;
    }

    @media screen and (max-width: $break-point--568) {
        font-size: $bullet-font-size-mobile;
        min-height: $bullet-text-min-height-mobile;
    }
}
