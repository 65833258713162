$border-color: #131e35;
$bg-color: lighten(#192540, 5%);
$completed-color: #81c14b;

.currency__baloon {
    color: $color__chips !important;

    @media screen and (max-width: $break-point--768),
        screen and (max-height: $break-point--568) {
        font-size: $text__size--small !important;
    }
}

.currency__baloon--value-config-bclass {
    color: $color__chips !important;
}

.currency__baloon--item-container-config-bclass {
    margin: 0 !important;
}

.currency__baloon--value-config-icon-bclass {
    @media screen and (max-width: $break-point--768),
        screen and (max-height: $break-point--568) {
        font-size: $text__size--normal !important;
    }
}

.games__progress--game-line {
    border: 2px solid $border-color;
    background-color: $bg-color;
    transition: border-color ease 0.4s, background-color ease 0.4s;

    &.completed {
        background-color: $completed-color;
        border-color: $completed-color;
    }
}

.games__progress--game-line-completed {
    border: 2px solid $completed-color;
    background-color: $completed-color;
}

.game__index--arrow {
    color: $color__chips;
    transform: translate(-50%, 0px) rotate(90deg) scale(1, 1);
    font-size: 2.3rem !important;
    animation: arrow-animation 0.9s ease infinite forwards;

    @media screen and (max-width: $break-point--768),
        screen and (max-height: $break-point--568) and (orientation: portrait) {
        font-size: 1.5rem !important;
        transform: translateX(-50%) rotate(90deg);
    }
}

@keyframes arrow-animation {
    0% {
        transform: translate(-50%, 0px) rotate(90deg) scale(1, 1);
    }
    50% {
        transform: translate(-50%, 30%) rotate(90deg) scale(0.8, 0.7);
    }
    100% {
        transform: translate(-50%, 0px) rotate(90deg) scale(1, 1);
    }
}
