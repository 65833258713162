.message__preview--text {
    color: $chat__topic-message--color;
}

.message__preview--icon {
    color: $chat__icons--color;
}

.message__preview-container {
    @extend %text;
}