/********************************
*      Buy item box skin        *
********************************/

.item-buy-button__main {
  width: 100%;
  .item-buy-button__text {
    font-size: 1.1em;
    @media only screen and (max-width: $break-point--440) {
      font-size: $text__size--normal;
    }
    &.bgn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .currency {
        order: 2;
        text-transform: lowercase;
        margin: 0 .3rem;
      }
      .price {
        order: 1;
      }
    }
    .chips {
      color: $color__white;
        font-size: inherit !important;
    }
    .gems {
      color: $color__white;
        font-size: inherit !important;
    }
  }

  .item-buy-button__text {
    @extend %text;
    direction: ltr !important;
  }
}