.custom-select-wrapper__container__width {
    width: auto !important;
    z-index: $layer--2;
}

.custom-select-wrapper__background {
    z-index: $layer--2;
}

.custom-select-content {
    z-index: $layer--2;
    width: 94%;
    margin-left: 3%;
    @include container--colored($container-dark);
    @include scrollbar();
    background-color: $main;
    border: .1rem solid $accent--1;
    padding: 1rem;
}

.custom-select-content__top {
    border-radius: .8rem .8rem 0 0;
    border-bottom: .1rem solid transparent;
}

.custom-select-content__bottom {
    border-radius: 0 0 .8rem .8rem;
    border-top: .1rem solid transparent;
}

.custom-select-wrapper__container {
    z-index: $layer--2;
}