/***************************
*     Room options skin    *
***************************/

// Styles
.cgr__text--value {
  @extend %text;
  @include label-icon( 1.8rem );
  &:before {
    left: -2.3rem !important;
  }
}
.cgr__text--value--sek {
  @extend %text;
  margin-left: .2rem;
}
