@use "sass:math";

@mixin stamp-bordered-text($map: $stamp-bordered-text-data) {
    $merged: map-merge-deep($stamp-bordered-text-data, $map);

    $text-padding: map-get($merged, text-padding);
    $text-margin: map-get($merged, text-margin);

    --stamp-text-padding: #{$text-padding};
    --stamp-text-margin: #{$text-margin};
}

$stamp-bordered-text-data: (
    text-padding: none,
    text-margin: 0.2rem,
);