.ranked-tile-component {
    border-radius: $border--radius;
    background: linear-gradient(90deg, #252a74, #454dc6 50%, #252a74);
    border-top: 2px solid #7180d9;
}

.ranked-room-tile__play-bclass {
    >p {
        font-size: 1.8em !important;

        @media screen and (max-width: $break-point--568) {
            font-size: 1.6em !important;
        }
    }
}

.ranked-room-tile__info-bclass {
    color: #fabd3c;
    font-size: 1.4em;

    >p,
    .bc__button--icon-cmp {
        color: #fabd3c !important;
        text-shadow: 0 -1px 0 #fed702, 0 1px 1px #000 !important;
        font-weight: $font-weight-normal !important;
        text-decoration: underline;
        text-underline-offset: 5px;
        font-size: inherit !important;
        text-transform: uppercase;
    }
}

.ranked-room-tile__entry {
    font-size: 2em;
    text-shadow: 0 2px 2px #000 !important;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.7em;
    }
}

.ranked-room-tile__play-btn--wrapper {
    height: 6.5rem;
    width: 80%;
    max-width: 31rem;
    background-color: #00000021;
    box-shadow: inset 0px 2px 2px 0px $black__transparent--06,
    0px 1px 0 #5c69d9;
    padding: 0.4rem;
    border-radius: $button-border-radius;

    @media screen and (max-width: $break-point--568) {
        height: 6rem;
        max-width: 27rem;
    }
}

.ranked-room-tile__entry--text-class {
    font-weight: $font-weight-normal;
    text-transform: initial;
}

.ranked-room-tile__inner-glow {
    border-left: 1px solid #4854ff;
    border-right: 1px solid #4854ff;
    box-shadow: inset 0 -1px 25px -5px #4e57f1;
}

.ranked-room-tile__entry--currency-class {
    text-shadow: initial;
}

.ranked-room-tile__sessions-info {
    font-size: 2rem;
}

.ranked-tile-top-bar__vip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: $font-weight-extra-bold;
    text-transform: uppercase;
    background: linear-gradient(#53565c, #000000);
    padding: 0.2rem 1.5rem;
    border-top: 2px solid #61656b;
    box-shadow: 0 2px 2px 0 $black__transparent--05;
}