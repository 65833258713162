@use "sass:math";

@mixin item-reward($map: $item-reward-data) {
    $merged: map-merge-deep($item-reward-data, $map);

    $inner-content-padding: map-get($merged, inner-content-padding);
    $img-wrapper-height: map-get($merged, img-wrapper-height);

    $aspect-ratio-width: map-get($merged, aspect-ratio-width);
    $aspect-ratio-height: map-get($merged, aspect-ratio-height);
    $item-img-width: map-get($merged, item-img-width);
    $item-table-img-width: map-get($merged, item-table-img-width);
    $item-reward-justify-content: map-get($merged, item-reward-justify-content);
    $item-chips-img-width: map-get($merged, item-chips-img-width);
    $item-gems-img-width: map-get($merged, item-gems-img-width);

    --inner-content-padding: #{$inner-content-padding};
    --img-wrapper-height: #{$img-wrapper-height};
    --aspect-ratio: #{math.div($aspect-ratio-height, $aspect-ratio-width) * 100%};
    --item-img-width: #{$item-img-width};
    --item-table-img-width: #{$item-table-img-width};
    --item-chips-img-width: #{$item-chips-img-width};
    --item-gems-img-width: #{$item-gems-img-width};
    --item-reward-justify-content: #{$item-reward-justify-content};
}

$item-reward-data: (
    inner-content-padding: 1rem 0,
    img-wrapper-height: 100%,
    aspect-ratio-width: 0.725,
    aspect-ratio-height: 1,
    item-img-width: 75%,
    item-table-img-width: 50%,
    item-chips-img-width: 70%,
    item-gems-img-width: 70%,
    item-reward-justify-content: space-around
);