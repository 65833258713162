/********************************************
*            Custom bet styles skin         *
********************************************/

// Variables
$color__background: $main;
$bet__color: $color--chips;

.custom-bet__container--bet-dynamic {
    border-radius: $radius--default;
    background-color: rgba(0, 0, 0, .26);
    width: 30rem;

    @media only screen and (max-width: $break-point--568) and (orientation: landscape) {
        width: 100%;
    }
    .custom-bet__container--bet-win-dynamic {
        &.bet {
            width: 20rem;
            @media only screen and (max-width: $break-point--568) and (orientation: landscape) {
                width: 16rem;
                min-width: 16rem;
            }
        }
    }
}

.custom-bet--host {
    z-index: 2;
}

.custom-bet__text--label-dynamic {
    @extend %text;
    position: relative;

    &.bet {
        color: $color__white;
    }
}

.custom-bet__text--numbers-dynamic {
    width: 20rem;
    &.bet {
        width: 20rem;
        min-width: 20rem;
        @media only screen and (max-width: $break-point--568) and (orientation: landscape) {
            width: 16rem;
            min-width: 16rem;
        }
        .scroll-bet__value {
            font-size: $text__size--large;
            padding: 0.5rem 0;
            line-height: 1.2 !important;
            @media only screen and (max-width: $break-point--480 ) {
                font-size: $text__size--normal;
            }
        }
    }

    .scroll-reward__label {
        @extend %direction-change;
        font-size: $text__size--normal;

        &:after {
            @include separator(yellow);
            bottom: auto;
            top: -.3rem;
        }

        .scroll-reward__value {
            font-size: $text__size--normal;
        }
    }

    .scroll-pool-name {
        @extend %direction-change;
    }
}