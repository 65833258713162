/*****************************
*       Gdpr popup skin      *
******************************/

.gdpr-popup__wrapper {
  @include scrollbar();
}

.gdpr-popup__text--bottom {
  > a {
    color: $accent--1;
    text-decoration: underline;
  }
}

.gdpr-popup__text--upper {
  @extend %text;
}

.gdpr-popup__container--checkbox-accept-refuse {
  .accept {
    @extend %text;
    @at-root .lang-ar &,
    .lang-fa & {
      margin: 0 1rem !important;
    }
  }
  .refuse {
    @extend %text;
    @at-root .lang-ar &,
    .lang-fa & {
      margin: 0 1rem !important;
    }
  }
}

.title-text {
  @extend %text;
  text-align: left;
}

.gdpr-popup__text--bottom {
  @extend %text;
  text-align: left;
}
