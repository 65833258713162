/*******************************************
*          Professionals avatars  skin     *
*******************************************/

// Variables
$badge-gold: imageUrl( 'professionals/badge-gold.png');
$badge-silver: imageUrl( 'professionals/badge-silver.png');
$badge-bronse: imageUrl( 'professionals/badge-bronze.png');

$large: 'large';
$medium: 'medium';
$small: 'small';

// Mixins
@mixin frame-image($source) {
  background-image: url($source);
  background-position: 0;
  background-size: 102% 100%;
  background-repeat: no-repeat;
}

@mixin glory($type) {
  .lobby__professional--avatar-container {
    position: relative;
    margin: 0;
    &:nth-of-type(1) {
      margin-top: 0;
      order: 2;
      @if $type == $large {
        width: 12rem;
        height: 13rem;
      } @else if $type == $medium {
        width: 10.5rem;
        height: 11.5rem;
        .lobby__professionals--badge-container {
          .lobby__professionals--username {
            font-size: $text__size--small;
          }
        }
      } @else if $type == $small {
        width: 8rem;
        height: 9rem;
        .lobby__professionals--badge-container {
          .lobby__professionals--username {
            font-size: $text__size--smaller;
          }
        }
      }
      .lobby__professionals--badge-container {
        @include frame-image( $badge-gold );
        background-size: 100% 100%;
      }
    }
    &:nth-of-type(2) {
      order: 1;
      margin-top: .5rem;
      @if $type == $large {
        width: 11rem;
        height: 12rem;
      } @else if $type == $medium {
        width: 9.5rem;
        height: 10.5rem;
        .lobby__professionals--badge-container {
          height: 38%;
          .lobby__professionals--username {
            font-size: $text__size--small;
          }
        }
      } @else if $type == $small {
        width: 7rem;
        height: 8rem;
        .lobby__professionals--badge-container {
          height: 40%;
          .lobby__professionals--username {
            font-size: $text__size--smaller;
          }
        }
      }
      .lobby__professionals--badge-container {
        @include frame-image( $badge-silver );
      }
    }
    &:nth-of-type(3) {
      order: 3;
      margin-top: .5rem;
      @if $type == $large {
        width: 11rem;
        height: 12rem;
      } @else if $type == $medium {
        width: 9.5rem;
        height: 10.5rem;
        .lobby__professionals--badge-container {
          height: 40%;
          .lobby__professionals--username {
            font-size: $text__size--small;
          }
        }
      } @else if $type == $small {
        width: 7rem;
        height: 8rem;
        .lobby__professionals--badge-container {
          .lobby__professionals--username {
            font-size: $text__size--smaller;
          }
        }
        .lobby__professionals--avatar-frame {
          height: calc(100% - 2rem);
        }
      }
      .lobby__professionals--badge-container {
        @include frame-image( $badge-bronse );
      }
    }
    .lobby__professionals--avatar-frame {
        position: absolute;
        top: 0;
        left: 0% !important;
        width: 100%;
        height: calc(100% - 2rem);
        padding: 0 .5rem;
    }
  }
}

@mixin glory__container($type) {
  @include glory($type);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  margin: 0rem auto 0;
}

// Styles
.lobby__professional--wrapper {
  &.default{
    @media only screen and (min-height: $break-point--900 ) {
      @include glory__container($large);
    }
    @media only screen and (min-width: $break-point--480 ) {
      @include glory__container($large);
    }
    @media only screen and (max-height: $break-point--900 ) and (orientation: landscape) {
      @include glory__container($medium);
    }
    @media only screen and (max-height: $break-point--768 ) and (orientation: landscape) {
      @include glory__container($small);
    }
    @media only screen and (max-width: $break-point--1024 ) and (orientation: portrait) {
      @include glory__container($medium);
    }
    @media only screen and (max-width: $break-point--480 ) and (orientation: portrait) {
      @include glory__container($medium);
    }
    @media only screen and (max-width: $break-point--320 ) and (orientation: portrait) {
      @include glory__container($small);
    }
    @media only screen and (max-height: $break-point--768 ) and (orientation: portrait) {
      @include glory__container($medium);
    }
    @media only screen and (max-height: $break-point--680 ) and (orientation: portrait) {
      @include glory__container($small);
      margin: .3rem auto 0;
    }
  }
  &.large {
    @include glory__container($large);
  }
  &.medium {
    @include glory__container($medium);
    &:not(.leaderboards) {
      @media only screen and (max-width: $break-point--440) {
        @include glory__container($small);
        margin: .3rem auto 0;
      }
    }
  }
  &.small {
    @include glory__container($small);
    margin: .3rem auto 0;
  }
  &.leaderboards {
    @media only screen and (min-width: $break-point--1280) {
      @include glory__container($large);
    }
    @media only screen and (max-width: $break-point--480) {
      @include glory__container($small);
    }
  }
}

.lobby__professionals--username {
  text-shadow: 0.1rem 0.1rem 0.1rem $black__transparent--03;
}

.lobby__professionals--place {
    display: none;
    text-align: center;
    color: $color__chips;
    font-weight: bold;
    text-shadow: 0.1rem 0.1rem 0.1rem $black__transparent--03;
    font-size: $text__size--normal;
}