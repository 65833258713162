.add-button_button {
    @include touch-hover() {
        border: 0.1rem solid $accent--1;
        border-radius: $border--radius;
    }
}

.add-button__icon {
    color: $accent--1
}

.add__button-host {
    background-color: transparentize($color__black, .7);
    border-radius: $border--radius;
}
