/*********************************
*    Setting default font sizes  *
*********************************/

// Variables
$text__size--x-large: 2.4rem;
$text__size--large: 1.8rem;
$text__size--normal: 1.4rem;
$text__size--small: 1.2rem;
$text__size--smaller: 1rem;
$text__size--x-small: .8rem;
