/******************************************
*              Gallery skin               *
******************************************/
.gallery--photo__delete-button,
.gallery--photo__report-button,
.gallery--photo__change-avatar-button {
    color: $accent--1;
}

// Variables
// Styles
.gallery__container--main {
    @include scrollbar();
}

.gallery__container--inner-image {
    @include aspect-ratio(1, 1);
    .gallery__container--add-button {
        background-color: #1d3a63;
        overflow: hidden;
        font-size: 6rem !important;
        @media only screen and (max-width: $break-point--440) {
            font-size: 5rem !important;
        }
    }
}

.gallery__container--add-button__icon {
    color: $color--white !important;
}
