@import "../../../../../styles/global/mixins/_mixins.scss";

.level-rewards--wrapper {
    margin-bottom: 5rem;
}

.level-rewards__level-up-rewards-img {
    position: relative;
}

.level-rewards-dot {
    border-radius: 100%;
    background-color: #4669ba;
    position: relative;
    box-shadow: 0px -1px 0px 0px #bee5ff;
    transition: filter 0.2s linear;

    &.overlay {
        filter: brightness(0.5);
    }

    &.animate {
        filter: brightness(1);
    }
}

.level-rewards--container {
    background-color: #052531;
    border: 3px solid #0a4255;
}