.custom-blitz-table--container {
    @include container--colored($container--4, 0.9);
    overflow: hidden;
    padding: 0.2rem 0;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between !important;
    max-width: 50rem;
}

.custom-blitz-table--header {
    background-color: transparentize($color__black, 0.74);
    @extend %text;
}

.custom-blitz-table--container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between !important;
}

.custom-blitz-table__state {
    border-radius: 10rem;
    padding: 0.5rem 1.5rem;
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.custom-blitz-table--seat {
    @include aspect-ratio(1, 1);
}

.custom-blitz-table__winners-info__footer {
    background-color: transparentize($color__black, 0.74);
    border: 1px solid $color__chips;
    border-radius: 0 0 $border--radius $border--radius !important;
    border-top: 0;
    @extend %text;
    > span {
        margin: 0 .5rem !important;
    }
}

.custom-blitz-table__with--footer {
    margin-bottom: 5rem !important;
    overflow: visible !important;
    height: unset !important;
}

.custom-blitz-table--team__colon {
    font-size: $text__size--large;
}