.apib__wrapper {
    @extend %container--bordered;
    min-height: initial;
    border-radius: 2rem;
    background-color: transparent;
}

.apib__container--title,
.apib__container--description {
    @extend %text;
}

.apib__reward {
    width: calc(25% - 2rem);
    margin: 1rem;
    font-size: 1.2rem !important;

    @media only screen and (max-width: $break-point--440) {
        width: calc(32% - 2rem);
        margin: 1rem;
    }

    @media only screen and (max-width: $break-point--320) {
        font-size: 1rem !important;
    }
}

.apib__reward-value {
    color: $color--chips;
}

.apib__button--bclass {
    padding: 0 2rem;
}

.apib__svg-icon-cmp {
    color: $accent--1;
}

.apib__button {
    p {
        font-size: $text__size--normal !important;
    }
}

.apib__container--reward--text {
    @extend %text;
}

.apib__container--info-expiresAt {
    padding: 1rem;
    font-size: $text__size--normal;
}

.apib_container--info-expiresAt-timer {
    font-size: inherit !important;
}