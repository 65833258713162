.club--leaderboard__container--list-users {
    @include scrollbar();
}

.container-leaderboard__avatar--class {
    width: 16.66% !important;

    @media only screen and (max-width: $break-point--768) {
        width: 20% !important;
    }
    @media only screen and (max-width: $break-point--530) {
        width: 25% !important;
    }
    @media only screen and (max-width: $break-point--380) {
        width: 33.33% !important;
    }

    .leaderboard-avatar__item {
        @media only screen and (max-width: $break-point--1280) {
            width: 80% !important;
        }

        @media only screen and (max-width: $break-point--480) {
            width: 90% !important;
        }
    }
}
