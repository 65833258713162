.profile-buttons__subscribed {
    max-width: 90%;
    font-size: $text__size--large !important;
    text-transform: uppercase;
    color: $color--chips;
    font-weight: $font-weight-bold;
}

.profile-buttons__tooltip-text {
    font-size: $text__size--normal;
    text-transform: capitalize;
    font-weight: $font-weight-normal;
}

.cash-back__info--button--bclass {
    color: $color--chips !important;
}