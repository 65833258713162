.custom-blitz-winner-box__container {
    background-color: #14254e;

    &.first {
        border: none;
        box-shadow: 0 -3px 3px #f5a724, inset 0 0 20px #f5a724;
    }

    &.second {
        border: none;
        box-shadow: 0 -3px 3px #5fb4e4, inset 0 0 20px #5fb4e4;
    }

    &.host {
        border: none;
        box-shadow: 0 -3px 3px #dc541f, inset 0 0 20px #dc541f;
    }
}