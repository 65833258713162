/*****************************
*     Wins ratio box skin    *
*****************************/


.wins-ratio-box__wrapper {
    @include container--colored($main, 0);
}

.wins-ratio-box__container--tournament-wins {
    @include container--colored($color__black);
    border: none;

    .wins-ratio-box__container--tournament-wins-title {
        background-color: transparentize($color__black, .5);
    }

    .wins-ratio-box__container--tournament-wins-info-box-inner {
        background-color: transparentize($color__black, .75);

        &.royal4 {
            > .icon {
                @include icon($royal4, 3rem);
            }
        }

        &.royal8 {
            > .icon {
                @include icon($royal8, 3rem);
            }
        }

        &.royal16 {
            > .icon {
                @include icon($royal16, 3rem);
            }
        }

        &.royal32 {
            > .icon {
                @include icon($royal32, 3rem);
            }
        }

        &.royal64 {
            > .icon {
                @include icon($royal64, 3rem);
            }
        }

        >.wins-count {
                color: $accent--1;
        }
    }
}

.wins-ration-box__button--reset {
    color: $accent--1;
}