/*******************************
*   Default container styles   *
*******************************/

// Mixins
@mixin container_constructor($type: $container--default, $cover: 'default', $color: 'transparent', $stroke: .1rem, $shadow: false, $radius: 0) {
  @if ( $type != $container--dark ) {
  } @else {
    background-color: dark( $color ) !important;
  }
  @if ( $color != false ) {
    background-color: $color;
  } @else {
    background-color: transparent;
    border-radius: $radius;
    @if ( $shadow == true ) {
        box-shadow: $container__shadow--inset;
    }
  }
  @if ( $shadow == true ) {
      box-shadow: $container__shadow;
  }
}

@mixin container--colored( $color, $transparentValue: 0.75, $hoverEffect: false ) {
  border-radius: $radius--default;
  background-color: transparentize( $color, $transparentValue);
  border: .1rem solid $accent--1;
  @if ($hoverEffect == true) {
    &:hover {
      background-color: light(transparentize( $color, $transparentValue));
    }
  }
}

// Placeholders
%form__container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 40rem;
  border: .1rem solid $accent--1;
  background-color: $secondary;
  border-radius: $radius--default;
  @media only screen and (max-width: $break-point--1920) {
    max-width: 45rem;
  }
  @media only screen and (max-width: $break-point--1366) {
    max-width: 36rem;
  }
  @media only screen and (max-width: $break-point--768) {
    max-width: 30rem;
  }
  @media only screen and (max-height: $break-point--480) {
    max-height: 98vh;
  }
  .form__content {
    @include touch-scroll();
    padding: 10% 2rem 2rem;
    @media only screen and (max-width: $break-point--768) {
      padding: 10% 1rem 1rem;
    }
    @media only screen and (max-height: $break-point--640) and (orientation: landscape ) {
      display: block;
      height: 85%;
      overflow-y: scroll;
    }
    @media only screen and (max-height: $break-point--530) and (orientation: portrait ) {
      overflow-y: scroll;
    }
    @media only screen and (min-height: $break-point--640) and (orientation: landscape ) {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    @media only screen and (min-height: $break-point--530 ) and (orientation: portrait ) {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    @media only screen and (max-height: $break-point--530)  {
      height: 85%;
    }
    @media only screen and (max-height: $break-point--360)  {
      height: 80%;
    }
  }
}

// Container with gradient cover
%container--normal {
  @include container_constructor( $container--normal, $container__cover, $secondary);
  border-radius: $radius__default;
  box-shadow: $container__shadow;
}

%container--dark {
  @include container_constructor($container--dark, 'default', $secondary, .1rem, false);
}


%container--aside {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100% - 11rem);
  max-width: $max-content-width;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
//   overflow: hidden;
  @media only screen and (max-width: $break-point--1366) {
    height: calc(100% - 10rem);
  }
  @media only screen and (max-width: $break-point--1440) {
    max-width: $max-content-width-1440;
  }
  @media only screen and (max-width: $break-point--768) {
    height: calc(100% - 7.5rem);
  }
}

// Main Dimmer ( cover element )
%container--dimmer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $popup--backdrop;
  z-index: $layer--3;
  overflow-y: auto;
  overflow-x: hidden;
}

// Used in containers like popup, login, register and settings
%container--bordered {
  position: relative;
  border: .1rem solid $accent--1;
  background-color: $secondary;
  border-radius: $radius--default;
  min-height: 28rem;
  overflow: hidden;
  .container__title {
    position: relative;
    background-color: $dark-secondary;
    order: 1;
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      @include separator( orange );
    }
    @media only screen and (max-height: $break-point--440) {
      height: 20%;
    }
  }
  .container__button--close {
    position: absolute!important;
    width: /*3.5*/4rem;
    height: /*3.5*/4rem;
    top: 1rem;
    right: /*1*/.5rem;
    padding: 0!important;
    margin: 0!important;
    min-height: auto!important;
    z-index: 2;
    @media only screen and (max-width: $break-point--768) {
      //top: .5rem;
      //right: .5rem;
      height: 3.5rem;
    }
    @media only screen and (max-height: $break-point--440) {
      top: 2%;
    }
  }
}

%shop-box {
  width: 33.33%;
  height: 37rem;
  padding: 2rem 1.5rem;
  max-width: 24rem;

  @media only screen and (max-width: $break-point--568) {
      height: 30rem;
      padding: 2rem .25rem;
  }

  @media only screen and (max-width: $break-point--380) {
      width: 50%;
      padding: 2rem .75rem;
  }
}

%shop-wrapper {
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  align-content: flex-start;
  justify-content: center;
  touch-action: pan-y !important;
  max-width: 90rem;
  margin: 0 auto;

  @media only screen and (max-width: $break-point--380) {
      max-width: 30rem;
  }
}
