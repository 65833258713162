$font-size: 2rem;

.daily-bonus--tile__text--container {
    background-color: $black__transparent--07;
    z-index: 2;
}

.daily-bonus--tile__timer {
    font-size: $font-size !important;
    color: $color__white;

    @media only screen and (max-width: $break-point--768), (max-height: $break-point--440) {
        font-size: $text__size--normal !important;
    }
}

.daily-bonus--tile__button-bclass {
    @media only screen and (max-height: $break-point--440) {
        > p {
            font-size: $text__size--normal !important;
        }
    }
}

.daily-bonus--tile__content--container {
    border-radius: $border--radius;
    background-image: url(imageUrl("lobby/DailyReward_1.jpg"));
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;

    &.progressive-videos {
        box-shadow: inset 0px 3px 3px #f90339;
        background-image: url(imageUrl("lobby/progressive-videos.png"));
    }
}
