/***********************
* Provider button skin *
***********************/

.provider-button__button {
    min-width: initial !important;
    height: 4rem !important;
    overflow: visible !important;

    @media screen and (max-width: $break-point--320) {
        font-size: 1.2rem;
    }
    &.paypal {
        .bc__icon--cmp {
            width: 80% !important;
        }
    }
    &.image {
        img {
            width: auto;
            height: 50%;
        }
    }
    &.xsollapaypal , &.xsollaepay, &.paypalcheckout {
        font-size: 7rem !important;
    }
}

.tag {
    background-color: $main;
}