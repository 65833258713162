/***************************************
*      Blitz-tournament skin           *
***************************************/

.winners__box-container {
    @include container--colored($container--2);
}

.box-footer {
    background-color: $main;
}

.winners-prize {
    @extend %text;
}

.winners-prize-chips {
    @include chip-sign(1.8rem);

    &:before {
        color: $color__chips;
        margin-right: .3rem;
    }

    color: $color--chips;
    margin-right: .3rem;
}

.winners-footer {
    @extend %container--dark;
}

.ribbon-text {
    @extend %text;
    color: $color__chips;
}

.winner_box-team-player {
    @include container--colored($color__black);
}
.winners__box-container {
    &.first {
        .winners-ribbon-container {
            background-color: transparent !important;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(imageUrl("blitz-tournament/tournament-icon-first.png"));
        }
    }
    &.second {
        .winners-ribbon-container {
            background-color: transparent !important;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(imageUrl("blitz-tournament/tournament-icon-second.png"));
        }
    }
}