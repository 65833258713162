/************************************
*       Edit profile skin           *
*************************************/

// Style

.ep__container {
  min-height: initial !important;
}

.ep__container--content {
  overflow-y: auto;
  @include scrollbar();
}
