/***************************************
*      Blitz-tournament skin           *
***************************************/

$first-place-sprite: imageUrl('blitz-tournament/first-place.png');
$second-place-sprite: imageUrl('blitz-tournament/second-place.png');

.won-popup__container--content {
    @include scrollbar();
}

.won-popup__prize--amount {
    > span {
        @include chip-sign(2.4rem);
        @extend %text;
        font-weight: normal;
        color: $color--chips;
    }
}

.won-image {
    @include background-image($second-place-sprite, 100%, 100%, left, center);
    background-size: auto 100%;
    display: block;
    width: 15rem;
    height: 15rem;
    min-height: 15rem;
    margin: 0 auto;
}

.sprite-animation {
    @include background-image($second-place-sprite, 100%, 100%, left, center);
    background-size: auto 100%;
    display: block;
    width: 15rem;
    height: 15rem;
    margin: 0 auto;
    animation: won-sprite 0.6s steps(16) infinite;
}

.first-place {
    background-image: url($first-place-sprite);
}

@keyframes won-sprite {
    100% {
        background-position: -240rem;
    }
}

.won-popup-image__new-design {
    display: none;
}