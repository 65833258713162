.shop-text-list__container--benefits-label {
    &:not(.simple-styling) {
        background-color: #356B84;
    }
}

.shop-text-list__container--benefits-list {
    padding: 1rem 0 0.5rem 0 !important;

    &:not(.simple-styling) {
        background-color: rgb(23 34 58 / 81%);
    }
}

.shop-text-list__wrapper {
    border-radius: $border--radius;
    overflow: hidden;
}