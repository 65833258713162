/***********************
*    Gifts Shop skin   *
***********************/


.gifts-shop__container--content {
  .tab-switch {
    .tab {
      .button-icon-tab {
        width: 98% !important;
        height: 100% !important;
        transform: translateZ(0);
        @media only screen and (max-width: $break-point--768) {
          p {
            font-size: $text__size--small;
          }
        }
        &:not(.selected) {
          button {
            background-color: $black__transparent--02 !important;
            border-radius: .8rem !important;
            border: none !important;
            &:before {
              background-color: transparent !important;
            }
          }
        }
        @media only screen and (max-width: $break-point--768) {
          height: 100%  !important;
          min-height: initial !important;
          line-height: initial !important;
        }
      }
    }
  }
}

.gifts-shop__popup-container--class {
    z-index: $layer--3 + 1 !important;
}