$font-color-blue: $color--bar;
$auth-btn-width: 2rem !important;

.auth--providers__buttons {
    .social__button {
        &.facebook-button {
            > button {
                > img {
                    width: $auth-btn-width;
                }
            }
        }

        &.twitter-button {
            > button {
                > img {
                    width: $auth-btn-width;
                }
            }
        }

        &.google-button {
            > button {
                > p {
                    text-shadow: none;
                    color: $font-color-blue;
                }

                > img {
                    width: $auth-btn-width;
                }
            }
        }

        &.email-button {
            > button {
                > img {
                    width: $auth-btn-width;
                }
            }
        }

        &.phoneNumber-button {
            > button {
                > img {
                    width: $auth-btn-width;
                }

                > p {
                    text-shadow: none;
                }
            }
        }
        &.apple-button {
            > button {
                color: $font-color-blue;

                > p {
                    text-shadow: none;
                    color: $font-color-blue;
                }
            }
        }

        &.guest-button {
            > button  {
                > p {
                    font-size: $text__size--normal;
                    color: $accent--1;
                }
            }
        }

        &.mobile-button {
            > button  {
                > p {
                    font-size: $text__size--normal;
                    color: $accent--1;
                }
            }
        }
    }
}
