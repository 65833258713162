@import '../../../../../../../styles/global/animations/zoom-in-out';

.rate-box__container--rate-container {
    .rate {
        .rate-container {
            .stars {
                width: 100%;
                &:not(.rated) {
                    direction: rtl;
                }
                &.rated {
                    @extend %text;
                }
                .star {
                    margin: 0 4%;
                    width: 12%;
                    color: $white__transparent--05;

                    &.selected {
                        @extend %zoomInAndOut;
                        color: $color__chips;
                    }

                    &:hover,
                    &:hover ~ .star {
                        color: $color__chips;
                    }

                    &.hidden {
                        display: none;
                    }
                }
            }
            .partner-rating {
                .rating {
                    display: flex;
                    align-items: center;
                    color: $color__chips;
                    font-size: $text__size--large !important;
                }
            }
        }
    }
}