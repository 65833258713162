@import '../../../../../styles/global/animations/bar-color-animation';

.level-animated-bar__container--outer {
    background-color: $black__transparent--05;
}

.level-animated-bar__container--inner {
    background-color: $accent--1;
}

.level-animated-bar__container--bar-icon {
    &:not(.club) {
        @include background-image(imageUrl('game-summary/experience-icon.png'));
    }

    &.club {
        @include background-image(imageUrl('game-summary/clubs-icon.png'));
    }
}