.custom-blitz-room-lobby__fragment-table {
    height: 15rem;
}

.custom-blitz-room-lobby__rounds--container {
    position: relative;
    @media screen and (max-width: $break-point--900) and (max-height: $break-point--1024) {
        margin-top: 0 !important;
    }
}

.custom-blitz__button {
    display: none !important;
}

.custom-blitz-room-lobby__scroll--controller {
    text-align: center;
    font-size: $text__size--large;
    font-weight: $font-weight-bold;
    color: $color__chips;
    @media screen and (max-width: $break-point--900) and (max-height: $break-point--1024) {
        margin-top: 5rem;
    }
}

.custom-blitz-room-lobby__scroll--controller {
    width: 100%;
    position: relative;
    @media screen and (max-width: $break-point--900) and (max-height: $break-point--1024) {
        height: 6rem;
    }
}

.custom-blitz-room-lobby__scroll--controller__container {
    height: 5rem;
    margin: 2rem auto;
    width: 40rem;
    max-width: calc(100% - 3rem);
}