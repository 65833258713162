/**********************************
*      Global feed  skin         *
**********************************/

// Shadows
$input__text--color: $main;
$input__container--bg-color: $color__white;

// Input & Form
.gf__container--input {
  background-color: $input__container--bg-color;
}

.gf__input--message {
  @extend %text;
  @include container--colored(transparent);
  @include scrollbar();
  border-radius: 1.8rem;
  border-top-right-radius: 0;
  color: $input__text--color;
}

.msg-input__send-button {
    width: 5rem !important;
    margin-left: 2rem;
    margin-right: 2rem;
}

.gf__button--bclass {
  color: $black--7;
}

.gf__input--drawer {
  background-color: $input__container--bg-color;
  @include scrollbar();
  overflow: auto;
}