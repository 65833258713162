/***********************
* Gifts Shop skin      *
***********************/

.gifts-shop-drawer__wrapper {
  @extend %container--bordered;
  border: none;
  border-radius: 0;
}

.gifts-shop-drawer__container--content {
  .content-gifts {
    .content-wrapper {
      background-color: $black__transparent--02;
    }
  }
}
.gift-shop-drawer-button-icon-tab {
  height: 3.5rem;
  width: 16rem;
  margin: 0 .5rem !important;
  @media only screen and (max-width: $break-point--768) {
    width: 13rem;
  }
}

.gift-shop-drawer_top--tabs {
  margin-top: 1rem;
}

.gift-shop-drawer-button-icon-tab__bclass {
  transition: none !important;
  background-color: #151835 !important;
  &.gift-shop-drawer-button-icon-tab__selected {
      background-color: $color--chips !important;
  }
}