:host {
  height: 10rem;
}

.top-tabs__list-item--navigation {
  &.separator {
    &:before {
      background-color: $accent--1;
    }
  }
}

.top-tabs__scroll {
    height: 0;
    border-radius: $border--radius * 2;
}

.top-tabs__scrollbar {
    height: 0;
    border-radius: $border--radius * 2;
}

.top-tabs__container--navigation {
  @include hide-scrollbar();
}

.top-tabs__style {
  padding: 0 !important;
}

.top-tabs-scrollbar__hidden {
  display: none !important;
}