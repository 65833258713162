%zoomInAndOut {
  animation: zoomInAndOut .3s linear forwards;
}

@keyframes zoomInAndOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
