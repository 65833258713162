.ranked-games-progress {
    border-radius: $border--radius;
}

.ranked-games-progress__wrapper {
    padding: 1rem;

    @media screen and (max-width: $break-point--568) {
        padding: 0.5rem;
    }
}

.ranked-games-progress__backdrop {
    background-color: $black__transparent--06;
    border-radius: $border--radius;
}

.ranked-games__button-claim-bclass {
    >p {
        font-size: 2.4rem !important;
    }

    @media screen and (max-width: $break-point--568) {
        > p {
            font-size: 1.8rem !important;
        }
    }
}

.ranked-games__all-claimed-text {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: 2.4rem;
    color: #54ff00;
    text-shadow: 0 -1px 0 #a8ff00, 0 2px 2px #000000;
    height: 5rem;
    margin-bottom: 1.75rem;
    @extend %text;
    @media screen and (max-width: $break-point--568) {
        font-size: 1.6rem;
        height: 3rem;
        margin-bottom: 0.75rem;
    }
}