.custom-blitz-seat--wrapper {
    &.showBackdrop {
        background-color: transparentize($color__black, .7);
    }
}

.custom-blitz-seat__add--icon {
    font-size: 3.5rem !important;

    &.reserved {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

.custom-blitz-seat__invite--button {
    font-size: 1em !important;
}

.custom-blitz-seat--reserved-backdrop {
    background-color: $black__transparent--06;
}

.custom-blitz-seat__locker__bclass {
    color: $color__chips;
    font-size: 4rem;

    @media screen and (max-width: $break-point--900) {
        font-size: 3rem;
    }
}

.custom-blitz-seat__locker {
    width: 100%;
    height: 100% !important;
}

.custom-blitz-seat--locked__with--reason {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
    height: 100% !important;
    border-radius: $border--radius;
    background-color: transparentize($color__black, .7);
    &.notEnoughChips, &.vipRequired {
        &:hover {
            border: 1px solid $color__chips;
        }
    }
}

.custom-blitz-seat--locked__with--reason__icon {
    font-size: 3.5rem;
    color: $color__chips;
}

.custom-blitz-seat__locker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.custom-blitz-seat__invite--icon {
    color: $color--chips;
}

.custom-blitz-seat__losers--overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 0.8rem;
}

.custom-blitz-seat__bot-container {
    background-color: transparentize($color__black, .7);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-blitz-seat--bot {
    width: 3rem;
}

.fl__text--reward-value__icon {
    color: $color--chips;
    font-size: $text__size--large;
}