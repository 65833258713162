$bullet-point-width: 0.8rem;

$title-font-size: $text__size--large + 0.2rem;
$bullet-font-size: $text__size--normal + 0.2rem;

$title-font-size-mobile: $text__size--large;
$bullet-font-size-mobile: $text__size--normal;

$bullet-text-min-height: 2rem;
$bullet-text-min-height-mobile: 1.6rem;

.clubs--info__separator {
    position: relative;

    &:before {
        @include separator(yellow);
    }

    &.section__separator {
        &:before {
            top: 50%;
            bottom: initial;
            transform: translateY(-50%);
        }
    }
}

.clubs-info__footer-button {
    margin: 0 2rem !important;
    width: 100%;
    min-width: unset !important;
    @media screen and (max-width: $break-point--440) {
        margin: 0 .5rem !important;
    }
    @media screen and (max-width: $break-point--380) {
        margin: 0 .3rem !important;
    }
}