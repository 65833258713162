/********************************
*      Rent chooser skin        *
********************************/

// Style
.rent-button__container--rent-time {
    @extend %text;
    &:after {
        @include separator(yellow);
    }
}

.rent-button-button__main {
    width: 100%;
    //padding: 0 1rem;
    min-width: 12rem !important;

    .rent-button-button__text {
        font-size: $text__size--large;
        font-weight: inherit;
        &.bgn {
            display: flex;
            justify-content: center;
            align-items: center;

            .currency {
                order: 2;
                text-transform: lowercase;
                margin: 0 .3rem;
            }

            .price {
                order: 1;
            }
        }

        .chips {
            color: $color__white;
            font-size: inherit !important;
        }

        .gems {
            color: $color__white;
            font-size: inherit !important;
        }
    }

    @media only screen and (max-width: $break-point--768) {
        > p {
            font-size: $text__size--normal;
        }
    }

    .rent-button-button__text {
        @extend %text;
        direction: ltr !important;
    }
}
