.chat-invite-content__info-box {
    border-color: $black--3;
    background-color: $color__white;
}

.chat-invite-content__currency-label {
    color: $color--chips;

    @media screen and (max-width: $break-point--380) {
        font-size: 1em;
    }
}

.chat-invite-content__info-box--text {
    color: $chat__message-text--color
}

.chat-invite-content__room--type__label, .chat-invite-content__room--description, .chat-invite-content__info {
    color: $color__black;
    text-align: center;
}

.chat-invite-content__room--type__label {
    font-size: $text__size--x-large;
}

.chat-invite-content__room--description {
    overflow: hidden;
    text-overflow: ellipsis;
}