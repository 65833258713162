/***********************
* Settings popup skin  *
***********************/

// Styles
.dev-settings-popup__wrapper {
  background: $black__transparent--08;
}

.dev-settings-popup__container--option {
  &:after {
    @include separator( orange );
    width: 90%;
    left: 5%;
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
}

.dev-settings-popup__container--content-inner {
  @include scrollbar();
}

.dev-settings--client-version {
  text-align: center;
}
