.ranked-team-stats-box {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 1rem;
    border-radius: $container-border--radius;
    background-color: #212b46;
}

.ranked-team-stats-box-delimiter {
    width: 2px;
    background-color: #134e5d;
}

.ranked-team-stats-box-title-bar-text {
    font-size: 1.2rem;
}

.ranked-team-stats-box-delimiter {
    display: none;
}

.ranked-team-stats-box-delimiter-horizontal {
    @include separator(yellow);
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
}

.ranked-stats-element {
    width: 20%;
    &:not(.ranked-team-stats-box-avatars) {
        background-color: $black__transparent--05;
        border-radius: $container-border--radius;
    }

    @media screen and (max-width: $break-point--568) {
        width: 23%;
    }
}

.ranked-team-stats-box-result-text {
    color: $color__chips;
    font-weight: $font-weight-bold;
    font-size: 2.4rem !important;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.8rem !important;
    }
}

.ranked-team-stats-box-rating-text {
    font-weight: $font-weight-bold;
    font-size: 2.4rem;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.8rem;
    }
}

.ranked-team-stats-box-streak-text {
    font-weight: $font-weight-bold;
    font-size: 2.4rem !important;
    color: #7ef51f;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.8rem !important;
    }

    &.isNegative {
        color: #ff1414;
    }
}


.ranked-team-stats__container-rays {
    &:before {
        background-image: var(--rays-animation);
        background-repeat: no-repeat;
        background-position: center;
        animation: rotateRays 10s linear infinite;
        will-change: transform;
    }
}