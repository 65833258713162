/**********************************
*     FB splash page skin         *
**********************************/

// Styles
.fb-splash__image--logo {
  background: var(--game__logo) no-repeat center;
  background-size: contain;
}

.fb-splash__image--hostess {
  background: var(--game__host) no-repeat center;
  background-size: contain;
}

.fb-splash__container {
  background: $black__transparent--05;
  .fb-splash__text {
    @extend %text;
    font-size: $text__size--large;
  }
}

.fb-splash__link--terms {
  @extend %link;
}

.fb-splash__link--privacy {
  @extend %link;
}

.fb-splash__text--terms-privacy {
  @extend %text;
  color: $black--b;
  text-align: center;
    > a {
        text-decoration: underline;
    }
}
