.offer-buy-item--container {
    border-radius: $border--radius;
    background-color: rgba(0, 0, 0, 0.3);
}

.offer-buy-item--container {
    &.golden {
        background-color: rgba(40, 188, 232, 0.25);
    }
}

.offer-item-shine-cmp {
    border-radius: $border--radius;
}

.offer--item__button--container {
    padding: 0 1rem;
}
