.club--info__name {
    font-size: $text__size--x-large;
    font-weight: 600;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--large + .2rem;
    }
}

.club--info__description {
    border-radius: $border--radius;
    background-color: $black__transparent--03;
}

.club--info__description--text {
    width: 100%;
    padding: 1rem;
    font-size: $text__size--normal + .2rem;
    word-wrap: break-word;
    text-align: left;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--small;
    }
}

.club--info__description--footer {
    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--smaller;
    }
}

.club--info__label-last-seen {
    color: $color__negative;
}

.club--info__label-last-seen {
    @extend %text;
}