/***********************
* Hidden shop skin     *
***********************/

// Styles
.hidden-shop__wrapper {
    @extend %container--dimmer;
}

.hidden-shop__content {
    @include scrollbar();
    height: calc(100% - 6rem);
    max-height: calc(98vh - 6rem)
}

.hidden-shop__container {
    @extend %form__container;
}

.hidden-shop__username {
    font-size: $text__size--large;
}

.hidden-shop__empty-users-list {
    background-color: $black__transparent--05;
    margin-top: 1rem;
    padding: 2rem;
    font-size: $text__size--large;
    text-align: center;
    border-radius: $border--radius;
    font-weight: $font-weight-bold;
}

.hidden-shop__selector-item {
    background-color: $black__transparent--03;
    cursor: pointer;

    &:hover {
        background-color: $black__transparent--03;
    }

    &.selected {
        background-color: $main;
    }

    &:not(.selected) {
        border-color: transparent;
    }
}

.hidden-shop__selector--selected-item {
    background-color: $black__transparent--06 !important;
    height: 6rem;
}

.hidden-shop__selector--options-container {
    top: 6rem !important;

    @media screen and (max-height: $break-point--440) {
        max-height: 15rem !important;
    }
}

.hidden-shop__popup--container {
    height: auto !important;
    min-height: initial !important;

    &.giftable {
        min-height: 35rem !important;
        height: 98% !important;
        max-height: 65rem !important;

        @media screen and (max-height: $break-point--440) {
            height: 98%;
            min-height: initial !important;
        }
    }
}

.hidden-shop__popup--backdrop {
    z-index: $layer--2 + 1 !important;
}

.hidden-shop__selector--wrapper {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    flex: 1;

    @media screen and (max-height: $break-point--440) {
        min-height: initial !important;
    }
}

.hidden-shop__content--text {
    font-size: $text__size--large !important;
    font-weight: $font-weight-bold;
}

.hidden-shop__select-text {
    font-size: $text__size--large;
    font-weight: $font-weight-bold;
    margin: 1rem 0;
    text-align: center;
}

.hidden-shop__online-indicator {
    background-color: $color--offline;

    &.online {
        background-color: $color--online;
    }
}

.hidden__shop--list--users-box-item {
    height: 6rem !important;
}

.hidden-shop__items-preview {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}

.hidden-shop__item {
    width: calc(32% - 2rem);
    margin: 1rem;
    font-size: 1.2rem !important;
    @media screen and (max-width: $break-point--320) {
        font-size: 1rem !important;
        width: calc(32% - 1rem);
        margin: .5rem;
    }
    &.giftable {
        width: calc(25% - 1rem) !important;
        margin: .5rem !important;
        @media screen and (max-width: $break-point--320) {
            width: calc(25% - 1rem) !important;
            margin: .5rem !important;
        }
    }
}

.hidden-shop__content__container {
    height: 95%;
    overflow-x: auto;
    @include scrollbar();
}