.xp-box__text--xp-gain {
    color: $accent--1;
}

.xp-box__text--xp-gain-animate-out {
    animation: xpTextAnimateOut 0.3s linear forwards !important;
}

@keyframes xpTextAnimateOut {
    from {
        transform: translateX(-50%) scale(1);
    }

    to {
        transform: translateX(-50%) scale(0);
    }
}