.custom-blitz-winner-box__player--reward__label {
    color: $color__chips;
}

.custom-blitz-winner-box__container {
    background-color: transparentize($color__black, .7);
    padding: 1rem;
}

.custom-blitz-winner-box__player--reward__value {
    font-size: $text__size--large;
}

.custom-blitz-winner-box__place--label {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 2rem;
}

.custom-blitz-winner-box__container {
    border-radius: $border--radius;
    &.first {
        border: 1px solid $color__chips;
        .custom-blitz-winner-box__place--label {
            color: $color__chips;
        }
    }
    &.second {
        border: 1px solid #5fb4e4;
        .custom-blitz-winner-box__place--label {
            color: #5fb4e4;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 2rem;
        }
    }
    &.host {
        border: 1px solid #dc541f;
        .custom-blitz-winner-box__place--label {
            color: #dc541f
        }
    }
}

.custom-blitz-winner-box__bot-container {
    background-color: transparentize($color__black, .7);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-blitz-winner-box__bot--icon {
    width: 4rem !important;
}