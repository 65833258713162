.personal-note-host {
    margin: 1rem auto;
    border-radius: 1rem;
}

.personal-note-text--conv-time {
    color: inherit;
    position: initial;
    padding: 0 1rem 1rem 0rem;
}

.personal-note--content-preview {
    color: inherit;
    overflow-wrap: break-word;
    line-height: 2rem;
}

.personal-note--single-container {
    position: relative;
    display: flex;
    flex-flow: row;
    padding: 1rem 1.7rem;
    border-radius: .7rem;
}