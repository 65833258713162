/***********************
*   Game history skin  *
***********************/

// Variables
$badge-won: imageUrl('game-history/game-history__won.png');
$badge-lost: imageUrl('game-history/game-history_lost.png');

// Mixin chip
@mixin chips( $color ) {
  display: inline;
  @media only screen and (min-width: $break-point--480) {
    @include chip-sign( 1.4rem );
    &:before {
      color: $color;
    }
  }
  @media only screen and (max-width: $break-point--480) {
    @include chip-sign( 1rem );
    &:before {
      color: $color;
    }
  }
}

// Styles

.game-history__text--no-games {
  @extend %stroked;
}

.game-history__container--upper-info {
  background-color: transparentize($color__black, .7);
}

.game-history__container--separator,
.game-history__container--middle-info-separator {
  &:before {
    @include separator(yellow);
  }
}

.game-history__container--room-type {
  > p {
    color: $accent--1;
  }
}

.game-history__container--middle-info-bottom {
  &.game-won {
    .game-history__text--text-game-status {
      color: $active--color;
      .chip {
        @include chips( $active--color );
      }
    }
  }

  &.game-lost {
    .game-history__text--text-game-status {
      color: $color__negative;
      .chip {
        @include chips( $color__negative );
      }
    }
  }
}

.game-history__container--my-team {
  @at-root .lang-ar &,
  .lang-fa & {
    order: 3;
  }
}

.game-history__container--other-team {
  @at-root .lang-ar &,
  .lang-fa & {
    order: 1;
  }
}

.game-history__text--win-loss,
.game-history__text--points {
  color: $accent--1;
}

.game-history__container--game-played {
  @include container--colored( $container--4 );
}
