/***************************
*       Shop skin          *
***************************/

// Styles
.shop__container--router-outlet {
  @include scrollbar();
}

.shop__top-tabs__bclass {
  width: 4rem;
  margin: .5rem;
  > p {
    font-size: $text__size--normal !important;
  }

  &.shop__top-tabs__bclass__selected {
      background-color: $color--chips !important;
  }
}

.shop__tabs--redesign {
  display: initial !important;
  margin: 0 1rem;
  .tab {
    padding: .5rem 1rem;
  }
}

.shop__tabs-style {
  color: $color__white;
  height: 3rem;

  > p {
      font-size: 1.4rem !important;
      color: $color__white;
  }

  &.selected {
      color: $color--chips;
  }

  .top-tabs__list-item--navigation {
      width: auto !important;
      padding: 0 1rem;
  }

  .bc__button--icon-cmp {
      display: none !important;
  }
}

.shop__top-tabs__scroll {
  visibility: hidden;
}

.shop__top-tabs__scrollbar {
  visibility: hidden;
}

.shop__top-tabs__bclass {
  color: $color--white !important;
  background-color: #151835 !important;
  width: 11rem;
  margin: 0;
  transition: none !important;
  > p {
      text-decoration: none;
  }
  &.shop__top-tabs__bclass__selected {
      background-color: $color--chips !important;
  }
}
