/***********************
* Popup message skin   *
***********************/

// Styles
.rate-popup__container--content {
    .rate-popup__container--content-inner {
        .rate-popup__container--content-text {
            @extend %text;
            > button {
                margin: 1rem 0;
                padding: 0 1rem !important;
            }
        }
    }
}

.rate-popup__container--buttons {
    .rate-popup__container--buttons-inner {
        .rate-popup__container--stars > p:hover:before,
        .rate-popup__container--stars > p:hover ~ p:before {
            color: $color__chips;
        }
        .rate-popup__container--stars {
            direction: rtl;
        }
    }
}

.rate-popup__star {
    color: $white__transparent--05;
    margin: 0 1rem;
    font-size: 6rem;
    @media only screen and (max-width: $break-point--768) {
        font-size: 5rem;
    }
    @media only screen and (max-width: $break-point--480),
        only screen and (max-height: $break-point--440) {
        font-size: 3rem;
    }
    &.selected {
        color: $color__chips;
    }
}

.rate-popup__container--content-text-paragraph {
    font-weight: bold;
    font-size: $text__size--large;
}
