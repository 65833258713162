.scroll--tile__button {
    min-width: 30px !important;
    min-height: 30px !important;
    max-width: 30px !important;
    max-height: 30px !important;
}

.scroll--tile__button--bclass {
    color: #416974 !important;
    background-color: #ececec !important;
    stroke: #416974;
    stroke-width: 2;
    border-radius: 50% !important;
    overflow: hidden;
    font-size: 1.5rem !important;
}

.scroll--tile--fragment {
    padding: .5rem;
}

.scroll--tile--fragment--text-p {
    font-size: $text__size--large;
    font-weight: $font-weight-bold;
    @media only screen and (max-width: $break-point--320) {
        font-size: $text__size--normal;
    }
}

.scroll--tile--fragment--label {
    display: initial;
    margin: 0;
}

.scroll--tile--fragment--label__not-visable {
    display: none;
}