/***************************
*       Shop skin          *
***************************/

.allterco__payment--form-buy-button {
  @include button--text($button--attention);
  width: 50%;

  @media only screen and (max-width: $break-point--768) {
    padding: 0 1rem;
  }

  @media only screen and (max-width: $break-point--768) and (orientation: landscape) {
    max-height: 3rem;
  }

  >p {
    @extend %text;
  }

  min-width: 4rem;
  height: 4rem;
  min-height: 3rem;
  display: block;
  margin: 0 auto;

  @media only screen and (max-width: $break-point--768) {
    height: 3.5rem;
  }
}