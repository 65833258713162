.giftableProducts--title-text {
    text-align: center;
    font-size: 2rem;
    color: #f5a724;
    @extend %text;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.8rem;
    }

    >span {
        color: red;
    }
}

.giftableProducts--wrapper {
    padding: 1rem;
    overflow: auto;
    @include scrollbar();
}

.giftableItem {
    background-color: transparent;
}

.giftableProducts--donate-button-bclass {
    &>p {
        font-size: 2rem;
    }
}

.giftableProduct--close-button {
    font-size: 2rem !important;
    margin: 0 !important;
}

.giftableProduct--close-button-bclass {
    color: red;
}

.giftableProducts--border-bottom {
    @include separator();
    position: initial;
}
