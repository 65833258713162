.room__wrapper {
    border-radius: $container-border--radius;
    
    &.custom {
        background-color: #66016b;
        box-shadow: 0 -3px 3px #a10faa, inset 0 0 12px #a10faa;
        &.belot , &.tabla, &.bace {
            background-image: url(imageUrl('lobby/room-bg-custom-belot.svg')) !important;
            .nav--room--box__image--icon {
                background-image: url(imageUrl('lobby/vip-room-crown.png'));
            }
        }
    }
    &.tournament {
        background-color: #fc7600;
        box-shadow: 0 -3px 3px #fcbf00, inset 0 0 12px #fcbf00;
        &.belot {
            background-image: url(imageUrl('lobby/room-bg-tournaments-belot.svg'));
            .nav--room--box__image--icon {
                background-image: url(imageUrl('lobby/tournament-room-cup.png'));
            }
        }
    }
}