/**********************************
*      Matching skin              *
**********************************/

$cup-image: imageUrl('matching/tournaments_matchmaking.png');

.blitz__animation__cup {
  @include background-image($cup-image, 100%, 100%, left, center);
  background-size: auto 100%;
  display: block;
  width: 15rem;
  height: 15rem;
  margin: 0 auto;
  animation: cup-shine .8s steps(6) infinite;
}

.blitz-matching__text--players--icon {
    font-size: 2.4rem;
    color: $accent--1;
}

// Name
.blitz-matching__container--name {
  &:after {
    @include separator( orange );
  }
}

@keyframes cup-shine {
  100% {
    background-position: -90rem;
  }
}

@-webkit-keyframes cup-shine {
  100% {
    background-position: -90rem;
  }
}

.blitz__animation__cup--new-design {
    display: none;
}