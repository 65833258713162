.container-leaderboard__container--tab {
    border-radius: $border--radius;
    border: none;
    background-color: rgba(70, 103, 158, 0.49);

    &:not(.selected) {
        border: none;
    }
}

.container-leaderboard__container--leaderboard {
    border: none;
    background-color: rgba(70, 103, 158, 0.49);
}

.container-leaderboard__container--users {
    padding: 0 5% !important;
    padding-top: 1rem;

    @media only screen and (max-width: $break-point--1280) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }

    @media only screen and (max-width: $break-point--640) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }

    @media only screen and (max-width: $break-point--580) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }

    @media only screen and (max-width: $break-point--380) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }
}

.container-leaderboard__container--user {
    width: 12.5% !important;

    @media only screen and (max-width: $break-point--1366) {
        width: 14% !important;
    }

    @media only screen and (max-width: $break-point--768) {
        width: 16.66% !important;
    }
    @media only screen and (max-width: $break-point--530) {
        width: 20% !important;
    }
    @media only screen and (max-width: $break-point--480) {
        width: 21% !important;
        margin-left: 2%;
        margin-right: 2%;
    }
    @media only screen and (max-width: $break-point--380) {
        width: 30.33% !important;
        margin-left: 1.5%;
        margin-right: 1.5%;
    }
}