.training-scroll-tile__title {
    display: flex;
    font-weight: 700;
    text-transform: uppercase;
    flex-direction: column;
    font-size: 3rem;
    text-align: center;
    width: 100%;
    word-break: break-word;
    text-align: center;
    display: flex;
    justify-content: center;
}

.training-scroll-tile__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
}

.training-scroll-tile__description {
    @extend %direction-change;
    font-size: 2.6rem !important;
    font-weight: $font-weight-black;
    text-transform: uppercase;

    @media screen and (max-width: $break-point--1366) {
        font-size: 2.2rem !important;
    }
}

.training-scroll-tile__button__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.training-scroll-tile__restriction--cover__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black__transparent--05;
    display: none;
    border-radius: 1.2rem;

    &.training-scroll-tile__restriction--cover__container__not-permitted {
        display: block;
        background-color: $black__transparent--05;
    }
}

.training-scroll-tile__restriction--cover {
    visibility: hidden;
}

.training-scroll-tile__separator {
    display: none;
}

 .training-scroll-tile__title_container {
    flex-direction: column;
 }

 .training-scroll-tile__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 5% 0;
    width: 100%;
 }

 .training-scroll-tile-reward__label {
    font-weight: $font-weight-black !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    @extend %direction-change;
    font-size: 2.6rem !important;

    &:after {
        content: none;
    }

    .training-scroll-tile-reward__value {
        font-size: 2.6rem;
    }

    @media screen and (max-width: $break-point--1366) {
        font-size: 2.2rem !important;

        .training-scroll-tile-reward__value {
            font-size: 2.2rem !important;
        }
    }
}

.training-scroll-tile-bet__value {
    font-size: 1.8rem;
}

.training-scroll-tile__room-transactions {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.training-scroll-tile__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.training-scroll-tile-bet__label {
    display: flex;
    align-items: center;
}

.training-scroll-tile-bet__value-label {
    font-weight: $font-weight-black !important;
    font-size: $text__size--normal;
    margin-right: .5rem;
}

.training-scroll-tile-reward__value,
.training-scroll-tile-bet__value {
    font-weight: $font-weight-black !important;
}

.training-scroll-tile__button {
    width: 50%;
}

.training-scroll-tile__players-counter {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}
