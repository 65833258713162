/***********************
* Popup message skin   *
***********************/

// Variables
.shop-item__container--content {
  @include touch-scroll();
  @include scrollbar();
}

.shop-item__container--old-chips-count {
  .old-value {
    @extend %text;
    .vip-bonus-label__plus-sign {
      margin: 0 .5rem;
    }
    .vip-bonus-label {
      direction: ltr !important;
      text-transform: uppercase;
      &.number {
        margin-left: 1.8rem;
      }
      &:not(.number) {
        color: $color__chips;
      }
    }
    .vip-bonus {
      @extend %number;
      font-size: inherit;
      direction: ltr !important;
    }
    .vip-bonus-crown {
      color: $color__chips;
    }
  }
}

.shop-item__container {
  @extend %container--bordered;
}

.shop-item__container--buy-button {
  .shop-item__button--buy {
    @media only screen and (max-width: $break-point--768) {
      padding: 0 1rem;
    }
    @media only screen and (max-width: $break-point--768) and ((orientation: landscape)) {
      max-height: 3rem;
      margin-top: .2rem;
      min-height: 4rem;
    }
  }
}

.vip-bonus__container {
  @extend %text;
  margin-right: .5rem;
}

.shop-item__container__custom--title {
  @extend %text;
  font-size: $text__size--large;
  font-weight: $font-weight-semibold;
}

.shop-item__container__custom--description {
  font-size: $text__size--large;
  text-align: center;
  max-width: 80%;
  margin: 2rem auto;
  @media only screen and (max-height: $break-point--568) {
    margin: 0 auto;
  }
}