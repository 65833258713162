/*****************************
*      Toaster skin        *
*****************************/

// Variables
$icon__info: imageUrl( 'toast/info.svg' );
$icon__warn: imageUrl( 'toast/warning.svg' );
$icon__error: imageUrl( 'toast/error.svg' );
$icon__close: imageUrl( 'toast/close.png' );
$icon__success: imageUrl( 'toast/success.png' );

// Styles

.toaster__wrapper {
  z-index: $layer--1;
}

.toaster__container {
  box-shadow: 0 .1rem .3rem rgba(0,0,0,0.12), 0 .1rem .2rem rgba(0,0,0,0.24);
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  &.info {
    background-color: $color--info;
    .toaster__image { 
      background-image: url($icon__info);
    }
  }
  &.warn {
    background-color: $color--warn;
    .toaster__image { 
      background-image: url($icon__warn);
    }
  }
  &.error {
    background-color: $color--error;
    .toaster__image { 
      background-image: url($icon__error);
    }
  }
  &.success {
    background-color: $color--success;
    .toaster__image { 
      background-image: url($icon__success);
    }
  }
}

.toaster__image { 
  @include background-image( $icon__info, auto, 80%, center, center );
}

.toaster__text--message {
  @extend %text;
  color: $color__white;
}


