.ranked-games-leaderboard-list__title {
    padding: 1.25rem 2.25rem;
    border-radius: 1.5rem;
    background: #121a2f;
}

.ranked-games-leaderboard-list__container {
    @include ranked-tiles-colors((border-color: transparent, bg-color: #0c1020, inner-padding: 0, height: 100%));
}

.ranked-games-leaderboard-item {
    font-size: 1.1rem;
    height: 7rem;
    margin-top: 1.4rem;

    &.isFirst {
        margin-top: 0;
    }

    &.isLast {
        margin-bottom: 1.4rem;
    }

    &.my {
        margin-bottom: -7rem;
        font-weight: $font-weight-bold;
    }

    @media screen and (max-width: $break-point--568) {
        font-size: 0.8rem;
        height: 5rem;
        margin-top: 1rem;

        &.isLast {
            margin-bottom: 1rem;
        }

        &.my {
            margin-bottom: -5rem;
        }
    }

    @include touch-hover {
        filter: brightness(1.2);
    }
}

.ranked-games-leaderboard-list__item {
    padding: 0 1.5rem;

    @media screen and (max-width: $break-point--568) {
        padding: 0;
    }

    text-transform: uppercase;
    color: #fabd3c;
    text-shadow: 0 1px 0px #000;
}

.ranked-games-leaderboard-list__title-text {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    white-space: nowrap;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.5rem;
    }
}

.ranked-games-leaderboard-list__item--content {
    padding: 1rem 0;

    @media screen and (max-width: $break-point--568) {
        padding: 0;
    }
}

.ranked-games-leaderboard-list__item-rank,
.ranked-games-leaderboard-list__item-name,
.ranked-games-leaderboard-list__item-win-rate,
.ranked-games-leaderboard-list__item-score {
    font-size: 2rem !important;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.2rem !important;
    }
}

.ranked-games-leaderboard-list-items {
    @include scrol-container();
    @include scrollbar();
    overflow: var(--ranked-games-leaderboard-list-overflow);
    padding: 0 1.5rem;

    @media screen and (max-width: $break-point--568) {
        padding: 0;
    }
}

.ranked-games-leaderboard-list {
    max-height: var(--ranked-games-leaderboard-list-max-height);
    padding: 4rem 0 0;

    @media screen and (max-width: $break-point--568) {
        padding: 2rem 0 0;
    }
}

.ranked-games-leaderboard-list__container {
    border-radius: $container-border--radius;
}

.ranked-games-leaderboard-list__title-text {
    &.in-leaderboard {
        display: none;
    }
}