.ranked-tiles-container {
    border-radius: $border--radius;
}

.ranked--tiles__timer-text {
    font-weight: $font-weight-normal;
    text-transform: none;
    @extend %text;
}

.ranked-tiles__timer {
    font-size: 1.5rem !important;
    > span {
        color: $color--white;
        font-weight: $font-weight-bold;
    }
}

.ranked-tiles-flag__container {
    border-top: 1px solid #a2abb5 !important;
    background: linear-gradient(0deg, #070707 0%, #44484c 100%) !important;
    box-shadow: 0 2px 3px #000 !important;
}

.ranked--tiles__wrapper {
    border-top: var(--ranked-games-container-border-top-height) solid var(--ranked-games-container-border-color);
    background-color: var(--ranked-games-container-bg-color);
    padding: var(--ranked-games-container-inner-padding);
    height: var(--ranked-games-container-height);
    box-shadow: var(--ranked-games-container-box-shadow-bottom);
    box-shadow:  inset 0 -4px 0 var(--ranked-games-container-box-shadow-bottom), inset 4px 0 0 var(--ranked-games-container-box-shadow-side),inset -4px 0 0 var(--ranked-games-container-box-shadow-side);
    @media screen and (max-width: $break-point--568) {
        padding: var(--ranked-games-container-inner-padding-mobile);
    }
}

@mixin ranked-tiles-colors($map: $ranked-tiles) {
    $merged: map-merge-deep($ranked-tiles, $map);

    $border-color: map-get($merged, border-color);
    $border-top-height: map-get($merged, border-top-height);
    $bg-color: map-get($merged, bg-color);
    $inner-padding: map-get($merged, inner-padding);
    $inner-padding-mobile: map-get($merged, inner-padding-mobile);
    $height: map-get($merged, height);
    $box-shadow-bottom: map-get($merged, box-shadow-bottom);
    $box-shadow-side: map-get($merged, box-shadow-side);

    --ranked-games-container-border-color: #{$border-color};
    --ranked-games-container-border-top-height: #{$border-top-height};
    --ranked-games-container-bg-color: #{$bg-color};
    --ranked-games-container-inner-padding: #{$inner-padding};
    --ranked-games-container-inner-padding-mobile: #{$inner-padding-mobile};
    --ranked-games-container-height: #{$height};
    --ranked-games-container-box-shadow-bottom: #{$box-shadow-bottom};
    --ranked-games-container-box-shadow-side: #{$box-shadow-side};
    @include curved-container-component($border-color, $bg-color);
}

$ranked-tiles: (
    border-color: white,
    border-top-height: 1px,
    bg-color: white,
    inner-padding: 1.5rem,
    inner-padding-mobile: 0.75rem,
    height: auto,
    box-shadow-bottom: transparent,
    box-shadow-side: transparent,
);