/***********************
* Gift Container skin  *
***********************/

// Styles
.gift-container__wrapper--gift-item {
  .price-container {
    .price-container-inner {
      > .price-container__component, .multiple-receivers {
        @extend %text;
      }
    }
  }
}

.image-container {
  height: 100% !important;
  width: calc(100% - 3rem) !important;
  border-radius: 1.5rem;
  overflow: hidden;
  @include aspect-ratio(1,1);
  img {
    width: 80% !important;
    margin: auto 10% !important;
  }
}