.btt__wrapper {
    &.royal4 {
        box-shadow: 0 -3px 3px #2d9fc2, inset 0 0 12px #2d9fc2;
    }

    &.royal8 {
        box-shadow: 0 -3px 3px #2d9fc2, inset 0 0 12px #2d9fc2;
    }

    &.royal16 {
        background-color: rgba(255, 0, 234, 0.25);
    }

    &.royal9 {
        box-shadow: 0 -3px 3px #454c84, inset 0 0 12px #454c84;
        background-color: rgb(136 126 253 / 25%);
    }
}