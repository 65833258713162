.report__container--middle-text {
    &:before {
        @include separator(orange);
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
    }
}

.report__custom-select__option {
    display: flex;
    align-items: center;
    padding: .5rem;
    border-radius: $radius--default;
    
    > p {
      @extend %text;
      font-size: 1.8rem;
    }
    
    &:hover {
      cursor: pointer;
      background-color: lighten($main, 20%);
    }
}