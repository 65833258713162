/**********************************
*      Emoticons list skin        *
**********************************/

emoticons-list {
  @extend %container--bordered;
  border: none;
  border-radius: 0;
}

.emoticons-drawer__container--items {
  @include scrollbar();
}
