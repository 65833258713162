/********************************************
*          Items slider skin                *
********************************************/

// Variables
$bet__color: $color--chips;
$color__background: rgba(0, 0, 0, .26);

%change-button {
  position: relative;
  padding: 0;
  height: toPixels(4.4rem);
  min-width: 4.4rem;
  margin: 0;
  min-height: 4.4rem;
  @media only screen and (max-width: $break-point--768) {
    height: toPixels(4.4rem) !important;
    min-height: 4.4rem !important;
    line-height: toPixels(4.4rem) !important;
  }
  &:before {
    display: block;
    color: $color__white;
    font-size: 1.8rem;
    top: 0;
    height: toPixels(4.4rem) !important;
    min-height: 4.4rem !important;
    line-height: toPixels(4.4rem) !important;
    @media only screen and (max-width: $break-point--768) {
      height: toPixels(4.4rem) !important;
      min-height: 4.4rem !important;
      line-height: toPixels(4.4rem) !important;
    }
  }
  &:focus {
    background-color: $color__background;
  }
  &:active {
    background-color: $color__background;
  }
  &:hover {
    background-color: $color__background;
  }
  &:disabled {
    background: $color__background;
    border: none;
    padding: 0;
    &:hover {
      cursor: not-allowed;
      background-color: $color__background;
      border: none;
    }
  }
}

// Dynamic bet
.fis__container--values {
  background-color: $color__background;
}

.fis__text--label {
  @extend %text;
  @include label-icon( 2.4rem );
  color: $color__chips;
}

.fis__text--option {
  @extend %text;
    &.image {
        direction: ltr !important;
    }
}

.fis__text--value{
  &.bet,
  &.chips {
    @include chip-sign( 2rem );
  }

  &.gems {
    @include chip-sign( 2rem, $icon-gems, $color__white );
  }
}

.fis__container--values {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26rem;
  margin: .3rem auto 1.5rem;
  padding: 0 .5rem;
  border-radius: .8rem;
  .fis__container--slide {
    position: relative;
    padding: .3rem 0;
    overflow: hidden;
    line-height: 50px;
    height: 50px;
    @media only screen and (max-height: $break-point--568) {
      line-height: 40px;
      height: 40px;
    }
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    width: 15rem;
    &:hover {
      cursor: pointer;
    }
    &.image {
      height: 8rem;
    }
  }
}

.fis__container--option {
  display: flex;
  align-items: center;
  &.image {
    width: 15rem;
    height: 7rem;
    margin: .5rem 4rem;
  }
}

.fis__text--option {
  position: relative;
  display: block;
  width: 15rem;
  min-width: 15rem;
  text-align: center;
}

.fis__text--label {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  left: 50%;
  padding: .5rem;
  font-weight: 600;
  font-size: $text__size--large;
  transform: translateX(-50%);
  line-height: 20px;
}

.fis__container--single-option {
  position: relative;
  display: block;
  width: 17rem;
  min-width: 17rem;
  text-align: center;
}

.fis__button--left, .fis__button--right {
  margin: 0 0.5rem;
}

.fis__image--value {
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  overflow: hidden;
  > .img-slider__image--preview {
    height: 100%;
  }

  > .img-slider__container--placeholder {
    width: 100%;
    height: 100%;
  }
}

.fis__text--value {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: $text__size--normal; //large;
  font-weight: 600;
  &:not( .chips ):not( .gems ) {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
  .currency {
    margin: .2rem;
  }
  @media only screen and (max-width: $break-point--360) {
    font-size: $text__size--small;
  }
  > .fis__text--time-value,
  .fis__text--points-value {
    margin: 0 .5rem;
  }
  > span {
    line-height: 18px;
  }
  &.chips, &.gems {
    margin-left: 2.4rem;
  }
}
