/*******************************
* Top bar notifications belot *
*******************************/

.top-bar__container--notification-popup {
    z-index: $layer--3 + 3;
}

.top-bar__container--notification {
    background-color: $black__transparent--09;
}

.top-bar-notif__container--text {
    .top-bar__text {
        @extend %text;
        > span {
            color: $color__chips;
        }
    }
}

.top-bar-notif__container--title {
    color: $accent--1;
}

.rate-stars {
    @include icon($star, 1.4rem, $color__chips, $before);
    margin-left: 1.4rem;
    position: relative;

    &:before {
        position: absolute;
        top: 50%;
        transform: translateX(-100%) translateY(-50%);
        @at-root .lang-ar &,
        .lang-fa & {
            transform: translateX(-150%) translateY(-50%);
        }
    }

    
}