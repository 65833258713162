/**********************************
*      Global feed skin           *
**********************************/

// Variables
$chat__icons--color: $accent--1;
$chat__link--color: $color__link;
$chat__message-text--color: $color--bar;
$chat__message-username--color: $color--bar;
$chat__message-sent-before--color: $black--7;
$chat__message-open--actions--color: $black--7;
$chat__message-opened--actions--color: $chat__icons--color;
$chat__message-bg--color: $color__white;
$chat__message-sticky-bg--color: $accent--1;
$chat__message-sticky-username--color: $chat__message-username--color;
$chat__message-sticky-text--color: $chat__message-text--color;
$chat__my-message-bg--color: $black--b;
$chat__icons-sticky--color: $chat__icons--color;
$chat__message-sticky-sent-before--color: $chat__message-sent-before--color;
$chat__message-kick-bg--color: $color--chips;
$chat__message-unsent--color: darkred;

.container--single-message {
    .text--single-message {
        @include container--colored($chat__message-bg--color, 0);
        border-radius: 1.8rem;
        box-shadow: none;
    }

    &.invite:not(.is-deleted) {
        .text--single-message {
            @include container--colored($chat__message-bg--color, 0);
        }
    }

    &.kick {
        .text--single-message {
            background-color: $chat__message-kick-bg--color;
            color: $color__white;
        }
    }
}

.reverse-message {
    .text--single-message {
        @extend %container--normal;
        border: 0;
        border-radius: 1.8rem;
        background-color: $chat__my-message-bg--color;
        box-shadow: none;
    }
}

.chat__message--actions {
    background-color: $color__white;
    border-radius: 1.8rem;
    width: 100%;
    height: 100%;
}

.actions__button--close {
    color: $black--7;

    &:hover {
        i:before {
            color: lighten($black--7, 10%);
        }
    }
}

.unsent-message {
    .action__button {
        .link--format-button {
            color: $chat__message-unsent--color;

            &:hover {
                i:before {
                    color: lighten($chat__message-unsent--color, 10%);
                }
            }
        }
    }
}

.link--format-button {
    color: $chat__icons--color;
}

.transformed-link {
    max-width: 100%;
    word-wrap: break-word;
    color: $chat__link--color;
}

.text--footer {
    @extend %text;
}

.text--footer--unsent {
    color: $chat__message-unsent--color;
}

.text--footer--timestamp {
    color: $chat__message-sent-before--color;
}

.msg-username {
    color: $chat__message-username--color;
}

.message-icon--pin {
    color: $chat__icons-sticky--color;
}

.action__button--menu {
    min-height: auto !important;
    height: auto !important;
}

.action__button--text {
    color: $black--7;
}

.message-sticky {
    .text--single-message {

        .msg-username {
            color: $chat__message-sticky-username--color;
        }

        .text--footer--timestamp {
            color: $chat__message-sticky-sent-before--color;
        }

        .action__button {
            &:before {
                color: $chat__icons-sticky--color;
            }

            &:hover {
                &:before {
                    color: lighten($chat__icons-sticky--color, 10%);
                }
            }
        }
    }
}

.action__button--open-bclass {
    color: $chat__message-open--actions--color !important;
}

.action__button--opened-bclass {
    color: $chat__message-opened--actions--color !important;
}

.message__kick-content-text {
    color: $color__white !important;
}

.message__enter {
    animation: messageEnterAnimation .2s linear forwards;
}

.message__exit {
    animation: messageExitAnimation .2s linear forwards;
}

@keyframes messageEnterAnimation {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes messageExitAnimation {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(100%);
    }
}
