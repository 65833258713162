.club--edit--role__wrapper {
    padding: 2rem;
}

.club--edit--role__text {
    text-align: center;
    font-size: $text__size--large;

    > span {
        color: $color__chips;
    }
}