.club--activity--feed__separator {
    position: relative;
    width: 100%;

    &:before {
        @include separator(yellow);
    }
}

.club--activity__header--pending-requests {
    color: $color--negative;
}

.club--activity__list--item {
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--small;
    }
}

.club--activity--feed__header--text {
    font-size: $text__size--x-large;
    text-align: center;
    width: 100%;
}

.club--activity__list {
    @include scrollbar();
}

.club--activity__header--new-activity {
    background: $black__transparent--07;
}

.club--activity__header--request--cooldown {
    @extend %text;
}

.club--activity--popup-container {
    max-height: 75rem !important;

    @media only screen and (max-height: 780px) {
        max-height: 96% !important;
    }
}
