/*************************
*  Topbar styles        *
*************************/
$top-bar-height: 6rem;
$top-bar-height-1366: 5rem;
$top-bar-height-768: 4rem;

// Styles
.top-bar__button-icon {
  font-size: 3rem !important;

  @media only screen and (max-width: $break-point--768) {
    font-size: 2.4rem !important;
  }
}

.top-bar__challenges-icon-active {
    color: $active--color;
}

.top-bar__settings__menu-icon {
  font-size: 3rem !important;

  @media only screen and (max-width: $break-point--768) {
    font-size: 2.4rem !important;
  }
}

.top-bar__dot--icon {
  border-color: $color--bar !important;
}

.top-bar__menu-icon {
  font-size: 4rem !important;

  @media only screen and (max-width: $break-point--768) {
    font-size: 3rem !important;
  }
}

.top-bar__wrapper {
  background-color: $color--bar;
}

.top-bar__outer--container {
    max-width: $max-content-width;

    @media screen and (max-width: $break-point--1440) {
        max-width: $max-content-width-1440;
    }
}

.settings__icon-active {
  color: $color--selected;
}

.top-bar__wrapper--button-icon {
  &.settings {
    &.newDesign {
      display: none;
    }
  }
}