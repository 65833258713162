/**********************************
*      Edit profile skin          *
**********************************/

// Styles
.edit-profile__wrapper {
  @include scrollbar();
}


.edit-profile__container--gender {
  padding: 0;
  margin: 2rem 0 0;
  @media only screen and (max-width: $break-point--480 ) {
    margin: 0;
  }
}

.edit-profile__text--hint {
  @extend %text;
  color: transparentize( $color__white, .5 );
  text-align: left;
}

.edit-profile__custom-select {
  height: 4rem;
  @media only screen and (max-width: $break-point--768) {
      height: 3rem;
  }
}

.edit-profile__custom-select__element {
  height: 100%;
}

.edit-profile__custom-select__option {
  display: flex;
  align-items: center;
  padding: .5rem;
  border-radius: $radius--default;

  > p {
    @extend %text;
    font-size: 1.8rem;
  }

  &:hover {
    cursor: pointer;
    background-color: lighten($main, 20%);
  }
}