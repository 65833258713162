/******************************
*   Special Phrases skin      *
******************************/

$title-background: imageUrl( 'game/emoticons/pink-gradient.png' );
// Style
.custom-phrases__container--title {
  position: relative;
  background-image: url( $title-background );
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: center;
  &:before {
    @include separator( orange );
  }
}

.custom-phrases__list {
  background-color: $black__transparent--02;
  &:before {
    @include separator( orange );
  }
  > .custom-phrases__constainer-list {
    flex: 1 1 auto;
    position: relative;
    > .custom-phrases__container--item-special {
      background-color: $color__white;
      border: .2rem solid $accent--1;
      border-radius: $radius--default;
      > p {
          @extend %text;
        color: $black__transparent--06;
      }
    }
    > .custom-phrases__container--item-default {
      background-color: $secondary;
      color: $color__white !important;
      font-weight: 400;
      &:hover {
        background-color: light($secondary);
      }

      > p {
        @extend %text;
      }
    }
    .vip {
      position: relative;
      background-color: lighten( $secondary, 5% );
      border: .2rem solid $accent--1;
      color: $color__white !important;
      font-weight: 400;
      &.player-non-vip {
        &:before {
          @include background-image(imageUrl($locked-photo));
          background-position: 50% 50%;
          background-size: 4rem;
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 2;
          @media only screen and (max-width: $break-point--480) {
            background-size: 3rem;
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $black__transparent--08;
        }
      }
      &:hover {
        background-color: lighten( $secondary, 10% );
      }
    }
  }
}

.phrases__vip-crown__container {
  display: none;
}