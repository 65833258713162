.buy-in__balance-wrapper {
    @extend %text;
    &.insufficient {
        color: $color--negative;
    }

    &.hidden {
        visibility: hidden;
    }
}

.buy-in__text {
    text-align: center;
    font-size: $text__size--large;
    padding-bottom: 1rem;
    > .twp__text {
        @extend %text;
    }
}