.club__notification-result__container {
    @include container--colored(transparent);
}

.club__notification-result__label {
    font-size: 1.3em;
    text-shadow: $text--shadow;
    font-weight: $font-weight-bold;

    @if ($skin-font-family == "Cairo") {
        line-height: 1;
    }
}

.club__notification-result__icon--status {
    &.negative {
        color: $color--negative;
    }

    &.positive {
        color: $active--color;
    }
}
