.challenge-badge__container {
    width: 100%;
    position: relative;
    &.with--tooltip {
        cursor: pointer;
    }
}

.challenges__icon {
    width: 100% !important;
}

.completed--challenge__current--level {
    position: absolute;
    bottom: .5rem;
    left: 50%;
    transform: translate(-50%, 0);
}

.challenge-badge__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50% !important;
}

.challenge--badge__main--info__title {
    font-size: $text__size--large;
    font-weight: $font-weight-bold;
    color: $color--chips;
    @extend %text;
}

.challenge--badge__main--info__completed-at {
    text-align: end;
    @extend %text;
}

.challenge--badge__main--info__description {
    @extend %text;
}

.challenge__badge--component {
    width: 100%;
    @include aspect-ratio(1, 1.054);
}