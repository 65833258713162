/******************************
*     Phrases list skin     *
******************************/

.shop-chips__container--vip-bonus-text {
  >p {
    @extend %text;
    color: $accent--1;
  }
}

.shop-chips__wrapper {
  @include scrollbar(.5rem);
}

.shop-chips__wrapper {
  @extend %shop-wrapper;
}

.shop-chips__box {
  @extend %shop-box;
}
