/***********************
*      Friends skin    *
***********************/

$color--online: $active--color;
$color--offline: $color__disabled;

.friends__container--main {
  @include scrollbar();
}

.friends__text-invite-friends {
  @extend %text;
}

.friends__list--header-title {
    @extend %text;

    &.online {
        &:before {
            @extend %background--image;
            background-color: $color--online;
            border-radius: 50%;
        }
    }

    &.offline {
        &:before {
            @extend %background--image;
            background-color: $color--offline;
            border-radius: 50%;
        }
    }
}

.friends-list__close--friend-button {
    color: $accent--1;
}