.chip-icon-component-host {
    direction: ltr !important;
    font-weight: $font-weight-bold;
}

.chip-icon__chip {
    color: $color__chips;
    filter: drop-shadow(0px 0px 0.5rem rgba(0, 0, 0, 0.5));
}

.chip-icon__number {
    font-weight: inherit;
}