.scroll-button-control__container {
    background-color: transparentize($color__black,.8);;
    border-radius: 10rem;
}

.scroll-button-control__button {
    background-color: $color--chips;
    color: $color__white;
    border-radius: 10rem;
    cursor: pointer;
}

.scroll-button-control__fragmets--container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateX(33%)
}

.scroll-button-control__fragmet {
    width: 33%;
    flex: none;
    opacity: .4;
    font-size: $text__size--normal;
    color: rgba(255, 255, 255, 0.618);
    @extend %text;
}

.scroll-button-control__fragmet__current {
    color: $color__white;
    opacity: 1;
    font-size: $text__size--large;
}

.scroll-button-control__container__ {
    width: inherit;
    height: inherit;
    padding: 0.5rem;
    overflow: hidden;
    position: relative;
}