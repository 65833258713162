/********************************************
*             Room styles skin           *
********************************************/

.mr__leaderboards {
    @include icon($trophy, 4rem, $accent--1, $before);
    transition: background-color 0.3s ease-in-out;
    border: 0.1rem solid $accent--1;
    background-color: $black__transparent--02;

    &:hover {
        background-color: lighten($promo__popup--tabs, 3%);
    }

    &:before {
        display: block;
        text-align: center;
    }
}

.mr__wrapper {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}

.bet__label--masters {
    > .custom-bet__text--label-dynamic__text {
        @include icon(
                $icon-masters,
                $icon__size--default - 0.6rem,
                $color__white,
                $before
        );
        @include icon(
                $icon-masters,
                $icon__size--default - 0.6rem,
                $color__white,
                $after
        );
        position: relative;
        color: $color__white;

        &:before {
            position: absolute;
            top: -0.25rem;
            left: -3rem;
            width: $icon__size--default - 0.6rem;
        }

        &:after {
            position: absolute;
            top: -0.25rem;
            right: -3rem;
            width: $icon__size--default - 0.6rem;
        }
    }
}

.bet__label--professionals {
    > .custom-bet__text--label-dynamic__text {
        @include icon(
                $icon-professionals,
                $icon__size--default - 0.6rem,
                $color__white,
                $before
        );
        @include icon(
                $icon-professionals,
                $icon__size--default - 0.6rem,
                $color__white,
                $after
        );
        position: relative;
        color: $color__white;

        &:before {
            position: absolute;
            top: -0.25rem;
            left: -3rem;
            width: $icon__size--default - 0.6rem;
        }

        &:after {
            position: absolute;
            top: -0.25rem;
            right: -3rem;
            width: $icon__size--default - 0.6rem;
        }
    }
}

.bet__label--diamond {
    > .custom-bet__text--label-dynamic__text {
        @include icon(
                $icon-masters,
                $icon__size--default,
                $color__white,
                $before
        );
        @include icon(
                $icon-masters,
                $icon__size--default,
                $color__white,
                $after
        );
        position: relative;
        color: $color__white;

        &:before {
            position: absolute;
            top: -0.4rem;
            left: -4rem;
        }

        &:after {
            position: absolute;
            top: -0.4rem;
            right: -4rem;
        }
    }
}

.bet__label--legends {
    > .custom-bet__text--label-dynamic__text {
        @include icon(
            $icon-legends,
            $icon__size--default,
            $color__white,
            $before
        );
        @include icon(
            $icon-legends,
            $icon__size--default,
            $color__white,
            $after
        );
        position: relative;
        color: $color__white;

        &:before {
            position: absolute;
            top: -0.4rem;
            left: -4rem;
        }

        &:after {
            position: absolute;
            top: -0.4rem;
            right: -4rem;
        }
    }
}

.bet__label--supreme {
    > .custom-bet__text--label-dynamic__text {
        @include icon(
                $icon-supreme,
                $icon__size--default,
                $color__white,
                $before
        );
        @include icon(
                $icon-supreme,
                $icon__size--default,
                $color__white,
                $after
        );
        position: relative;
        color: $color__white;

        &:before {
            position: absolute;
            top: -0.4rem;
            left: -4rem;
        }

        &:after {
            position: absolute;
            top: -0.4rem;
            right: -4rem;
        }
    }
}

.bet__label--geniuses {
    > .custom-bet__text--label-dynamic__text {
        @include icon(
                $icon-geniuses,
                $icon__size--default,
                $color__white,
                $before
        );
        @include icon(
                $icon-geniuses,
                $icon__size--default,
                $color__white,
                $after
        );
        position: relative;
        color: $color__white;

        &:before {
            position: absolute;
            top: -0.4rem;
            left: -4rem;
        }

        &:after {
            position: absolute;
            top: -0.4rem;
            right: -4rem;
        }
    }
}

.bet__label--elite {
    > .custom-bet__text--label-dynamic__text {
        @include icon(
                $icon-elite,
                $icon__size--default,
                $color__white,
                $before
        );
        @include icon(
                $icon-elite,
                $icon__size--default,
                $color__white,
                $after
        );
        position: relative;
        color: $color__white;

        &:before {
            position: absolute;
            top: -0.4rem;
            left: -4rem;
        }

        &:after {
            position: absolute;
            top: -0.4rem;
            right: -4rem;
        }
    }
}

.bet__label--sultans {
    > .custom-bet__text--label-dynamic__text {
        @include icon(
                $icon-sultans,
                $icon__size--default,
                $color__white,
                $before
        );
        @include icon(
                $icon-sultans,
                $icon__size--default,
                $color__white,
                $after
        );
        position: relative;
        color: $color__white;

        &:before {
            position: absolute;
            top: -0.4rem;
            left: -4rem;
        }

        &:after {
            position: absolute;
            top: -0.4rem;
            right: -4rem;
        }
    }
}

.mr__players-counter {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}