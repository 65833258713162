/***************************************
*      Blitz-tournament skin           *
***************************************/

// Placeholders
%blitz-round {
  @media only screen and (max-width: $break-point--980) {
    @include container--colored($container--4);
    background-color: transparent;
    border: none;
  }
}

// Styles
.blitz-tournament__wrapper {
  @include scrollbar();
}

.blitz-tournament__container--round {
  @extend %blitz-round;
}

.blitz-tournament__container--box-left-line {
  border-left: .2rem solid $color__white;
  > .line-box {
    border-bottom: .2rem solid $color__white;
  }
}

.team-player {
  @include container--colored($color__black);
}


.blitz-tournament__container--box-right-line {
  &.final {
    border-right: .2rem solid $color__white;
  }
  > .line-box {
    border-bottom: .2rem solid $color__white;
  }
}

.blitz-tournament__container--round-bottom-line {
  @media only screen and (max-width: $break-point--980) {
    > .line-box {
      border-right: .2rem solid $color__white;
    }
  }
}
