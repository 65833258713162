.ft__container--toggle {
    .ft__text--toggle-label {
        @extend %text;
        @include label-icon(2.4rem);
        text-align: left;
        color: $color--chips;
        position: relative;
    }
}

.ft__container--toggle--hint {
    > p {
        @extend %text;
        color: $white__transparent--05;
    }
}
