.notification-button__active {
  color: $accent--1;
}

.notification-button__notification-sign {
  pointer-events: none;
  left: 50%;
  top: 50%;
  transform: translate(-100%, -80%);
  margin: -0.1rem;
  position: absolute;

  &.text-button {
      left: 3%;
      top: 15%;
      transform: translate(-50%, -50%);
  }
}