.item-store-ribbon__container--ribbon-status {
    @extend %background--image;
    background-image: url(imageUrl("shop/owned-badge.svg"));
}

.item-store-ribbon__container--ribbon-rent {
    > p {
        @extend %text;
        text-align: left;
    }
}

.item-store-ribbon__container--ribbon-icon {
    color: $color__white;
    font-size: $text__size--x-large;
}