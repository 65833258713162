.friend-requests__container--friend {
    @include container--colored($main);
    transition: background-color ease 1s;

    &.highlighted {
        background-color: light($main);
    }
}

.friend-requests__text--username {
    color: $color__white;
}

.friend-requests__container--send-message {
    min-width: 4rem;
}

.friend-requests__scroll-container {
    position: relative;
    height:  100%;
    width: 100%;
}