/******************************
*           Game skin         *
******************************/

.game {
  background-position: center center;
  background-size: cover;
  background-color: $main;
}

.game-wrapper {
  position: absolute;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.game__rotate {
  background-color: $color__black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.game__rotate--icon {
  font-size: 10rem;
  line-height: 0;
  margin: 1rem;
}