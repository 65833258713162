.daily-reward {
    background-color: $black__transparent--05;

    &.registerAdvent {
        border: none;

        &.current {
            border: .3rem solid #f6b229;
            background-color: #7D2F9E;
        }

        &.highlighted {
            background-color: #7D2F9E;
        }
    }
}