$image--placeholder: imageUrl("gallery/photo.svg");
$locked-photo-placeholder: imageUrl("gallery/LockedPhoto.svg");
$locked-photo-image: imageUrl("gallery/icon-lockedphoto.svg");

.photo__image--placeholder {
    @include background-image($image--placeholder, 100%, 100%);

    &.locked {
        @include background-image($locked-photo-image, 70%, 70%);
        border-radius: .8rem;
    }
}

.photo__container--image-cover {
    background-color: $color__white;
}

.photo__locked-tint {
    background-color: $black__transparent--08;
}

.photo__locked-img {
    @include background-image($locked-photo-image, 70%, 70%);
}
