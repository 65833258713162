.item-reward {
    font-size: 1.8rem;
}

.item-reward__reward {
    @include aspect-ratio(0.725, 1);
    width: auto !important;
    height: auto !important;
    border: none;
    border-radius: .8rem;

    &.backgroundImage {
        background-color: #00000044;
    }
    &.current {
        border: .1rem solid $accent--1;
    }

    &.claimed {
        background-color: $black__transparent--03;
        overflow: hidden;
        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: $black__transparent--03;
            opacity: 0.8;
        }
    }
}

.item-reward__reward--image {
    width: var(--item-img-width, 75%);
    height: auto;

    &.table {
        width: var(--item-table-img-width, 50%);
    }

    &.chipPack {
        width: var(--item-chips-img-width, var(--item-img-width, 75%));
    }

    &.gemsPack {
        width: var(--item-gems-img-width, var(--item-img-width, 75%));
    }
}

.item-reward__reward--booster {
    font-weight: $font-weight-bold;
    text-align: center;
}

.item-reward__reward {
    font-size: inherit;
}

.item-reward__reward--text {
    font-size: inherit;

    &.hidden {
        display: none;
    }
}

.item-reward__reward--currency {
    font-size: inherit;
}

.item-reward--content {
    padding: var(--inner-content-padding, map-get($item-reward-data, inner-content-padding));
    justify-content: var(--item-reward-justify-content, space-around);
}

.item-reward__reward--image--wrapper {
    height: var(--img-wrapper-height, map-get($item-reward-data, img-wrapper-height));
}
