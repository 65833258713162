.ranked-tile-top-bar__element {
    font-size: $text__size--large;
    color: #7883f8;
    font-size: 1.4rem;

    background-color: #1b2157;
    box-shadow: inset 0px 2px 4px 0px $black__transparent--06,
        0px 1px 0 #5c69d9;
    padding: 1rem;
    border-radius: 10rem;

    @media screen and (max-width: $break-point--568) {
        padding: 0.5rem;
        font-size: 1.3rem;
    }
}

.ranked-room-tile-top-bar {
    background: linear-gradient(90deg, #212560, #31389b, #212560);
    box-shadow: 0 -2px 0px 0px #3a41a1 inset, inset 0 -1px 4px 0 $black__transparent--07;
    padding: 1.25rem 1.25rem 2rem;

    @media screen and (max-width: $break-point--568) {
        padding: 0.75rem 0.75rem 1.5rem;
    }
}

.ranked-tile-top-bar-curved-path-border__class {
    fill: url(#header-shape-gradient);
    filter: drop-shadow(0 1px 2px $black__transparent--07);

    &.desktop {
        fill: url(#header-shape-gradient-desktop);
    }
}

.stop-1 {
    stop-color: #3a41a1;
}

.stop-2 {
    stop-color: #7180d9;
}

.stop-3 {
    stop-color: #3a41a1;
}
