/******************************
*      Profile main skin      *
******************************/

// Styles

.cp__image--logo {
  @include background-image($game__logo, 100%, 100%);
}

.profile__container--club--title {
    font-size: $text__size--x-large;
}


.profile_bg--image {
  mask:
    linear-gradient(to right ,transparent 0% ,white 30% ,white 70%,transparent 100%),
    linear-gradient(to bottom,transparent 0% ,white 30% ,white 70%,transparent 100%);
  mask-composite: intersect;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 20rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-position: 50% 30%;
}