/*******************************
* PromoTournament options skin *
*******************************/

// Styles
.pto__wrapper {
  z-index: 999 !important;
}

.pto__container--contents {
  @include container--colored( $promo__popup--bg, 0.3 );
  padding: 0 1rem 1rem;
  @media only screen and (max-width: $break-point--440) {
    padding: 0 .5rem;
  }
}

.promo-tournaments-options__top-tabs__bclass {
    width: 15rem;
    &.top-tabs__selected {
        background-color: $color--chips;
    }
}

.promo-tournaments-options__top-tabs__container {
    background-color: $black__transparent--03;
    border-radius: 0.8rem;
    padding: .4rem 0;
}