@import "../../../../styles/global/mixins/mixins";

.bling-bling-img {
  display: block;
  width: 56px;
  height: 56px;
  top: 0%;
  left: 0%;
  transform: translate(-50%, -50%);
  position: absolute;
  pointer-events: none;
  @include background-image(imageUrl('lobby/blinkblink.png'), 100%, 100%, left, center);
  animation: blink-blink 1.2s steps(20, jump-end) infinite;
  background-size: auto 100%;
}

@keyframes blink-blink {
  82% {
      background-position: -1120px;
  }
  100% {
    background-position: -1120px;
  }
}