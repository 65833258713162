.image-notification__container--title {
    color: $accent--1;
}

.image-notification__text--notification-info {
    @extend %text;
    padding-right: 1rem;

    @at-root .lang-ar &,
    .lang-fa & {
        padding-right: 2rem;
    }
}

.image-notification__reward {
    font-size: inherit !important;
}

.image-notification__reward:not(:first-child) {
    margin-left: 0.8rem;
}