.profile__top-tabs__bclass {
    background-color: #151835;
    width: 4rem;
    margin: .5rem;

    &.top-tabs__selected {
        background-color: $color--chips;
    }
}

.profile__dot--icon {
    border-color: #192540 !important;
}

.profile__container--router-outlet {
    @include scrollbar();
    @include touch-scroll();
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.profile__top-tabs {
    margin: 0 1rem;
}

.profile__top-tabs__bclass__notification {
    background-color: #151835;
    width: 4rem;
    margin: .5rem;

    &.top-tabs__selected {
        background-color: $color--chips;
    }
}

.profile-top-tabs__tab {
    height: 5rem !important;
    display: flex;
    align-items: center;
}