/***********************
* Popup message skin   *
***********************/

.shop-item-info__container--item-info {
  position: relative;

  &.phrasesList {
    padding-top: 2rem;
  }

  .item-image {
    &.cashbackUnlocker {
      background-image: url(imageUrl("cash-back/stars_animation.png"));
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

.shop-item-info__container--price {
  .price {
    @extend %number;

    &.discount:after {
      border-top: .4rem solid $color--negative;
    }
  }
}

.shop-item-info__container--title {
  >p {
    @extend %text;
  }

  &:before {
    @include separator(yellow);
    top: 0;
  }
}

.shop-item__separator {
  display: none;
}

.item-title {
  font-weight: $font-weight-bold;
}

.item-image__img--element {
  &.cashbackUnlocker {
    animation: shopItemAnimation 0.8s linear infinite alternate;
  }

  &.skippiesPack {
    height: initial !important;
  }
}

.shop-item-info__container--text {
  font-size: $text__size--x-large;
}

@keyframes shopItemAnimation {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

.shop-item-info__container__flag {
  position: absolute;
  top: 0;
}

.item--phrases__container {
  height: calc(100% - 4rem);
  width: 90%;
  margin: 0 auto;
  padding: 2rem 1rem !important;
  background-color: #300327;
  border-radius: $container-border--radius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item--phrase {
  font-size: $text__size--large;
  @extend %text;
  padding: 0.5rem 0;
  display: flex;
  background-color: $color__white;
  border-radius: $radius--default;
  @extend %text;
  color: $black__transparent--06;
  width: fit-content;
  padding: .5rem 1rem;
  margin: .5rem 0;
  text-align: center;

  @media screen and (max-width: $break-point--568) {
    font-size: $text__size--normal;
  }
}

.item-phrase__text {
  margin-left: .2rem;
}