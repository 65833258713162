@mixin separator( $type: orange ) {
  $separator-image: map-get( $separators, $type );
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: .2rem;
  width: 100%;
  background: url($separator-image) repeat-y;
  background-size: 100% 100%;
}
