.audio__content--time {
    color: $chat__message-text--color;
}

.audio__content--progress-class {
    background-color: $color--bar;
}

.audio__content--button-bclass {
    color: $color--bar !important;
}
