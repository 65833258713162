.avatar-notification__container--title {
    color: $accent--1;
}

.avatar-notification__text--notification-info {
    @extend %text;
    font-size: $text__size--normal;
    padding-right: 1rem;
    @at-root .lang-ar &,
    .lang-fa & {
        padding-right: 2rem;
    }
}