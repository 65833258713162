.restriction-level--host {
    background: $black__transparent--05;
    text-transform: uppercase;
    border-radius: 2rem;
}

.restriction__level {
    line-height: 1.8rem;
}

.restriction__icon {
    font-size: 1.8rem;
    margin: 0 .5rem;
}