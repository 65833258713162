.club--jr--status__container {
    @include container--colored(transparent);
}

.club--jr--status__label {
    font-size: 1.3em;
    text-shadow: $text--shadow;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $break-point--768) {
        font-size: 1.2em;
    }
}

.club--jr--status__icon--status {
    &.negative {
        color: $color--negative;
    }

    &.positive {
        color: $active--color;
    }
}
