.ranked-season-tile__container {
    background-size: cover;
    background-color: $main;
    background-position: center;

    &.small-container {
        .pl__container--professionals {
            @media screen and (max-width: $break-point--480) {
                transform: scale(0.8);
            }

            @media screen and (max-width: $break-point--380) {
                transform: scale(0.6);
            }
        }

        .ranked-season-tile-state__text {
            &.small-tile {
                font-size: 2.4rem;

                @media screen and (max-width: $break-point--768) {
                    font-size: 2rem;
                }

                @media screen and (max-width: $break-point--568) {
                    font-size: 1.6rem;
                }

                @media screen and (max-width: $break-point--480) {
                    font-size: 1.2rem;
                }

                @media screen and (max-width: $break-point--380) {
                    font-size: 1rem;
                }
            }

            @media screen and (max-width: $break-point--768) {
                font-size: 2rem;
            }

            @media screen and (max-width: $break-point--568) {
                font-size: 1.8rem;
            }

            @media screen and (max-width: $break-point--480) {
                font-size: 1.4rem;
            }
        }
    }
}

.ranked-season-tile__name {
    position: relative;
    text-align: center;
    font-size: 4vh;
    padding: 0 1rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    &.leaderboard-visible {
        font-size: 2.4vh;
        padding: 0 0.5rem;

        @media screen and (max-width: $break-point--768) {
            font-size: 2.4vh;
        }
    }

    @media screen and (max-width: $break-point--568) {
        font-size: 2.4vh;
    }
}

.ranked-season-tile-state__text {
    color: #f5c845;
    text-shadow: 0px 1px 0px #5a0e08, 0 3px 0px #a62330;
    font-size: 2.6rem;
    text-align: center;
    @extend %text;

    @media screen and (max-width: $break-point--380) {
        font-size: 2rem;
    }
}

.ranked-season-tile__button {
    height: 5rem;

    @media screen and (max-width: $break-point--568) {
        height: 4rem;
    }
}

.ranked-season-tile__button-bclass {
    > p {
        font-size: 2rem !important;

        @media screen and (max-width: $break-point--568) {
            font-size: 1.8rem !important;
        }
    }
}

.ranked-season-tile__info {
    font-size: 2.4rem;
    @media only screen and (max-width: $break-point--980) and (orientation: portrait) {
        font-size: 1.6rem;
    }
    @media screen and (max-width: $break-point--568) {
        font-size: 1.6em;
    }
}

.pl__container--professionals::after {
    display: none !important;
}

.leaderboard-professionals {
    .avatar__container--footer {
        height: 1.6rem!important;

        .avatar__text--username {
            font-size: $text__size--x-small + .2!important;
            width: calc(100% - 1.6rem)!important;
            line-height: 16px!important;
            &.no-frame {
              line-height: 1.8rem!important;
            }
        }
    }

    .avatar__text--level {
        font-size: $text__size--x-small + .2!important;
        line-height: 14px!important;
        bottom: .2rem!important;
        &.no-frame {
          line-height: 1.6rem!important;
          bottom: 0!important;
        }
    }
}