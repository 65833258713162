.training-scroll-tile__title {
    font-size: 2.6rem;
    text-shadow: 0 3px 1px #232728;
}

.training-scroll-tile__room-separator {
    display: none;
}

.training-scroll-tile__description {
    color: $color--white;
    text-shadow: 0 3px 1px #232728;
}

.training-scroll-tile__container {
    background-image: url(imageUrl("lobby/matchmaking-scroll-tile-pattern.svg"));
    background-color: #263c6b;
    background-size: cover;
    background-position: center;
    border-radius: $container-border--radius;
    box-shadow: inset 0 3px 3px #4264ad, inset 0 0 12px #4264ad;
}

.training-scroll-tile__logo {
    background-size: cover;
    background: url(imageUrl("lobby/beginners-default.png"));
    background-position: center;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 70%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
}

.training-scroll-tile__button {
    width: 27rem !important;
    margin-bottom: 7% !important;
    
    @media screen and (max-height: $break-point--1280) {
        margin-bottom: 5% !important;
    }
    
    @media screen and (max-width: $break-point--768) {
        margin-bottom: 6% !important;
    }

    &.isTouch {
        margin-bottom: 5% !important;

        @media screen and (max-width: $break-point--768) {
            margin-bottom: 7% !important;
        }
    }
}

.training-scroll-tile__icon {
    color: #1b2d5b;
    width: 100% !important;
}

.training-scroll-tile__title_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.training-scroll-tile__room-transactions {
    position: absolute;
    bottom: 0;
}

.training-scroll-tile-reward__label {
    display: flex;
    flex-direction: row;
}

.training-scroll-tile-reward__value-label {
    margin-right: 0.5rem;
}

.training-scroll-tile__players-counter {
    padding: .5rem !important;
    color: $color--white;
    border-radius: 10rem;
    width: 7rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #0a0c0f52 !important;
}