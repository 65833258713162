.notification-wrapper__container--main {
    &.gifts,
    &.invites {
        @include container--colored($container--6);
    }

    &.likes,
    &.likesandratings,
    &.club {
        @include container--colored($container--4);
    }

    &.system {
        @include container--colored($container--5);
    }
}