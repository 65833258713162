/********************************
*   Restriction cover styles    *
********************************/

.restriction-vip--host {
    line-height: 1.8rem;
    font-size: 1.8rem;
}

.restriction__vip--text--new-design {
    display: inline-block;

    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--normal;
    }
}

.restriction-vip--host {
    background: $black__transparent--05;
    border-radius: 2rem;
    padding: 1rem;
}

.restriction__vip--icon {
    margin: 0 0.5rem;
}
.restriction__vip--new-design {
    display: none;
}

.restriction__vip--icon--new-design {
    display: none !important;
}