.ranked-games-grid {
    padding: 1rem;

    @media screen and (max-width: $break-point--568) {
        padding: 0.5rem;
    }
}

.ranked-games__tiles--container {
    @include ranked-tiles-colors((border-color: transparent, bg-color: $black__transparent--03, inner-padding: 1.5rem, inner-padding-mobile: 1rem 0.75rem 0.75rem,));
}
