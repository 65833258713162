.item-small-box__xp-item {
    color: $color--chips;
}

.item-small-box__value {
    font-weight: $font-weight-bold;
}

.item-small-component {
    background-color: transparent;
}