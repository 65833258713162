.account-migration-popup__container {
    @include touch-scroll();
    @include scrollbar();
}

.account-migration-popup__header--image {
    background-size: 100%;
    background-repeat: no-repeat;

    &.migration-image {
        background-image: url(imageUrl('account-migration/header.png'));
    }
}

.account-migration-popup--games-image {
    background-size: 100%;
    background-repeat: no-repeat;

    &.games-image {
        background-image: url(imageUrl('account-migration/migrating_games.png'));
        background-position: center;
    }
}

.account-migration-popup--button-action {
    .account-migration-popup__button-text {
        min-width: 100%;

        p {
            font-size: $text__size--large;
        }
    }
}

.account-migration-popup--height {
    max-height: 65rem !important;
}

.account-migration-popup--title {
    font-size: $text__size--x-large;
    font-weight: $font-weight-bold;

    @media only screen and (max-width: $break-point--320) {
        font-size: $text__size--normal;
    }
}

.account-migration-popup--upper-text {
    font-size: $text__size--normal;
    margin-top: 1rem;
}

.account-migration-popup--info-text {
    font-size: $text__size--small;
}