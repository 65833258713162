.register-bonus--host {
    height: 100%;
    display: block;
}

.register-bonus-rewards {
    @include scrollbar();
    @include scrol-container();
}

.register-bonus-daily-reward--timer-container {
    background: #96b0d4 !important;
    text-shadow: none !important;
    height: auto !important;
    box-shadow: none !important;
    border-top: none !important;
}

.register-bonus__timer {
    font-size: 1.5rem !important;

    .time {
        color: #041125 !important;
        font-weight: $font-weight-bold;
    }
}

.register-bonus-rewards-claim-button-timer {
    color: inherit !important;

    .time {
        font-size: 1.5rem !important;
        color: inherit !important;
        text-shadow: inherit !important;

        @media screen and (max-width: $break-point--568) {
            font-size: 1.4rem !important;
        }
    }
}

.register-bonus-rewards-claim-button-bclass {
    > p {
        @media screen and (max-width: $break-point--568) {
            font-size: 1.4rem !important;
        }
    }
}

.register-bonus-rewards-stamp--completed {
    @include stamp-bordered-text((
        text-padding: 0.25rem 0.75rem,
        text-margin: 0.5rem,
    ));
    stroke-width: 4px !important;
    stroke: currentColor !important;
}