.invite-friends__wrapper {
    border: 1px solid $accent--1;
    border-radius: .8rem;
}

.invite-friends__title--wrapper {
    background-color: $black__transparent--02;
    @extend %text;
}

.invite-friends__banner--text {
    @extend %text;
    > p {
        display: inline !important;
    }
}

.invite-friends__chips--text {
    color: $accent--1;
}

.invite-friends__banner {
    &:after {
        @include separator(orange);
    }
}

.invite-friends__banner--img {
    background-image: url(imageUrl('shop/items/chipPack2.png'));
    background-repeat: no-repeat;
    background-position: center;
}

.invite-friends__buttons--mobile {
    @media only screen and (max-width: $break-point--768) {
        i {
            left: .35rem;
        }
        i:before {
            font-size: $text__size--normal;
            line-height: $text__size--normal;
        }
    }
}