$ribbon-gold: imageUrl('shop/vip-ribbon.svg');

.shop-item-component__title {
    @extend %text;
    width: 100%;
    font-size: $text__size--x-large;
    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--large;
    }

    &.first {
        color: $accent--1;
        font-weight: 600;
    }

    &.second {
        @extend %text;
        font-size: $text__size--large;
        @media only screen and (max-width: $break-point--480) {
            font-size: $text__size--normal;
        }
    }

    &.currency {
        @include chip-sign($icon__size--default);
        width: auto;
    }

    &.gems {
        @include chip-sign($icon__size--default);
        width: auto;

        @include icon($icon-gems, $icon__size--default, $color__gems, $before);
    }

    &.vip {
        @include background-image($ribbon-gold);
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color--bar;
    }

    &.exchange {
        @include chip-sign($icon__size--default);
        width: auto;
    }
}