.ciw__container--plus-sign {
    color: $color--chips;
}

.ciw__container--currencies__active {
    background-color: $color--bar;
    width: 100% !important;
}

.ciw__container--skippies-currency-item {
    display: none !important;
}