.phone-register__custom-select__icon {
    width: 2rem;
    margin-right: 1rem;
}

.phone-register__custom-select__option {
    display: flex;
    align-items: center;
    padding: .2rem;
    border-radius: $radius--default;

    > p {
        @extend %text;
        font-size: $text__size--large;
    }

    &:hover {
        cursor: pointer;
        background-color: lighten($main, 20%);
    }
}

.phone-register__custom-select {
    width: 12rem;
    height: 4rem;
    @media only screen and (max-width: $break-point--768) {
        height: 3rem !important;
    }
}

.phone-register__custom-select__option__text {
    direction: ltr !important;
}