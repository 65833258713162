/*************************
*  Avatar styles belot   *
*************************/

// Style
.avatar__container--footer {
  background-color: $color__black;
}

.avatar__image--placeholder {
  @include background-image(imageUrl( 'placeholders/placeholder.svg' ));
}

.avatar__text--username {
  @extend %text;
}

.avatar__text--level {
  color: $color__white;
  text-shadow: -1px -1px 0 $black__transparent--05, 1px -1px 0 $black__transparent--05, -1px 1px 0 $black__transparent--05,  1px 1px 0 $black__transparent--05;
}
