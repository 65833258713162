@mixin item-component($map: $item-component-data) {
    $merged: map-merge-deep($item-component-data, $map);

    $height: map-get($merged, height);
    $width: map-get($merged, width);

    --item-component-img-width: #{$width};
    --item-component-height: #{$height};
}

$item-component-data: (
    width: 100%,
    height: 100%
);