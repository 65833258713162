.progressive-ads-bonus__reward--booster {
    font-size: 1.2em;

    >p {
        @extend %text;
        text-align: center;
    }

    @media screen and (max-width: $break-point--568) {
        font-size: 1em;
    }
}

.progressive-ads-bonus__reward--currency {
    font-size: $text__size--x-large !important;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.6rem !important;
    }

    @media screen and (max-width: $break-point--360) {
        font-size: $text__size--small !important;
    }
}

.progressive-ads-bonus--button {
    height: 5rem !important;

    @media screen and (max-width:$break-point--768) {
        height: 4.5rem !important;
    }

    @media only screen and (max-width: $break-point--440) {
        font-size: $text__size--smaller;
    }

    @media only screen and (max-width: $break-point--380) {
        font-size: $text__size--x-small;
    }
}

.progressive-ads-bonus--button-bclass>p {
    @media screen and (max-width:$break-point--768) {
        font-size: 0.8rem !important;
    }
}

.progressive-ads-bonus__reward-wrapper {
    border: none;
    overflow: visible;
    background-color: #00000000;
}

.progressive-ads-bonus__reward {
    @include aspect-ratio(0.725, 1);
    background-color: #00000044;
    height: unset !important;
    display: flex !important;
    height: auto !important;
    justify-content: center;
    align-items: center;

    &:not(.current) {
        border: none;
    }

    &.claimed {
        background-image: none;
        background-color: $black__transparent--03;

        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: $black__transparent--03;
            opacity: 0.8;
        }
    }
}

.progressive-ads-bonus__reward--item {
    @media only screen and (max-width: $break-point--900) {
        font-size: 1.2rem !important;
    }

    @media only screen and (max-width: $break-point--320) {
        font-size: 1rem !important;
    }
}