.ranked-games-leaderboard__item-rank,
.ranked-games-leaderboard__item-name,
.ranked-games-leaderboard__item-win-rate,
.ranked-games-leaderboard__item-score {
    font-size: 2em !important;
}

.ranked-games-leaderboard__item-name-text {
    margin-left: 1.5rem;

    @media screen and (max-width: $break-point--568) {
        margin-left: 0.5rem;
    }
}

.ranked-games-leaderboard-item {
    border-radius: $container-border--radius;
    overflow: hidden;
    background: #111a2d;
    box-shadow: 0 4px 4px 0px #070823;

    &.current-player {
        background: #172f56;
    }

    &.sticky {
        background: #71c122;
        color: white;
    }

    &.golden {
        background: $color__chips;
        font-weight: bold;
        color: #38383a;
    }

    &.silver {
        background: #c8cdd3;
        color: #38383a;
        font-weight: bold;
    }

    &.bronze {
        background: #bfa088;
        color: #351604;
        font-weight: bold;
    }
}