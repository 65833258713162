/************************************
*  User list skin                   *
************************************/

.user-list-item__container--score {
  >p {
    @include chip-sign(1.4rem);
  }
  &.pro {
    >p {
        @include icon($star, $text__size--normal, $color__chips, before);
    }
  }
  &.best {
    >p {
        @include icon($star, $text__size--normal, $color__chips, before);
    }
  }
  &.likes {
    >p {
        @include icon($star, $text__size--normal, $color__chips, before);
    }
  }
  &.richest,
  &.earnedchips {
    >p {
        @include icon($star, $text__size--normal, $color__chips, before);
    }
  }
}
