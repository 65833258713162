.fl__text--reward-value {
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &.none {
        @extend %text;
    }
}

.fl__text--reward-label {
    @extend %text;
}

.fl__text--label {
    @extend %text;
    @include label-icon(2.4rem);
    color: $color__chips;
}

.fl__container--values {
    background-color: rgba(0, 0, 0, 0.26);
    font-weight: $font-weight-bold;
}
