/******************************
*     Simple message skin     *
******************************/

.simple-message__main--simple-message {
  @extend %container--bordered;
}

.simple-message__container--title {
  &:after {
    @include separator( $type: orange );
  }
}

.simple-message__container--message {
  @include scrollbar();
}
