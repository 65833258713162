.ranked-seasons-leaderboards-host {
    display: block;
    height: calc(100% - 8rem);
}

.ranked-season--my-position-text {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    padding: 1rem 0;

    @media screen and (max-width: $break-point--480) {
        font-size: 1.5rem;
        padding: 0;
    }
}