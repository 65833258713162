.invite-friends__title--wrapper {
    background-color: #4C6EAF;
    border-top-right-radius: $border--radius;
    border-top-left-radius: $border--radius;
}

.invite-friends__wrapper {
    background-color: $black__transparent--05;
    border-radius: $border--radius;
    border: none;
    margin-top: 1rem;
}

.invite-friends__content{
    padding-bottom: 1rem;
}