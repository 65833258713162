$container__text-color: $color__white;

.statistics-panel__wrapper {
    @include container--colored($container-dark);
}

.statistics-panel__container--value {
    > p {
        @extend %text;
        color: $accent--1;
    }
}

.statistics-panel__container--icon--cmp {
    color: $container__text-color;
    font-size: 6rem;

    @media only screen and (max-width: $break-point--480) {
        font-size: 3rem;
    }
}
