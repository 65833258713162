.happy-hour-container {
    background: url(imageUrl('shop/happy-hour-banner.png')), linear-gradient(#efe457, #feae31);
    background-size: contain;
    height: 10rem;
    border-radius: $container-border--radius;
    border-bottom: 0.1rem solid #f0e155;
    position: relative;
}

.happy-hour-flag {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.happy-hour-timer {
    font-size: 1.5rem !important;
    > span {
        color: $color--white;
        font-weight: $font-weight-bold;
    }
}

.happy-hour-flag__container {
    background: linear-gradient(0deg, #070707 0%, #44484c 100%) !important;
    box-shadow: 0 2px 3px #7c6e12 !important;
}

.happy-hour__title {
    font-weight: $font-weight-extra-bold;
    color: #151617;
    text-shadow: 0 1px 0 #feab00, 0 2px 1px #00000047, 0 3px 0 #feab00;
    width: 100%;
    padding-top: 2rem;
    font-size: 3.5rem;
    text-align: center;
    @media screen and (max-width: $break-point--440) {
        font-size: 3rem;
    }
}

.happy-hour__description {
    font-style: italic;
    color: #9e3800;
    text-shadow: 0 0.5px 0 #f35600, 0 1px 0 #feab00;
    font-weight: $font-weight-extra-bold;
    width: 100%;
    font-size: 2rem;
    text-align: center;
    @extend %text;
    > span {
        color: #151617;
        text-shadow: 0 1px 0 #feab00, 0 2px 1px #00000047, 0 3px 0 #feab00;
    }
    @media screen and (max-width: $break-point--440) {
        font-size: 1.6rem;
    }
}