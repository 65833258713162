.ranked-stats-rating-text {
    color: #7ef51f;

    &.isNegative {
        color: #ff1414;
    }

    &.isSame {
        color: #c8c8c8;
    }
}