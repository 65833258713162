.tooltip_host {
    background: rgba(0, 0, 0, .9);
    border: .1rem solid transparentize( $accent--1, .5);
}
.tooltip__arrow {
    background: rgba(0, 0, 0, .9);
    border: .15rem solid transparentize( $accent--1, .5);
    border-bottom: none;
    border-right: none;
}

.tooltip__container--content {
    @include scrollbar();
}