.bottom-bar__button-icon {
    flex-flow: column nowrap;
    padding: 0 !important;
    min-width: 4rem !important;
    font-size: 3rem !important;
    white-space: nowrap;
    .bc__button--icon-cmp {
        font-size: $text__size--x-large !important;
        margin: 0 !important;
        @media only screen and (max-width: $break-point--768) {
            font-size: 2rem !important;
        }
    }

    > p {
        font-size: $text__size--normal !important;
    }

    @media only screen and (max-width: $break-point--768) {
        font-size: 2rem !important;

        > p {
            font-size: 1rem !important;
        }
    }

    @media only screen and (max-width: $break-point--480) {
        min-width: 3.5rem !important;
    }
}

.bottom-bar__wrapper {
    background-color: $color--bar;
}

.bottom-bar__link-overlay {
    background-color: $black__transparent--08;
}


.bottom-bar__container {
    max-width: $max-content-width;

    @media screen and (max-width: $break-point--1440) {
        max-width: $max-content-width-1440;
    }
}

.bottom-bar__light {
    display: none;
}