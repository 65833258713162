.skippy-wallet__wrapper {
    border-radius: $container-border--radius;
    background-image: linear-gradient(to bottom, #345896, #2d4e88, #26457b, #1f3c6e, #183361);
    box-shadow: inset 0px -2px 2px 0px #00002a, inset 0px 2px 1px 0px #64acff;
    font-size: 2rem;
    @extend %text;

    &:hover {
        cursor: pointer;
    }
}

.skippy-wallet__currency-item {
    font-size: inherit;
    direction: ltr;

    @media screen and (max-width: $break-point--480) {
        font-size: inherit;
    }
}