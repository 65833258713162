/********************************
*          Social skin          *
********************************/

.social__container {
  @include scrollbar();
}

.social-layout__container--layout__avatar {
  height: 100% !important;
  margin: 0 auto;
  display: flex !important;
  justify-content: center;
}

.social-layout__avatar {
  @media only screen and (min-height: $break-point--1024) {
    width: calc(11% - .4rem) !important;
  }
  @media only screen and (max-width: $break-point--1280) {
    width: calc(8.33% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--1024) {
    width: calc(10% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--800) {
    width: calc(11% - .4rem) !important;
  }
  @media only screen and (max-width: $break-point--680) {
    width: calc(14.26% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--440) {
      width: calc(16.666% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--360) {
      width: calc(20% - .4rem) !important;
  }
}