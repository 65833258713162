/************************************
*        Account merge skin         *
************************************/

.account-merge__container {
  display: block !important;
  height: auto !important;
  max-height: initial !important;
  max-width: 40rem !important;
  @media only screen and (max-width: $break-point--1920) {
    max-width: 45rem !important;
  }
  @media only screen and (max-width: $break-point--1366) {
    max-width: 36rem !important;
  }
  @media only screen and (max-width: $break-point--768) {
    max-width: 30rem !important;
  }

  &.popup-container__wrapper--content {
    @extend %form__container;
    min-height: initial;
  }
}

.account-merge__title-container {
  @media only screen and (max-width: $break-point--768) {
    height: 5rem !important;
  }
}

.account-merge__container--form {
  @include scrollbar();
}