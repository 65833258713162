.sic-host-class {
    @include container--colored($container--9);
}

.sic__icon {
    @include icon($rent-timer, 3rem, $accent--1);
}

.sic__description {
    @extend %text;
}

.sic__container--club-points {
    >number {
        font-size: $text__size--large;
        @include chip-sign($text__size--large);

        &:before {
            content: $club-xp !important;
        }
    }
}

.sic__container__flag {
    width: 80%;
    position: absolute;
    top: 5rem;
    transform: translateY(-50%);
    left: -0.2rem;
    max-width: 16rem;
}

.sic__giftable-item--count {
    background-color: #f3c042;
    border-radius: 1.2rem;
    padding: 1rem 1.8rem;
    position: absolute;
    right: -4px;
    top: -4px;
    font-weight: bold;
    font-size: 2.2rem !important;
    color: black;
    text-align: center;

    &.none {
        background-color: #a72925;
        color: white;
    }

    @media screen and (max-width:$break-point--568) {
        font-size: 1.8rem;
    }

    @media screen and (max-width:$break-point--380) {
        font-size: 1.5rem;
        padding: 0.8rem 1.8rem;
    }
}

.sic__item-component {
    font-size: 1.8rem;
    background-color: transparent !important;

    @include item-component((width: 80%));

    &.deck {
        @include item-component((width: 70%));
    }

    &.table {
        @include item-component((width: 55%));
    }

    @media screen and (max-width:$break-point--440) {
        @include item-component((width: 70%));

        &.deck {
            @include item-component((width: 60%));
        }

        &.table {
            @include item-component((width: 45%));
        }
    }

    @media screen and (max-width:$break-point--360) {
        font-size: 1.6rem;
    }
}

.sic__spinner--cmp {
    border-radius: $container-border--radius;
}