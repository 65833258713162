.personal-notes-host {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.personal-notes__wrapper {
    padding: 0 1rem 1rem 1rem;
    overflow: auto;
    overflow-x: hidden;
    @include scrollbar();
}

.personal-notes--comments {
    background-color: rgba(0, 0, 0, .3);
    color: white;
    margin: 0.8rem auto;
    padding: 0.5rem;
}

.personal-notes__simple-message-input {
    background-color: #131e35;
}

.personal-notes-action--button {
    color: #f3a522;
    font-size: 1.8rem;
    padding: 1rem 0;
}

.pesonal-notes__add-note-error {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding-right: 2rem;
}

.pesonal-notes__add-note-error-text {
    color: #8b0000;
    margin-right: 0.2rem;
    font-weight: $font-weight-bold;
    font-size: 1.3rem;
}

.personal-notes-input__button-send {
    margin: 0 !important;
    font-size: 1.4rem !important;
    min-height: auto !important;
    min-width: auto !important;
    height: auto !important;
}

.personal-notes__simple-message-input--wrapper {
    max-width: 568px;
    margin: 0 auto;
}

.personal-notes__no-notes-text {
    margin-top: 5rem;
    color: #f3a522;
    font-size: 2rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    @media screen and (max-height: $break-point--580) {
        margin-top: 2rem;
        font-size: 1.7rem;
    }

    @media screen and (max-height: $break-point--480) {
        margin-top: 0;
        font-size: 1.5rem;
    }
}

.personal-notes__no-notes {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.delete-note {
    animation: delete-note 300ms ease-in-out forwards;
}

.personal-notes--info-text {
    font-size: inherit;
    text-align: center;
    @extend %text;
}

@keyframes delete-note {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(200%);
    }
}