/*********************
*  Shop item value   *
*********************/

.shop-item-info__item-container {
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    > span {
        @include chip-sign(1em);
        &.gemsPack {
            @include icon($icon-gems, $icon__size--default, $color__gems, $before);
        }
    }
}
.shop-item-info__text--bonus-vip {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .chips-item__text--bonus-value-chips {
        color: $color--white;
    }
}

.shop-item-info__container--badge {
    .duration__container {
        @extend %text;
        color: $color__white;
        .duration__container__icon {
            color: $color--chips;
        }
    }
}

.shop-item-info__container--badge {
    .duration {
        @extend %text;
    }
}

.shop-item-info__value {
    &.discounted:after {
        content: "";
        border-top: .4rem solid $color--negative;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 48%;
        width: 100%;
        transform: rotateZ(-10deg);
    }
}

.shop-item-info__value--redesign,
.shop-item-info__container--bonus {
    display: none;
}

.shop-item-info__value--new-price {
    > .sii__chip-cmp {
        font-size: 3rem;
    }
}

.shop-item-info__promo {
    >.shop-item-info__chip-cmp {
        font-size: 1.6rem !important;
    }
}