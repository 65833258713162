@font-face {
  font-family: 'RobotoRegular';
  src: url(fontUrl('Roboto/Roboto-Regular.woff')) format('woff'),
  url(fontUrl('Roboto/Roboto-Regular.ttf')) format('truetype');
}

@font-face {
  font-family: "RobotoRegular";
  src: url(fontUrl('Roboto/Roboto-Bold.woff')) format('woff'),
  url(fontUrl('Roboto/Roboto-Bold.ttf')) format('truetype');
  font-style: normal;
  font-weight: $font-weight-bold;
}
