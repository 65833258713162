.progressive-ads__bar-wrapper {
    border: .3rem solid #131e35;
    margin-top: 2rem;
}

.progressive-ads__outer--baloon {
    border: .3rem solid #131e35;
    background-color: #131e35;
}

.progressive-ads__inner--baloon {
    background-color: $color--chips;
    display: flex;
    justify-content: center;
    align-items: center;

    &.passed {
        background-color: #131e35;
    }
}

.progressive-ads__bar-inner {
    background-color: #131e35;
    &.completed {
        .progressive-ads__inner--baloon {
            background-color: #131e35;
        }
    }
}


.progressive-ads__tick {
    font-size: 2em;
    color: $color--chips;
}

.progressive-ads__number {
    font-weight: $font-weight-bold;
    color: #131e35;
}