.skippies-text__currency-component {
    font-size: inherit !important;
    font-weight: inherit;
}

.skippies-text--text-with-placeholder-custom-class {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}