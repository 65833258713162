/*******************************
*     Buttons Decision skin    *
*******************************/

// Styles
.pbm__google {
    > p {
        color: #757777;
        text-shadow: none;
    }
}

.pbm__button {
    &.apple {
        .pbm__button__bclass {
            color: $color--bar !important; 
            > p {
                color: $color--bar !important; 
            }
        }
    }
    &.google {
        .pbm__button__bclass {
            > p {
            color: #757777;
            text-shadow: none;
            }
        }
    }
}