/*******************************
* PromoTournament rules skin   *
*******************************/

// Styles
.pr__wrapper {
    @include scrollbar();
}

.pr__text {
    @extend %text;
    @at-root .lang-ar &,
    .lang-fa & {
        text-align: right;
    }
    color: $color__white;
}
