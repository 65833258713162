/***********************************
*            Lobby Content skin    *
***********************************/


.lobby__grid {
    overflow: hidden;
    overflow-y: auto;
    @include scrollbar();
}

.avatar-layout__online-users-count__number {
    color: $color--selected;
    font-size: $text__size--large !important;
    font-weight: $font-weight-bold;
    margin-left: .1rem;
}

.avatar-layout__online-users-count__icon {
    color: $color--selected;
    font-size: $text__size--large !important;
    margin-right: .1rem;
    margin-left: .2rem
}

.avatar-layout__online-users-count__label {
    color: $color--selected;
    font-size: $text__size--large !important;
    font-weight: $font-weight-bold;
}

.avatar-layout__online-users-count__container {
    @extend %text;
}

.avatar-layout--container {
    height: 100%;
}