.ranked-games-leaderboard__title {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
}

.ranked-games-leaderboard__title-icon {
    font-size: 2.4rem;
}

.ranked-games-leaderboard-stats {
    background: #1b335b;
}

.ranked-games-leaderboard-stats__info-wrapper {
    background: #162b4c;
}
