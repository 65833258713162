/**********************************
*      Matching styles skin       *
**********************************/

// Styles
.matching__wrapper {
  background-color: $black__transparent--08;
  z-index: $layer--3;
}

.matching__container {
  @include scrollbar();

  @media screen and (max-height: $break-point--320){
    min-height: auto;
  }

  > p { @extend %text; }
}

.matching__container {
  @include touch-scroll();
  position: relative !important;
  max-width: 36rem !important;
  max-height: 98% !important;
  height: auto !important;
  overflow-y: auto;
  @media only screen and (max-width: $break-point--768) {
    max-width: 31rem !important;
  }
  @media only screen and (max-width: $break-point--480) {
    max-width: 28rem !important;
  }
  @media only screen and (max-height: $break-point--480) {
    max-width: 24rem !important;
  }
}


