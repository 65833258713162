.club-notification__container--title {
    color: $accent--1;
}

.club-notification__text--notification-info {
    @extend %text;

    > span {
        color: $color__chips;
    }
}

.club-notification__container--title-and-info {
    @extend %text;
    padding-right: 1rem;
}