.leaderboard-item__score {
    > number {
        @include chip-sign(1.4rem);
    }
    &.matchmaking {
        > number {
            @include icon($star, $text__size--normal, $accent--1, $before);
        }
    }
    &.best {
        > number {
            @include icon($star, $text__size--normal, $accent--1, $before);
        }
    }
    &.likes {
        > number {
            @include icon($heart, $text__size--normal, $accent--1, $before);
            // &:before {
            //     content: $heart !important;
            //     left: -1.8rem;
            // }
        }
    }
    &.richest,
    &.earnedchips {
        > number {
            @include icon($chip, $text__size--normal, $accent--1, $before);
        }
    }
    &.gems {
        > number {
            @include icon($icon-gems, $text__size--normal, $accent--1, $before);
        }
    }

    &.clubs {
        > number {
            @include icon($club-points, $text__size--normal, $accent--1, $before);
        }
    }

    &.clubmembers {
        > number {
            @include icon($club-xp, $text__size--normal, $accent--1, $before);
        }
    }

    &.rankedSeasonCurrentSeries,
    &.rankedSeasonHallOfFame {
        > number {
            @include icon($rank-elo, $text__size--normal, $accent--1, $before);
        }
    }

    &.clubmembers {
        margin-top: .5rem;
    }
}
