/******************************
*        Room skin            *
******************************/

.cgr__container {
    &.expanded {
        .cgr__container-outer {
            @include scrollbar();
        }
    }
}

.cgr__backdrop {
    background-color: transparentize($color__black, .5);
}

.cgr__wrapper {
    @include container--colored($container--4, 0.9);
    overflow: hidden;
    padding: 0.2rem 0;
    &.expanded {
        background-color: $main;
    }

    &.blur {
        border: none;
    }
}

.cgr__timer {
    .time {
        font-size: 1.4rem !important;

        @media screen and (max-width: $break-point--568) {
            font-size: 1.2rem !important;
        }
    }
}

.cgr__timer-info--icon {
    font-size: 1.4rem;
    color: $color--chips;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.2rem;
    }
}

.cgr__timer-expires {
    font-size: 1.4rem;
    @extend %text;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.2rem;
    }
}

.cgr__wrapper__allIn {
    background: radial-gradient($main,rgb(43,80,169));
    border: .3rem solid $accent--1;
    padding: 0;
    &.blur {
        background: none;
        padding: 0.2rem 0;
        border: .1rem solid transparent;
    }
}

.cgr__wrapper--header {
    background-color: transparentize($color__black, 0.74);
}

.cgr__all-in__label {
    width: 5rem;
    height: 1.1rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    background-image: url(imageUrl("icons/allIn.svg"));
    background-repeat: no-repeat;
}

// Timer
.cgr__container--countdown {
    color: $white__transparent--08;
    background-color: $black__transparent--05;
}

// Options
.cgr__container--options {
    margin-top: .2rem;
    height: 3rem;
}

.cgr__watch-info--icon {
    color: $color--chips;
}

.cgr__container--start-btn {
    &.waiting-for-host {
        background-color: $black__transparent--05;
        border-bottom-left-radius: $radius--default;
        border-bottom-right-radius: $radius--default;
    }
}

.blur {
    box-shadow: none;

    &:before {
        content: '';
        @extend %container--normal;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparentize($color__black, .3);
        border-bottom-left-radius: $radius--default;
        border-bottom-right-radius: $radius--default;
        z-index: 3;
        box-shadow: none;
    }
}

.cgr__container--timer {
    @extend %text;
}