.bank-pack-box__wrapper {
    @include container--colored($color__black);
}

.bank-pack-box__container--info {
    > p {
        margin: .2rem 0;
    }
    .chipPack {
        @include chip-sign($icon__size--default);
        color: $color__chips;
        > span {
            margin-left: .7rem;
        }
    }

    .gemsPack {
        @include chip-sign($icon__size--default);
        color: $color__white;

        @include icon($icon-gems, $icon__size--default, $color__gems, $before);
        > span {
            margin-left: .7rem;
        }
    }

    .vip {
        @include chip-sign($icon__size--default);
        @extend %text;
        color: $color__white;
        text-align: left;
        @include icon($crown, $icon__size--default, $crown, $before);
        &:before {
            content: "";
            color: $color__white;
        }
        > span {
            margin-left: .7rem;
        }
    }

    @media only screen and (max-width: $break-point--568) {
        >p {
            margin-left: 1.8rem !important;
            &:before {
                font-size: 1.4rem !important;
                left: -2rem !important;
            }
        }
    }
}

.bank-pack-box__container--price {
    > p {
        @include icon($price-tag, $icon__args...);

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 11rem;
            color: $accent--1;
            @media only screen and (max-width: $break-point--568) {
                font-size: 9rem;
            }
        }
    }
}

.bank-pack-box__container--vip__amount {
    font-weight: $font-weight-bold;
}