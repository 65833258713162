.progressive-ads-bonus__container {
    overflow-y: auto;
    height: calc(100% - 6rem);
    @include scrollbar();
}

.progressive-ads-bonus__reward-cmp {
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--smaller;
    }
}

.progressive-ads-bonus__next-bonus {
    @extend %text;
    font-size: $text__size--large;
    font-weight: $font-weight-bold;
    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--normal;
    }
}

.progressive-ads-bonus__mini-game {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation: mini-game-visible 0.3s cubic-bezier(0.69, 0, 0, 1) forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100002; // TODO: Remove after all popups are dynamically created

    &.mini-game-visible {
        transition: opacity .3s .3s;
        opacity: 1;
    }
}
