.club--join--button__label {
    width: 90%;
    border-radius: $border--radius $border--radius 0 0 !important;
}

.club--join--button__status--label {
    font-size: 1.3em;
    text-shadow: $text--shadow;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $break-point--768) {
        font-size: 1.2em;
    }

    &.rejected {
        color: $color--negative;
    }

    &.member {
        color: $active--color;
    }
}

.club--join--button__status--container {
    @include container--colored(transparent);
}
