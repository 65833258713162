/********************************
*    blitz lobby avatar skin    *
********************************/

// Style
.lost-cover {
  background-color: $black__transparent--05;
  border-radius: $border--radius;
}

.player-default-image {
  border-radius: $border--radius;
}

.team-player {
  @include container--colored($color__black);
}

.result-won {
  color: $color--success;
}

.result-lost {
  color: $color--negative;
}
