.ranked-games-progress-info__wrapper {
    padding: 2rem 3rem;

    @media screen and (max-width: $break-point--568) {
        padding: 1rem;
    }
}

.ranked-games-progress-info {
    border-radius: $border--radius;
}

.ranked-games-progress-info__game-progress--current {
    color: #eaab00;
}

.ranked-games-progress-info__game-progress--divider {
    margin: 0 0.5rem;
}

.ranked-games-progress-info--title {
    font-size: 2.4rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: #ffae00;
    text-shadow: 0 -1px 0 #ffff00, 0 2px 2px #000000;
    padding-bottom: 2rem;
    @extend %text;

    > span {
        color: #54ff00;
        text-shadow: 0 -1px 0 #a8ff00, 0 2px 2px #000000;
    }

    @media screen and (max-width: $break-point--568) {
        font-size: 1.6rem;
        padding-bottom: 1rem;
    }
}

.ranked-games-progress-avatar-wrapper {
    width: 20%;
    max-width: 11rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    &.isCompleted {
        .ranked-games-progress-info-games--current,
        .ranked-games-progress-info-games--divider,
        .ranked-games-progress-info-games--required {
            color: #b3ff00;
        }
    }

    &.isClaimed {
        .ranked-games-progress-info-games--current,
        .ranked-games-progress-info-games--divider,
        .ranked-games-progress-info-games--required {
            color: #ffab00;
            filter: brightness(0.3);
        }

        .ranked-games-progress-info--avatar {
            filter: brightness(0.3);
        }
    }
}

.ranked-games-progress-info-games {
    font-weight: $font-weight-bold;
    font-style: italic;
    padding-top: 1rem;

    @media screen and (max-width: $break-point--568) {
        padding-top: 0.25rem;
    }
}

.ranked-games-progress-info-games--current,
.ranked-games-progress-info-games--divider {
    color: #ffab00;
}

.ranked-games-progress-info-games--current,
.ranked-games-progress-info-games--divider,
.ranked-games-progress-info-games--required {
    font-size: 2rem !important;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.4rem !important;
    }
}

.ranked-games-progress-info--avatar-check,
.ranked-games-progress-info--bundle-check {
    font-size: 3rem;

    @media screen and (max-width: $break-point--568) {
        font-size: 2rem;
    }
}

.ranked-games-progress-info-games-progress {
    margin-top: 2rem;

    @media screen and (max-width: $break-point--568) {
        margin-top: 1rem;
    }
}

.ranked-games-progress-info--bundle-check__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);

    .ranked-games-progress-info--bundle-check {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #54ff00;
    }
}

.ranked-games-progress-bundle-wrapper {
    .ranked-games-progress-bundle-wrapper__info-icon {
        position: absolute;
        top: 1rem;
        left: 1rem;

        @media only screen and (max-width: $break-point--480) {
            top: 0.5rem;
            left: 0.5rem;
        }
    }

    .ranked-games-progress-bundle-wrapper__image-container {
        @media only screen and (max-width: $break-point--640) {
            max-width: 15vw !important;
            max-height: 15vw !important;
        }

        @media only screen and (max-width: $break-point--568) {
            max-width: 17vw !important;
            max-height: 17vw !important;
        }
    }
}

.ranked-games-progress-bundle-wrapper__item-container {
    text-align: center;
    margin: 0 0.5rem;
    width: 10rem;
    @media only screen and (max-width: $break-point--768) {
        width: 9rem;
    }

    chip-icon-component,
    gem-icon-component {
        font-size: 1.6rem;
    }

    &.vip-item {
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
            aspect-ratio: auto;
            margin: 0 auto;
            padding: 0 1rem;
        }

        .ranked-games-progress-bundle-wrapper__item-container__vip-label {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 0.3rem;
        }

        @media only screen and (max-width: $break-point--568) {
            padding-top: 1rem;

            img {
                padding: 0;
            }
        }
    }
}

.daily-reward-item-reward {
    width: 100%;

    .item-reward__reward--text {
        font-size: 1.4rem;
    }
}

.bundle-reward-item {
    height: 100%;

    .item-reward__reward {
        height: 100% !important;

        .item-reward--content {
            padding: 0;
            justify-content: center;

            .item-reward__reward--image--wrapper {
                position: relative;
                width: 100%;
                max-width: 8rem;
                padding-bottom: 70%;
                height: 0;
                overflow: hidden;

                .item-reward__reward--image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    max-width: 8rem;
                    max-height: 8rem;
                }
            }
        }
    }

    .item-reward__reward--text {
        font-size: 1.4rem !important;

        @media screen and (max-width: $break-point--640) {
            font-size: 1.2rem !important;
        }

        @media screen and (max-width: $break-point--480) {
            font-size: 1rem !important;
        }

        @media screen and (max-width: $break-point--380) {
            font-size: 0.8rem !important;
        }
    }
}

.ranked-games-progress-bundle-wrapper__items-wrapper {
    display: flex;
    width: 100%;
}

.tooltip-background {
    display: block;
    background: #fff;
    color: #000000;
    border-radius: 8px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);

    &.tall {
        min-height: 22rem!important;
    }

    @media only screen and (max-width: $break-point--568) {
        max-width: 100% !important;
    }
}

.tooltip-arrow-color {
    background: #fff !important;
}
