.cgr__wrapper--header-item {
    height: 100%;
}

.cgr__text--header-item {
    @extend %text;
    font-size: 1.4rem;

    @media screen and (max-width: $break-point--768) {
        font-size: 1.2rem;
    }
}

.cgr__text--header-item--value {
    text-indent: 0.25rem;
    @extend %text;
    color: inherit;
    font-size: 1.6rem;

    @media screen and (max-width: $break-point--768) {
        font-size: 1.4rem;
    }
}

.cgr__text--header--item--icon-chips {
    color: $color__chips;
}

.cgr__text--header--item--icon-gems {
    color: $color__gems;
}