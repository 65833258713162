.challenge-progress-info-box__challenge-title {
    font-weight: $font-weight-bold;
    font-size: 2rem;
    text-transform: uppercase;
}

.challenge-progress-info-box__collect-button {
    width: 100%;
    margin: 0.5rem 1rem;

    &.challenge-done {
        visibility: hidden;
    }
}

.challenge-progress-info-box__collect-button {
    p {
        font-size: $text__size--normal !important;
    }
}

.challenge-progress-info-box__collect-button-hidden {
    visibility: hidden;
}

.challenge-progress-info-box__challenge-icon {
    color: $accent--1;
    font-size: 8rem;
}

.challenge-progress-info-box--wrapper {
    padding: 1rem;
}

.challenge-progress-info-box--wrapper-weekly {
    background-image: linear-gradient(180deg, #0061d6, #0a4fb7, #0a3e99, #062d7c, #001e60);
    border-radius: 2rem;
}

.challenge-progress-info-box--wrapper-daily {
    background-color: $black__transparent--05;
    border-radius: 2rem;
}

.challenge-progres-info-box__claim-button-animation {
    visibility: visible;
    animation: claimButtonAnimation 0.5s linear forwards;
}

.challenge-progress-info-box__text--progress-bar {
    font-style: italic;
}

.challenge-progress-info-box__collect-button-reward {
    font-size: inherit;
    color: $color--chips;
}

.challenge-progress-info-box__challenge-info-container {
    padding-top: 1.5rem;
}

@keyframes claimButtonAnimation {
    0% {
        transform: scale(0);
    }

    33% {
        transform: scale(1.1);
    }

    66% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}