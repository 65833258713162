/*************************
*  Shared app variables  *
*************************/
// 200
$break-point--200: 200px;
$break-point--280: 280px;

// 300
$break-point--320: 320px; //320x568
$break-point--360: 360px; //360x640
$break-point--380: 380px;

// 400
$break-point--440: 440px;
$break-point--480: 480px; //480x800

// 500
$break-point--530: 530px;
$break-point--568: 568px; //320x568
$break-point--580: 580px;

// 600
$break-point--640: 640px;
$break-point--680: 680px;

// 700
$break-point--768: 768px; //768x1366

// 800
$break-point--800: 800px; //800x1280
$break-point--850: 850px;

// 900
$break-point--900: 900px; //900x1440
$break-point--960: 960px;
$break-point--980: 980px;

// 1000
$break-point--1024: 1024px; //1024x768
$break-point--1050: 1050px; //1050x1680
$break-point--1080: 1080px; //1080x1920
$break-point--1200: 1200px;
$break-point--1280: 1280px; //1280x800
$break-point--1366: 1366px; //1366x768
$break-point--1440: 1440px; //1440x900
$break-point--1500: 1500px;
$break-point--1600: 1600px; //game content breakpoint
$break-point--1680: 1680px; //1680x1050
$break-point--1920: 1920px; //1920x1080

// Fixed resolutions
$max-content-width: 1600px;
$max-content-width-1440: 1600px;


// App Layers
$layer--1: 999999; // toasters
$layer--2: 99999; // loaders
$layer--3: 10000; // popups,..etc.
$layer--4: 1000;
$layer--5: 100; // bottom/top bar,..etc.

$popup-outlet-max-width: 768px;
$popup-outlet-max-height: 1024px;

$radius--default: .8rem;
$container-border--radius: .8rem;
$border--radius: $radius--default;
$transition--default: .2s ease-in-out;

$bottom--rail__ad-banner__height: 100px;