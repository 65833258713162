.shop-utilities__wrapper {
    @extend %shop-wrapper;

    .shop-utility__box {
        @extend %shop-box;
    }
}

@media only screen and (max-width: $break-point--380) {
    .shop-utilities__wrapper {
        max-width: initial !important;
    }
}

@media only screen and (max-width: $break-point--568) {
    .shop-utility__box {
        width: 50% !important;
        padding: 2rem 1.5rem !important;
    }
}

@media only screen and (max-width: $break-point--768) {
    .shop-utility__box {
        padding: 2rem 0.5rem !important;
    }
}