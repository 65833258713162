/****************************
*      Forgotten pass skin  *
****************************/

// Styles
.cp__container {
  display: block !important;
  height: auto !important;
  max-height: initial !important;
  max-width: 40rem !important;
  @media only screen and (max-width: $break-point--1920) {
    max-width: 45rem !important;
  }
  @media only screen and (max-width: $break-point--1366) {
    max-width: 36rem !important;
  }
  @media only screen and (max-width: $break-point--768) {
    max-width: 30rem !important;
  }
  @media only screen and (max-height: $break-point--320 - 20) {
    height: 98% !important;
  }
}

.cp__title-container {
  @media only screen and (max-width: $break-point--768) {
    height: 5rem !important;
  }
}

.cp__container {
  &.popup-container__container {
    @extend %form__container;
    min-height: initial;
  }
}

.cp__container--form {
  @include scrollbar();
}

