.level-up-rewards--wrapper {
    position: relative;
    padding-top: 1rem;
}

.free-rewards__claim-all {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
}

.free-rewards__claim-all--bclass {
    padding: 1rem;
}

.free-rewards__claim-all-text {
    font-size: 2rem;
}

@media screen and (max-width:$break-point--320) {
    .level-up-rewards-progress-bar {
        
        height: calc(100% - 211px) !important;
    }
}