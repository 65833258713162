.user-filter-popup__container--options {
    @include scrollbar();
}

.user-filter--popup--cmp-class {
    max-height: 55rem !important;
    &.ios {
        @media screen and (max-width: $break-point--568) {
            max-height: calc(100% - 12rem) !important;
        }
    }
}
