.daily-reward--title {
    @extend %text;
    font-size: 1em;
}

$checked-sign-thickness: .5rem;

.daily-reward {
    border-radius: $border--radius;
    background-color: rgba(0, 0, 0, .2);
    justify-content: center;

    &.current {
        border: 0.2rem solid $color--selected;
    }

    &.checked {
        opacity: 0.4;
        background-color: $color__black;
    }

    @include item-reward((
        inner-content-padding: 0,
        img-wrapper-height: auto,
        aspect-ratio-width: 1,
        aspect-ratio-height: 1,
        item-img-width: 50%,
        item-table-img-width: 25%,
        item-reward-justify-content: space-evenly
    ));

    &.registerAdvent {
        justify-content: space-around;
        border: 0.1rem solid $color--selected;

        &.current {
            border: 0.3rem solid $color--selected;
            box-shadow: 0 0 10px 0px $color--selected, inset 0 0 8px 0px $color--selected;
            background-color: rgba(106, 13, 170, 0.45);
        }

        &.checked {
            opacity: 0.4;
            background-color: $color__black;
            filter: brightness(0.5);
        }

        &.highlighted {
            background-color: rgba(106, 13, 170, 0.45);
        }
    }
}

.checked-image {
    position: absolute;
    &.round {
        display: none;
    }
    width: 100%;
    height: 100%;
    &.not-round {
        transform: rotate(-40deg) !important;

        &:before, &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            background-color: $active--color;
            border-radius: $checked-sign-thickness;
        }

        &:before {
            height: 100%;
            width: $checked-sign-thickness;
        }

        &:after {
            width: 100%;
            height: $checked-sign-thickness;
        }
    }
}

.daily-reward--title {
    font-weight: $font-weight-bold;
}

.daily-reward-item-reward {
    font-size: 0.9em !important;
    width: 90%;
    max-width: 12rem;

    @media screen and (max-width: $break-point--568) {
        font-size: 0.8em !important;
    }
}