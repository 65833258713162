$chips__image: url(imageUrl('level-up/chipPack.png'));
$vip__image: url(imageUrl('level-up/vip.png'));
$gems__image: url(imageUrl('level-up/gemsPack.png'));

.profile-large-button__wrapper {
    @include container--colored($container--5);
}

.large-button__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.large-button__image--chips {
        background-image: $chips__image;
    }

    &.large-button__image--gems {
        background-image: $gems__image;
    }

    &.large-button__image--vip {
        background-image: $vip__image;
    }
}

.large-button__info-text {
    @extend %text;
    color: $color__white;
}