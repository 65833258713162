.rankings-scroll-tile__wrapper {
    border-radius: $container-border--radius;
    background-color: rgb(57 88 118 / 88%);
    box-shadow: 0 -3px 3px #5f89ba, inset 0 0 12px #5f89ba;

    &.belot {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-pattern.svg"));
    }

    &.tabla {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-dice-pattern.svg"));
    }

    &.santase {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-pattern.svg"));
    }

    &.bace {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-ludo-pattern.svg"));
    }

    &.blato {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-pattern.svg"));
    }
}

.rankings-scroll-tile-bclass {
    height: 12rem !important;
    width: 9rem !important;

    @media screen and (max-width: $break-point--480) {
        height: 8rem !important;
        width: 5rem !important;
    }
}

.rankings-scroll-tile-bclass {
    height: 12.5rem !important;
    width: 9.5rem !important;

    @media screen and (max-width: $break-point--1024) {
        height: 12.5rem !important;
        width: 9.5rem !important;
    }

    @media screen and (max-width: $break-point--480) {
        height: 11.5rem !important;
        width: 8.5rem !important;
    }
}