/****************************
*   Promo codes skin        *
****************************/

$promo-code-background: imageUrl('shop/promo_codes/main.svg');
$promo-code-coins: imageUrl('shop/promo_codes/promo-card-coins.png');

// Styles
.promo-code__wrapper {
  background: url($promo-code-background) no-repeat;
}

.promo-code__container--title {
  @extend %text;
}

.promo-code__container--input {
  .promo-code__input {
    @include input();
    padding: 0;
    text-align: center;
    @media only screen and ( max-width: $break-point--768) {
      padding: 0;
    }
  }
}

.promo_code__container--error {
  .promo_code__text--error {
    @extend %input-error;
  }
}

.promo-code__container--coins {
  &:before {
    background: url($promo-code-coins) no-repeat;
    background-size: cover;
  }
  &:after {
    background: url($promo-code-coins) no-repeat;
    background-size: cover;
  }
}
