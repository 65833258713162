// The font face definitions for OpenSans should stay as they are here and need to be the same as these in every game.
// This is necessary as the game resolves it's fonts based on these definitions and will fallback to the regular font if
// a concrete font (like bold) does not match any of these font faces. To load the fonts sooner a dummy elements are placed
// in the bottom of the body tag of the index.html page as anchor tags using these fonts with the needed font-weight.
// Example:
// <a style='font-family: OpenSans;'></a>
// <a style='font-family: OpenSans; font-weight: 300;'></a>
// <a style='font-family: OpenSans; font-weight: bold;'></a>

@font-face {
  font-family: 'OpenSans';
  src: url(fontUrl('OpenSans/OpenSans/OpenSans.woff2')) format('woff2'),
  url(fontUrl('OpenSans/OpenSans/OpenSans.ttf')) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  src: url(fontUrl('OpenSans/OpenSansLight/OpenSans-Light.woff2')) format('woff2'),
  url(fontUrl('OpenSans/OpenSansLight/OpenSans-Light.ttf')) format('truetype');
  font-weight: $font-weight-light;
}

@font-face {
  font-family: 'OpenSans';
  src: url(fontUrl('OpenSans/OpenSansBold/OpenSans-Bold.woff2')) format('woff2'),
  url(fontUrl('OpenSans/OpenSansBold/OpenSans-Bold.ttf')) format('truetype');
  font-weight: $font-weight-bold;
}
