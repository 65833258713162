@use "sass:math";

$width: 500;
$height: 500;
$box-shadow: ();

@mixin keyframes($animationName) {
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin particle-effect($particlesCount) {
    $particles: $particlesCount;
    $box-shadow: ();
    $box-shadow2: ();
    @for $i from 0 through $particles {
        $box-shadow: $box-shadow,
        random($width) - math.div($width, 2) + px random($height)- math.div($height, 1.2) + px $accent--1;
        $box-shadow2: $box-shadow2, 0 0 #fff;
    }
    box-shadow: $box-shadow2;

    @include keyframes(bang) {
        to {
            box-shadow: $box-shadow;
        }
    }

    @include keyframes(gravity) {
        to {
            transform: translateY(200px);
            opacity: 0;
        }
    }
}

.fireworks__container {
    position: relative;
    width: 100%;
    height: 100%;
}

.fireworks__before,
.fireworks__after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    will-change: box-shadow, transform, opacity, margin;
    animation: bang 1s ease-out infinite backwards,
        gravity 1s ease-in infinite backwards,
        position 5s linear infinite backwards;

        @media screen and (max-width: $break-point--568), screen and (max-height: $break-point--568) {
            border-radius: initial;
            width: 3px;
            height: 3px;
        }

        @media screen and (max-width: $break-point--568), screen and (max-height: $break-point--568) {
            @include particle-effect(25);
        }

        @media screen and (min-width: $break-point--568 + 1) and (min-height: $break-point--568 + 1) {
            @include particle-effect(50);
        }
}

.fireworks__after {
    animation-delay: 1.25s, 1.25s, 1.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
}

@include keyframes(position) {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}
