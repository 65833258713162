.friends-button__bclass {
    .bc__button--icon-cmp {
        margin-right: 0 !important;
        font-size: 2.4rem !important;
    }

    > p {
        font-size: 2.4rem !important;
        line-height: unset !important;
    }
}

.friends-button__cooldown-timer {
    color: inherit !important;
    font-size: inherit !important;
    margin: .2rem;
}