.level-up-progress-bar--initial-wrapper {
    background-color: #09111d;
    border-radius: 5rem;
    box-shadow: 0 0 1px 1px #1f2a47;
    padding: 4px 0;
}

.level-up-progress-bar--secondary-wrapper {
    background-color: #113279;
    border-radius: inherit;
    padding: 4px 0;
}

.level-up-progress-bar--container {
    background-color: #081223;
    border-radius: inherit;
    box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, .5), 0 0 1px 2px #72b4c1;
    overflow: hidden;
    padding: 4px 0;
    position: relative;
}

.level-up-progress-bar {
    background-color: #ffb400;
    border-radius: inherit;
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 100%);
    box-shadow: inset 0 1px 2px 0px #ffff00;
}

.level-up-progress-bar-transition {
    transition: transform 0.6s linear;
}