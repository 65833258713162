.wins-ratio-box__wrapper {
    @include container--colored(#365394, 0);
    border: none;
}

.wins-ratio-box__container--tournament-wins {
    &.wins-ratio-box__container--tournament-wins-title {
        background-color: #1c263d;
    }

    &.wins-ratio-box__container--tournament-wins-info-box {
        background-color: #273a69;
    }

    &.wins-ratio-box__container--tournament-wins-info-box-inner {
        background-color: #1c263d;
    }
}