.custom-blitz-winners-info__container {
    @include container--colored($container--4, 0.9);
}

.custom-blitz-winners-info--header {
    background-color: transparentize($color__black, 0.74);
}

.custom-blitz-winners-info__tournament-name {
    color: $color__chips;
    font-size: $text__size--large;
}
