.stamp-card__middle--border {
    border-top: 3px solid #b7b7b7;
    border-style: dashed;
}

.stamp-card__timer {
    font-size: 1.5rem !important;
    > span {
        color: #9ef000;
        font-weight: $font-weight-bold;
    }
}

.stamp-card__flag__container {
    background: linear-gradient(0deg, #070707 0%, #44484c 100%) !important;
    box-shadow: 0 2px 3px #7c6e12 !important;
}

.stamp-card__stamp {
    background-image: url(imageUrl('shop/stamp-card/Stamp_off.png'));
    background-size: contain;
    &.stamp-card__stamp__collected {
        background-image: url(imageUrl('shop/stamp-card/Stamp_on.png'));
    }
}

.stamp-card__stamp__outer {
    background-image: url(imageUrl('shop/stamp-card/Center.png'));
    background-size: contain;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    align-items: center;
    &.first {
        background-image: url(imageUrl('shop/stamp-card/Left.png'));
    }

    &.last {
        background-image: url(imageUrl('shop/stamp-card/Right.png'));
    }
}

.stamp-card__title {
    color: #3c3f42;
    font-size: $text__size--large;
    font-weight: $font-weight-black;
}

.stamp-card__progress {
    color: #3c3f42;
    font-size: $text__size--normal;
    font-weight: $font-weight-black;
}

.stamp-card__top-container {
    border-radius: 1.5rem;
    box-shadow: 6px 10px 10px #05061a78, -6px 10px 10px #05061a78;
}

.stamp-card__bottom-container {
    border-radius: 1.5rem 1.5rem 0 0;
    box-shadow: 6px 10px 10px #05061a78, -6px 10px 10px #05061a78;
}

.stamp-card__info {
    font-size: $text__size--large;
    color: #182600;
    @extend %text;

    > span {
        color: #69a400;
        font-weight: $font-weight-bold;
    }

    @media screen and (max-width: $break-point--360) {
        font-size: $text__size--normal;
        padding: 1rem 2rem 0 !important;
    }
    @media screen and (max-height: $break-point--680) {
        font-size: $text__size--normal;
        padding: 1rem 2rem 0 !important;
    }
}

.stamp-card__middle-container {
    background: url(imageUrl('shop/stamp-card/LINE.png')) #ffffff;
    background-size: contain;
    background-repeat: repeat;
    background-position: center;
}

.stamp-card__reward__item {
    color: #3c3f42;
    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--normal !important;
    }

    @media screen and (max-width: $break-point--320) {
        font-size: $text__size--small !important;
    }

    &.item {
        font-size: 2rem;
        flex: 0.4;
        @media screen and (max-width: $break-point--480) {
            font-size: $text__size--normal !important;
        }
        @media screen and (max-height: $break-point--680) {
            min-width: 7rem !important;
        }

        @media screen and (max-width: $break-point--360) {
            min-width: 6rem !important;
        }
        @media screen and (max-width: $break-point--320) {
            font-size: $text__size--small !important;
        }
    }
}

.stamp-card__container__wrapper {
    transform: translateY(calc(100% - 16rem));
    width: calc(100% - 24rem);
    position: absolute;
    bottom: 0;
    left: 12rem;
    z-index: 2;
    @media screen and (max-width: $break-point--680) {
        left: 5rem;
        width: calc(100% - 10rem);
    }
    @media screen and (max-width: $break-point--568) {
        left: 2rem;
        width: calc(100% - 4rem);
    }
    @media screen and (max-width: $break-point--440) {
        left: 1rem;
        width: calc(100% - 2rem);
    }
}

.stamp-card__top-container {
    background-color: $color--white;
}

.stamp-card__stamps__container {
    @include hidden-scrollbar();
}

.stamp-card__bottom-container {
    background-color: $color--white;
}

.stamp-card__claim--button__bclass {
    > p {
        font-size: 2rem !important;
    }
}

.stamp-card__tutorial__button__bclass {
    color: #355895 !important;
}

.stamp-card__stamp--completed {
    position: absolute;
    top: 5.5rem;
    left: 50%;
    transform: rotate(-8deg) translateX(-50%);
    font-size: 3rem !important;
    font-weight: $font-weight-bold !important;
    opacity: .7;
    z-index: 3;

    @media screen and (max-width: $break-point--568) {
        font-size: 2.25rem !important;
        white-space: nowrap;
    }
}

.stamp-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $black__transparent--05;
    z-index: 2;
}

.stamp-card__top-container {
    position: relative;
}