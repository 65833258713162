@keyframes rotateRays {
    from {
        transform: rotateZ(0deg)
    }
    to {
        transform: rotateZ(360deg)
    }
}

.game-summary__container--timer {
    @extend %text;

    .timer {
        direction: ltr !important;
    }
}

.game-summary__popup,
.game-summary__container--title {
    background-color: $main !important;
}

.game-summary__container--content {
    @include scrollbar();
}

.game-summary__container--team-won {
    @include container--colored($main);
}

.game-summary__container--rating {
    width: 100%;
    margin-bottom: 1rem;
}

.game-summary__timer {
    color: $accent--1;
}

.game-summary__text--won {
    @extend %text;
}

.game-summary__congrats {
    font-weight: $font-weight-bold;
}