voice-chat-drawer {
    @extend %container--bordered;
    border: none;
    border-radius: 0;
}


.voice-chat-drawer__container--top-bar-title {
    @media only screen and (max-width: $break-point--768) {
        height: 3rem !important;
        >p {
            font-size: $text__size--normal !important;
        }
    }
}

.voice-chat-drawer__container--items {
    @include scrollbar();
}