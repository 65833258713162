.cgr__seat--container {
    background-color: transparentize($color__black, .7);
    border-radius: $border--radius;
}

.cgr__seat--button {
    color: $accent--1;
    font-size: 5rem;

    @media screen and (max-width: $break-point--768) {
        font-size: 4rem;
    }
}
