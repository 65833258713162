.ranked-games-progress-bar {
    background-color: #05072a;
    height: 2rem;
    border-radius: $border--radius;
    box-shadow: inset 0 2px 2px 0px #000000, 0px 1px 2px rgb(255 255 255 / 35%);
}

.ranked-games-progress-bar-completed {
    width: 100%;
    height: 100%;
    background-color: #f5ce47;
    box-shadow: inset 0 2px 3px 1px #fdfba0, inset 0 -2px 3px 1px #fe6202;
}

.ranked-games-progress-bar-count-wrapper {
    background-color: #ffa23f;
    padding: 0.25rem 0.5rem;
    border-top: 2px solid #fff67e;
    border-radius: 1.5rem;
    box-shadow: 0 2px 2px 2px #000000;
}

.ranked-games-progress-bar-count {
    background: linear-gradient(#333a70, #0a0f51);
    padding: 0.3rem .75rem;
    min-width: 5rem;
    font-weight: $font-weight-bold;
    font-style: italic;
    font-size: 2rem;
    color: #ffab00;
    border-radius: inherit;
    border-bottom: 2px solid #fed907;
    box-shadow: inset 0 2px 2px 1px #14310e;

    @media screen and (max-width: $break-point--568) {
        padding: 0;
        font-size: 1.6rem;
        min-width: 4rem;
    }
}

.ranked-games-progress-bar-delimiter {
    position: absolute;
    height: 50%;
    background-color: #6371ff;
    width: 3px;
    top: 50%;
    transform: translate(-50%, -50%);

    &.isCompleted {
        background-color: #1d3800;
    }
}