/************************************
*  Container Leaderboard skin       *
************************************/

.container-leaderboard__container--my-place {
    >p {
        @extend %text;
    }
}

.container-leaderboard__container--leaders {
    &:after {
        @include separator(yellow);
    }
}

.container-leaderboard__container--tab {
    @include container--colored($color__black);

    >p {
        @extend %text;
        text-align: center;
    }

    &:not(.selected) {
        background-color: $black__transparent--05;
        border: .1rem solid transparentize($accent--1, 0.4);

        >p {
            color: transparentize($color__white, 0.4);
        }
    }
}

.container-leaderboard__container--leaderboard {
    @include container--colored($color__black);
}

.container-leaderboard__container--my-place {
    span {
        color: $accent--1 !important;
    }
}

//.container-leaderboard__container--user {
.user-score {

    >p,
    >span {
        @include chip-sign(1.4rem);
    }

    &.pro {

        >p,
        >span {
            @include icon($star, $text__size--normal, $accent--1, $before);
        }
    }

    &.best,
    &.matchmaking {

        >p,
        >span {
            @include icon($star, $text__size--normal, $accent--1, $before);
        }
    }

    &.gems {

        >p,
        >span {
            @include icon($icon-gems, $text__size--normal, $color--gems, $before);
        }
    }

    &.likes {

        >p,
        >span {
            @include icon($heart, $text__size--normal, $accent--1, $before);
        }
    }

    &.richest,
    &.earnedchips {

        >p,
        >span {
            @include icon($chip, $text__size--normal, $accent--1, $before);
        }
    }

    &.clubs,
    &.clubmembers {

        >p,
        >span {
            @include icon($club-xp, $text__size--normal, $accent--1, $before);
        }
    }
}

.container-leaderboard__custom-select__option {
    border-radius: $radius--default;

    >p {
        @extend %text;
        font-size: $text__size--large;
    }

    &:hover {
        cursor: pointer;
        background-color: lighten($main, 20%);
    }
}

.container-leaderboard__custom-select {
    height: 4rem;
}

.container-leaderboard--ranked-season-leaderboard-name {
    padding: 2rem 3rem;
    margin: 1rem 2rem;
    border-radius: 10px;
    display: inline-block;
    background-color: #203b64;
}