.image-message-wrapper__text--username {
    width: 100%;
    height: 15%;
    font-size: $text__size--x-large;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $break-point--568) {
        font-size: $text__size--large;
    }
}

.image-message__timer {
    font-size: inherit !important;
}

.image-message__timer--wrapper {
    @extend %text;
}

.image-message__timer--text {
    @extend %text;
    margin: 0 .5rem;
}