.completed-challenges-list-preview__container {
    background-color: $black__transparent--03;
    border-radius: $border--radius;
}

.completed-challenges-list-preview__container {
    display: flex;
    flex-direction: column;
}

.completed-challenges-list-preview__list--container {
    display: flex;
    justify-content: space-evenly;
}

.completed-challenges-list-preview_title {
    height: 5rem;
    font-size: 2.4rem;
    vertical-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}