/**************************
*    tournaments skin     *
**************************/

.tournaments__pages {
    @include scrollbar();
}

.tournaments__top-tab__button {
    width: auto;
    @media screen and (max-width: $break-point--768) {
      height: 4rem !important;
    }
  }

  .tournaments__top-tab__button-bclass {
    background-color: #151835 !important;
    height: 4rem;
    padding: 0 0.5rem;
    min-width: 11rem;

    > p {
        text-decoration: none;
    }

    &.tournaments__top-tabs__selected {
        background-color: $color--chips !important;
    }
  }

  