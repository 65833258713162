.clubs-list__create-button {
    margin: 0 2rem !important;
    width: 100%;
    min-width: unset !important;
    @media screen and (max-width: $break-point--440) {
        margin: 0 .5rem !important;
    }
    @media screen and (max-width: $break-point--380) {
        margin: 0 .3rem !important;
    }
}