.ranked-stats-rating-container {
    @include container--colored($main);
}

.ranked-stats-rating-container__rating {
    font-size: 2.4rem;
    font-weight: $font-weight-bold;
}

.ranked-stats-rating-container__rating--label-cclas {
    background: $main !important;
}

.ranked-stats-rating-container__rating--label-text {
    color: $color__chips;
}