.bdropdown--header {
    background-color: #192540;
    border: 0.1rem solid #f3a522;
    border-radius: 0.8rem;
    height: 4rem;

    @media only screen and (max-width: $break-point--768) {
        height: 3.5rem;
    }
}

.bdropdown--body {
    @include container--colored($main, 0);

    &.downward {
        border-radius: 0 0 $radius--default $radius--default;
        border-top: transparent;
    }

    &.upward {
        border-radius: $radius--default $radius--default 0 0;
        border-bottom: transparent;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%) !important;
    }

    > *:hover {
        background-color: lighten($main, 20%);
        border-radius: $radius--default;
    }
}