/**********************
* Leaderboards skin   *
***********************/


.leaderboards__container--main {
  @extend %container--aside;
  display: flex;
  flex-direction: column;
  height: 100%;
    &.hidden {
        height: 0;
    }
}

.leaderboard__top-tab__button {
  @media screen and (max-width: $break-point--768) {
    height: 4rem !important;
  }
}

.leaderboard__top-tab__button-bclass {
  background-color: #151835 !important;
  height: 4rem;
  margin: .5rem;
  width: 4rem;
  &.leaderboard__top-tabs__selected {
      background-color: $color--chips !important;
  }
}

.leaderboard__container {
  @include scrollbar();
  &.hidden {
    display: none;
  }
}