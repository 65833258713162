.friends-dropdown__overlay {
    background-color: $black__transparent--08;
}

.friends-dropdown__top-tabs__bclass {
    width: 11rem !important;
    color: $color__white;
    background-color: #151835;
    margin: 0 .2rem;
    > p {
        color: $color__white;
        font-size: $text__size--normal;
        text-decoration: none;
    }
    &.friends-dropdown__top-tabs__current {
        background-color: $color--chips;
    }
}