.clubs--list__separator {
    position: relative;

    &:before {
        @include separator(yellow);
    }
}

.clubs--list__wrapper {
    @include scrollbar();
}

.clubs--list__club-name {
    font-size: $text__size--x-large;
    font-weight: 600;
    color: $color__chips;
}

.clubs--list__level-requirement-text {
    > span {
        font-weight: 600;
    }
}