.notification-wrapper__container--main {

    &.gifts,
    &.invites {
        @include container--colored(#3a2146, 0);
        border: none;
    }

    &.likes,
    &.likesandratings,
    &.club {
        @include container--colored(#192246, 0);
        border: none;
    }

    &.system {
        @include container--colored(#04362E, 0);
        border: none;
    }
}