/*************************************
*     Navigation bar skin            *
*************************************/

// Styles
.nav-bar__container--arrow-back {
  .nav-bar__button--back {
    width: 4rem !important;
  }
}

.nav-bar__text--title {
  @extend %text;
  color: $accent--1;
}
