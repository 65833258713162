.cash-back__container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar();
    background-image: url(imageUrl("cash-back/piggyRoom_bg.png"));
    background-size: cover;
}

.cash-back-cmp {
    display: block;
    height: 100%;
}

.cash-back__tell-me-more {
    &:before {
        background-image: url(imageUrl("cash-back/ui_icon_info.png"));
    }
}

.cash-back__title {
    position: relative;
    text-align: center;
    font-size: 5vh;
    text-transform: uppercase;
    padding: 0 1rem;
    font-weight: $font-weight-extra-bold;
    text-shadow: 0 2px 0 #1fa8e2, 0 4px 0 #270000, 0 7px 13px #000;

    @media screen and (max-width: $break-point--380) {
        font-size: 2.5rem;
    }

    > span {
        width: 100%;
        color: #e8b82d;
        text-shadow: 0 1px 0 #bf7d10, 0 2px 0 #f3dd05, 0 3px 0 #00012f, 0 7px 13px #000;
    }
}

.cash-back__status-widget {
    font-size: $text__size--small;

    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--smaller;
    }
}

.cash-back__hint {
    @extend %text;
}

.cash-back__hint-button {
    height: 2em !important;

    > p {
        font-size: $text__size--large !important;
    }

    @media screen and (max-width: $break-point--768) {
        height: 2em !important;
    }
}

.cash-back__animation {
    &.isDecay {
        min-height: 40% !important;
        @media screen and (max-width: $break-point--440) {
            min-height: 30% !important;        
        }
    }
    &.completed, &.readyToCollect, &.collected {
        &:before {
            background-image: url(imageUrl("cash-back/finish.png"));
            content: "";
            display: block;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background-size: contain;
        }

        &.collected:before {
            background-image: url(imageUrl("cash-back/finish-golden.png"));
        }
    }
}

.cash-back__click-tutorial--image {
    width: 15vh;
}

.cash-back__click-tutorial--text {
    color: #5a919d;
    font-size: 2rem;
    font-weight: $font-weight-bold;
}

.cash-back__time-to-next-bonus {
    background-color: rgba(0, 0, 0, .5);
    border-radius: $container-border--radius;
    padding: 3em;
    text-align: center;
    font-size: $text__size--smaller;
    max-width: 80%;
    min-width: 40%;

    @media screen and (max-width: $break-point--360) {
        font-size: 0.6rem;
        padding: 2.5em;
    }
}

.cash-back__timer {
    font-size: 1.6em !important;
    margin-left: 0.5rem;

    .time {
        color: $color__white;
    }
}

.cash-back__timer--icon {
    font-size: 1.4em;
}

.cash-back__timer--wrapper {
    display: inline-flex;
    align-items: center;
    border-radius: 2rem;
    background-color: $black__transparent--05;
    padding: 0.75rem 1.25rem;
    margin-top: 0.5rem;
}

.cash-back__hint-decay--text {
    font-size: $text__size--large;
    margin-bottom: 0.5rem;

    > span {
        color: #ff1100;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }

    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--normal;
    }
}

.cash-back__hint-decay--expired {
    font-size: $text__size--x-large;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--normal;
    }
}

.cash-back__hint-decay--new-piggy {
    font-size: $text__size--normal;
    margin-bottom: 0.5rem;
    color: #63e1ff;

    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--normal;
    }
}

.cash-back__prices {
    font-weight: $font-weight-bold;
}

.cash-back__navigation--content {
    min-height: 40rem;
    &.isDecay {
        @media screen and (max-height: $break-point--768) and (orientation: landscape) {
            min-height: 60rem !important;
        }
    }   
}