$container--bg--color: transparentize($container--4, 0.75);

.club--notification--avatar__container {
    background-color: $container--bg--color;
}

.club--notification--avatar__title {
    .highlighted {
        color: $color--chips;
    }

    > span {
        @extend %text;
    }

    @if ($skin-font-family == "Cairo") {
        line-height: 1;
    }
}

.club--notification--avatar__value {
    @extend %text;
    > span {
        color: $color--chips !important;
    }

    > p {
        align-items: center;
    }

    @if ($skin-font-family == "Cairo") {
        line-height: 1;
    }
}

.club--notification--avatar__elapsed--time {
    color: $black--7;
}

.club--notification__currency-value {
    &.chips {
        color: $color--chips !important;
    }
}

.club--notification__username {
    color: $color--chips !important;
    direction: ltr !important;

    &.verified {
        position: relative;
        padding-right: 1.3em;

        &:after {
            content: "";
            position: absolute;
            margin: 0.1em;
            width: 1em;
            height: 1em;
        }
        &.blue {
            &:after {
                background-image: url(imageUrl("icons/LoyaltyBadge-Blue.svg"));
            }
        }
        &.bronze {
            &:after {
                background-image: url(imageUrl("icons/LoyaltyBadge-Bronze.svg"));
            }
        }
        &.silver {
            &:after {
                background-image: url(imageUrl("icons/LoyaltyBadge-Silver.svg"));
            }
        }
        &.gold {
            &:after {
                background-image: url(imageUrl("icons/LoyaltyBadge-Gold.svg"));
            }
        }
        &.platinum {
            &:after {
                background-image: url(imageUrl("icons/LoyaltyBadge-Platinum.svg"));
            }
        }
    }
}
