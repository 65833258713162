.club--avatar__image--placeholder {
    @include background-image(imageUrl("placeholders/placeholder.svg"));
}

.club--avatar__text--level {
    color: $color__white;
    text-shadow: -1px -1px 0 $black__transparent--05,
        1px -1px 0 $black__transparent--05, -1px 1px 0 $black__transparent--05,
        1px 1px 0 $black__transparent--05;
}

.club--avatar__level {
    font-size: 1em;
    font-weight: $font-weight-bold;
}