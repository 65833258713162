@import "../../../styles/global/animations/pulse.scss";

.tut__pulse__animation {
    animation: tut-pulse 1s linear infinite;
}

.tut__slow-pulse__animation {
    animation: tut-slow-pulse 1.75s linear infinite;
}

.tut__border__animation {
    border: $color--chips solid 3px;
    border-radius: 0.8rem;
    animation: tut-border 1.75s linear infinite;
}

.tut__outerElement {
    transform: translateZ(0);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: transparent;
    z-index: 100000;
}

.tut__innerElement {
    @extend .tut__border__animation;
    width: 100%;
    height: 100%;
}

@keyframes tut-pulse {
    0% {
        transform: scale3d(1, 1, 1)
    }

    25% {
        transform: scale3d(0.82, 0.82, 0.82)
    }

    50% {
        transform: scale3d(1, 1, 1)
    }

    75% {
        transform: scale3d(1.13, 1.13, 1.13)
    }

    100% {
        transform: scale3d(1, 1, 1)
    }
}

// @keyframes tut-pulse {
//     0% {
//         transform: scale3d(1, 1, 1)
//     }

//     10% {
//         transform: scale3d(0.8, 0.8, 0.8)
//     }

//     20% {
//         transform: scale3d(1.05, 1.05, 1.05)
//     }

//     30% {
//         transform: scale3d(0.9, 0.9, 0.9)
//     }

//     40% {
//         transform: scale3d(1, 1, 1)
//     }

//     100% {
//         transform: scale3d(1, 1, 1)
//     }
// }

@keyframes tut-slow-pulse {
    0% {
        transform: scale3d(1, 1, 1)
    }

    50% {
        transform: scale3d(0.9, 0.9, 0.9)
    }

    100% {
        transform: scale3d(1, 1, 1)
    }
}

@keyframes tut-border {
    from {
        border-color: transparent;
    }

    50% {
        border-color: $color--chips;
    }

    to {
        border-color: transparent;
    }
}