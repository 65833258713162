%platform-skinned-popup-title-bar {
  background-color: $color--bar !important;
}

.popup-title-bar--platform-skinned-popup-title-bar {
  @extend %platform-skinned-popup-title-bar;
}

.popup-title-bar__container--close,
.popup-title-bar__container--back-button {
  top: 50% !important;
  transform: translateY(-50%);
}

.popup-title-bar__container--close-redesign {
  display: none;
}

.popup-title-bar__container--back-button {
  right: auto;
  left: 1rem;
}

.popup-title-bar__container--game-title {
  &.blank-header {
    background-color: transparent !important;
    &:before {
      content: none !important;
    }
  }
  .popup-title-bar__image--game-title {
    @include background-image($game__logo, auto, 100%);
  }

  &.progressive-ads {
    background-color: #131e35;
  }
}

.popup-title-bar__text--static-title {
    font-size: 1.5em;
    text-transform: uppercase;
    @media only screen and (max-width: $break-point--480),
        screen and (max-width: $break-point--768) and (orientation: landscape) {
        font-size: 1.2em;
    }
}