.shop-skippies__container--wrapper {
    @include scrollbar(.5rem);
    padding-top: 1rem;
}

.shop-skippies--title-text {
    text-align: center;
    font-size: 2rem;
    color: #f5a724;
    @extend %text;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.8rem;
    }
}