.level-up-rewards--container {
    background-color: #151d30;
    border-bottom: 1px solid #46619c;
    border-radius: $radius--default;
    margin: 0 auto;
    box-shadow: inset 0px 0px 3px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: 0.3rem;

    &.fade-out-animation {
        animation: level-up-rewards-container-fade-out 0.3s linear forwards;
    }

    &.ready-for-claim {
        background-color: #38589ecf;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), inset 0 0 5px 4px #466da8;
        border-bottom: 1px solid #9be8f0;
    }
}

.level-up-rewards-container--wrapper {
    border-radius: $container-border--radius;
    background-color: #263555;
    padding: 4px 5px;
    border-bottom: 1px solid black;
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #709cd5;
    position: relative;

    &.fade-out-animation {
        animation: level-up-rewards-container-wrapper-fade-out 0.3s linear forwards;
    }

    &.ready-for-claim {
        background-color: #6aa1de;
        box-shadow: 0 2px 13px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 5px 4px #2db41b, inset 0 1px 1px 0 #f2fdff;
    }
}

.level-up-rewards-icon--wrapper {
    position: absolute;
    right: -7px;
    top: -12px;
    border-radius: 100%;
    padding: 0.6rem;
    background-color: #1c253e;
    transition: transform 0.2s linear;

    &.claimed-animation {
        animation: iconClaimed 0.5s linear forwards;
    }

    &.hidden {
        display: none;
    }
}

.level-up-rewards-icon--wrapper-secondary {
    margin: auto;
    border-radius: inherit;

    &.lock {
        color: #e8eae9;
        padding: 0.5rem;
        box-shadow: 0 -1px 0px 0 #5f92d5;
        background-image: linear-gradient(to bottom, #37569a, #304a83, #2a3e6c, #243257, #1d2742);
    }

    &.claimed {
        background-color: #bedd50;
        box-shadow: 0px -1px 0px 0px #f1f98d;
        color: #072f3d;
    }
}

.level-up-rewards-container--overlay {
    position: absolute;
    background-color: #1b233bd1;
    border-radius: inherit;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 1;
}

.level-up-rewards-container--transition {
    transition: opacity 0.2s linear;
    opacity: 0;
}

.level-up-rewards-container-wrapper__claim-animation {
    animation: 0.6s containerWrapperScale ease-in-out;
}

.level-up-rewards-container__icon-animation {
    transform: scale(0);
}

.level-up-rewards__item {
    @include item-component((width: 80%, height: 94px));
    background-color: transparent;
}

@media screen and (max-width: $break-point--440) {
    .level-up-rewards__item {
        @include item-component((width: 80%, height: 85px));
    }
}

@media screen and (max-width: $break-point--380) {
    .level-up-rewards__item {
        @include item-component((width: 80%, height: 70px));
    }
}

.level-up-rewards-container--wrapper-animation {
    animation: 0.5s level-up-rewards-container-wrapper-animation ease-in-out forwards;
}

.level-up-rewards-container--animation {
    animation: 0.5s level-up-rewards-container-animation ease-in-out forwards;
}

.level-up-rewards-container__shine {
    border-radius: inherit;
}

@keyframes iconClaimed {
    0% {
        display: block;
        transform: scale(0);
    }

    33% {
        transform: scale(1);
    }

    66% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes containerWrapperScale {
    0% {
        transform: scale(0.9);
    }

    33% {
        transform: scale(1);
    }

    66% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes level-up-rewards-container-wrapper-animation {
    0% {
        background-color: #29385c;
        transform: scale(1);
    }

    25% {
        background-color: #3a507b;
    }

    50% {
        background-color: #4a6a9b;
        transform: scale(1.03);
    }

    75% {
        background-color: #5a85bc;
    }

    100% {
        background-color: #6aa1de;
        transform: scale(1);
        box-shadow: 0 2px 9px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 5px 4px #2db41b, inset 0 1px 1px 0 #f2fdff;
    }
}

@keyframes level-up-rewards-container-animation {
    0% {
        background-color: #151d30;
    }

    25% {
        background-color: #1d2b49;
    }

    50% {
        background-color: #253964;
    }

    75% {
        background-color: #2e4981;
    }

    100% {
        background-color: #38589ecf;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), inset 0 0 5px 4px #466da8;
    }
}

@keyframes level-up-rewards-container-wrapper-fade-out {
    0% {
        background-color: #6aa1de;
        box-shadow: 0 2px 4px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 3px 2px #2db41b, inset 0 1px 1px 0 #f2fdff;
    }

    25% {
        background-color: #5a85bc;
        box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #709cd5;
    }

    50% {
        background-color: #4a6a9b;
    }

    75% {
        background-color: #3a507b;
    }

    100% {
        background-color: #29385c;
        box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #709cd5;
    }
}

@keyframes level-up-rewards-container-fade-out {
    0% {
        background-color: #38589ecf;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), inset 0 0 2px 1px #466da8;
    }

    25% {
        background-color: #2e4981;
        border-bottom: 1px solid #46619c;
        box-shadow: inset 0px 0px 3px 2px rgba(0, 0, 0, 0.5);
    }

    50% {
        background-color: #253964;
    }

    75% {
        background-color: #1d2b49;
    }

    100% {
        background-color: #151d30;
        border-bottom: 1px solid #46619c;
        box-shadow: inset 0px 0px 3px 2px rgba(0, 0, 0, 0.5);
    }
}