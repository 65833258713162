/******************************
*     Phrases list skin       *
******************************/

phrases-list {
  @extend %container--bordered;
  border: none;
  border-radius: 0;
}

.phrases-drawer__container--items {
  @include scrollbar();
}

.phrases-list {
  background-color: $black__transparent--02;
  > ul {
    > div.overlay {
      background-color: $black__transparent--08;
      .crown {
        @include icon($crown, $icon__args...);
        &:before {
          color: $accent--1;
        }
      }
      .message {
        > p {
          color: $color__chips;
        }
      }

    }
    > li {
      background-color: $secondary;
      color: $color__white !important;
      font-weight: 400;
      &:hover {
        background-color: light($secondary);
      }

      &.vip {
        position: relative;
        background-color: lighten( $secondary, 5% );
        border: .2rem solid $accent--1;
        color: $color__white !important;
        font-weight: 400;
        &.player-non-vip {
          &:before {
            @include background-image(imageUrl($locked-photo));
            background-position: 50% 50%;
            background-size: 6rem;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translateX(-50%) translateY(-50%);
            z-index: 2;
            @media only screen and (max-width: $break-point--480) {
              background-size: 3rem;
            }
          }
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black__transparent--08;
            z-index: 1;
          }
        }
        &:hover {
          background-color: lighten( $secondary, 10% );
        }
      }
    }
  }
}
