/***********************
* Free reward box skin *
***********************/

.free-reward-box__container--main {
    @extend %container--bordered;
    min-height: initial;
    border-radius: 2rem;
    background-color: transparent;
}

/* Used for the inner contet*/
.free-reward-box__container--button-action {
    .free-reward-box__button-text {
        min-width: 100%;

        p {
            font-size: $text__size--normal;

            >* {
                font-size: inherit !important;
                &.chips {
                    color: $color__white;
                    fill: $color__chips;
                }
                &.gems {
                    color: $color__white;
                    fill: $color__gems;
                }

                &.free-rewards__button--icon-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: inherit !important;
                }
            }
        }
    }
}

.free-reward-box__container--image {
    background-size: calc(100% - 2rem);
    background-position: center;
    background-repeat: no-repeat;

    &.email-verification-image {
        background-image: url(imageUrl('free-rewards/verifyAccount.png'));
    }

    &.account-merge-image {
        background-image: url(imageUrl('free-rewards/accountMerge.png'));
    }

    &.anti-churn-image{
        background-image: url(imageUrl('free-rewards/antiChurn.png'));
    }

    &.invite-friends-image {
        background-image: url(imageUrl('free-rewards/inviteFriendsLetter.png'));
    }

    &.rewarded-video-image {
        background-image: url(imageUrl('free-rewards/rewardedVideo.png'));
    }

    &.scratchCard-image {
        background-image: url(imageUrl('free-rewards/scratchCard.png'));
    }

    &.wheelOfFortune-image {
        background-image: url(imageUrl('free-rewards/wheelOfFortune.png'));
    }

    &.slot-image {
        background-image: url(imageUrl('free-rewards/slot.png'));
    }

    &.piniata-image {
        background-image: url(imageUrl('free-rewards/piniata.png'));
    }

    &.luckyBall-image {
        background-image: url(imageUrl('free-rewards/luckyBall.png'));
    }

    &.lottery-image {
        background-image: url(imageUrl('free-rewards/lottery.png'));
    }

    &.push-notification-image {
        background-image: url(imageUrl('free-rewards/pushNotification.png'));
    }

    &.first-lost-game-image {
        background-image: url(imageUrl('free-rewards/firstLostGame.png'));
    }
}

