/******************************
*     Phrases list belot     *
******************************/

.shop-vip-host-container {
  display: block;
  padding-bottom: 2rem;
}

.shop-vip__wrapper {
  @extend %shop-wrapper;
}

.shop-vip__box {
  @extend %shop-box;
}

.shop-vip__wrapper {
  @include scrollbar();
}

.shop-vip__container--vip-bottom-text {
  .benefits-label {
    background-color: transparentize($container--1, .8);
  }

  .benefits-list {
    background-color: transparentize($color__black, .8);
  }

  .benefits-list-item__wrapper {
    .benefits-list__item-text-bullet {
      background-color: $accent--1;
    }

    >p {
      >span {
        color: $accent--1;
      }
    }
  }
}

.shop-vip--list__hint {
  border: none;
  background-color: $color--chips;
  text-shadow: 0 0 0.5rem rgb(0 0 0 / 50%);
}

.shop-vip--list__hint--text {
  font-weight: $font-weight-bold;
  font-size: $text__size--small;
  text-align: center;
  padding: 1rem;
}

.shop-vip__container--vip-subscriptions--text-link {
  font-size: $text__size--normal;
  font-weight: $font-weight-bold;
  text-decoration: underline;
  cursor: pointer;
}

.shop-vip__container--vip-subscriptions-bottom-container {
  width: 100%;
  padding: 0 2rem;
  text-align: center;
}

.shop-vip__container--vip-subscriptions--text-bottom {
  font-size: $text__size--small;
}

.shop-vip__container--vip-bottom-text-redesign {
  display: none;
}

.shop-vip__container--vip-bottom-text-old-design {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  flex-flow: column;

  .shop-vip__button--benefits {
    padding: 1.5rem 1rem;
    border-radius: $border--radius;
    background-color: $black__transparent--03;
  }
}

.shop-vip__button--benefits-bclass {
  &.old-design {
    .shop-vip__text--benefits {
      text-decoration: underline;
    }
  }
}

.shop-vip--list__hint--text-link {
  font-weight: $font-weight-bold;
  text-decoration: underline;
  cursor: pointer;
}