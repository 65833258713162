@use "sass:math";

:root {
    --chip-icon: #{svg-load(imageUrl($chip), fill=#{$color__chips})};
}

@mixin chip-sign($size, $icon: $chip, $color: $color__chips) {
    @if ($icon != $chip or $color != $color__chips) {
        @include icon($icon, $size, $color, $before);
    }

    position: relative;
    color: $color__white;
    margin-left: $size + math.div($size, 6);
    &:before {
        @if ($icon == $chip and $color == $color__chips) {
            content: "";
            background-image: var(--chip-icon);
            background-repeat: no-repeat;
            display: block;
            width: $size;
            height: $size;
        }

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -($size + math.div($size, 4));
        color: $color;
        line-height: toPixels($size) + toPixels(0.6rem);
    }
}

@mixin hide-scrollbar() {
    /* WebKit */
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        height: 0;
        display: none;
    }
    /* Firefox */
    scrollbar-width: none;
}
