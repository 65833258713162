/***********************
* Popup message skin   *
***********************/

// Styles
.popup__wrapper {
  @extend %container--dimmer;
}

.popup__container {
  @extend %container--bordered;
}

.popup__container--main--content {
  @include scrollbar();
}

.popup__container--content {
  @include scrollbar();

  .popup__container--content-inner {
    .popup__container--content-title {
      >p {
        @extend %text;
      }
    }

    .popup__container--content-text {
      >p {
        @extend %text;
      }
    }
  }
}

.popup-container__wrapper--content {
  &.progressive-ads {
    background-color: #192540;
  }

  &.free-rewards-popup__popup-container {
    background: #192540 $background__pattern repeat center;
    background-size: 15rem; 
  }
}

.popup__title-bar {
  &.progressive-ads {
    background-color: #131e35;
  }
}