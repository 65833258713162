.search-user-popup__content-section {
    @include scrollbar();
}

.search-user-popup__top-tabs__bclass {
    font-size: $text__size--x-large !important;
}

.search-user-popup__top-tabs__bclass {
    background-color: rgba(0, 0, 0, .2);
    width: 5rem;
    margin: .5rem;

    &.top-tabs__selected {
        background-color: $color--chips;
    }
}

.search-user-popup__select-button-bclass {
    padding: 0 1rem;
}

.search-user-popup__users-list {
    padding: 0 2rem 2rem;
}