/***********************
*   User list skin     *
***********************/

$color--online: $active--color;
$color--offline: $color__disabled;

.list-users-box__container--list-title {
    >p {
        @extend %text;
    }

    &:before {
        @include separator(yellow);
    }
}

.list-users-box__text--list-title {
    @extend %text;

    &.online {
        &:before {
            @extend %background--image;
            background-color: $color--online;
            border-radius: 50%;
        }
    }

    &.offline {
        &:before {
            @extend %background--image;
            background-color: $color--offline;
            border-radius: 50%;
        }
    }

    &.blocked {
        @include icon($block, 2rem, $accent--1, $before);

        &:before {
            width: 2.4rem;
        }
    }

    &.muted {
        @include icon($muted, 2rem, $accent--1, $before);

        &:before {
            width: 2.4rem;
        }
    }
}


.list-users-box__container--info {
    .username {
        >p {
            @extend %text;
            direction: ltr !important;

            &.list-users-box-online-indicator {
                position: relative;
                padding-left: 2rem;

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1.4rem;
                    height: 1.4rem;
                    border-radius: 50%;
                    transform: translateY(-50%);
                    background-color: $color--offline;
                }

                &.online {
                    &:before {
                        background-color: $color--online;
                    }
                }
            }
        }
    }

    .user-label {
        @extend %text;
    }
}

.list-users-box__container--info {
    .user-label {
        color: $color__white;
    }
}

.list-users-box__container--delete-button {
    color: $accent--1;
}

.list-users-box__container--action-button {
    color: $accent--1;
}

.list-users-box__button-buy-block-skin {
    background-color: $black__transparent--03;
}

.list-users-box__text--list--title-numbers {
    direction: ltr !important;
}