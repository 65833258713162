.club--members--list__label {
    margin: 0.25rem 0;
    color: $color__chips;
    font-size: $text__size--normal;
}

.club--members--list__label-last-seen {
    margin: 0.25rem 0;
    color: $color--negative;
    font-size: $text__size--normal;

    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--small;
    }
}

.club--members--list__hint {
    font-size: $text__size--large;
}

.club--members--list__menu--btn-bclass {
    padding-right: 0;

    >p {
        text-align: left;
    }
}

.club--members--dropdown--btn {
    height: 4rem !important;
}