%input {
  @extend %text;
  position: relative;
  transition: border $transition--default,
  color $transition--default;
  user-select: auto !important;
  -webkit-user-select: auto !important;
  -moz-user-select: text !important;
  display: block;
  width: 100%;
  background: $black__transparent--03;
  color: $white__transparent--05;
  font-size: $text__size--large;
  text-align: left;
  height: 4rem;
  line-height: toPixels(3.8rem);
  &:focus {
    color: $color__white;
    outline: none;
  }
  &:active {
    color: $color__white;
    outline: none;
  }
  @media only screen and ( max-width: $break-point--768 ) {
    font-size: $text__size--normal;
    height: 3rem;
    line-height: toPixels(2.8rem);
  }
}

%input-error {
  @extend %text;
  position: relative;
  background: $color__negative;
  color: $color__white;
  font-size: $text__size--smaller;
  font-weight: 400;
  line-height: 1.4rem;
  margin-top: .1rem;
  padding: 0 .5rem;
  text-transform: uppercase;
  box-shadow: 0 .1rem .3rem $black__transparent--08;
  &:before {
    position: absolute;
    top: -.5rem;
    left: 1rem;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 .5rem .5rem .5rem;
    border-color: transparent transparent $color__negative transparent;
  }
}

%container--error {
  min-height: 2.7rem;//1.4rem;
}

%hint {
  @extend %text;
  position: relative;
  color: lighten($color__disabled--hover, 40%);
  font-size: $text__size--smaller;
  line-height: $text__size--smaller;
  line-height: toPixels( $text__size--smaller );
  padding: .2rem .5rem 0 .5rem;
  display: none;
}

// Mixins
@mixin input( $icon: 'no-icon' ) {
  @extend %input;
  padding: 0 .5rem 0 5.4rem;
  border: .1rem solid transparentize( $accent--1, .5 );
  border-radius: $radius--default;
  @if ( $icon != 'no-icon' ) {
    ~ .input__label {
      @include icon( $icon, $text__size--x-large, $white__transparent--05 );
      transition: color $transition--default;
      position: absolute;
      top: 0;
      left: 0;
      width: 5rem;
      text-align: center;
      line-height: 37px;
    }
  }
  &:focus {
    border: .1rem solid $accent--1;
    ~ .input__label {
      &:after {
        color: $color--selected;
      }
    }
  }
  ~ .input__container--error {
    position: relative;
    min-height: 2.7rem;
    .input--error {
      @extend %input-error;
    }
    .input--hint {
      @extend %hint;
    }
  }
  @media only screen and ( max-width: $break-point--768) {
    padding: 0 .5rem 0 3.5rem;
    @if ( $icon != 'no-icon' ) {
      ~ .input__label {
        @include icon( $icon, $text__size--large, $white__transparent--05 );
        position: absolute;
        top: 0;
        left: 0;
        width: 3.8rem;
        line-height: 27px;
      }
    }
  }
}
