.item-box__label-with-elevated-price {
    box-shadow: none;
}

@media only screen and (max-width: $break-point--480) {
    .item-box__value {
        &.title {
            font-size: 1.5rem !important;
        }
    }
}