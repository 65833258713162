.vip-item--save-from-offer {
    @extend %text;
    height: auto;
    color: $color--chips;
    font-weight: $font-weight-bold;
    font-size: $text__size--large;
    @media only screen and (max-width: $break-point--440) {
        font-weight: $font-weight-bold;
        font-size: $text__size--normal;
    }
}

.vip-days-amount-container {
    font-weight: $font-weight-bold;
}

.vip-days-amount {
    font-size: 3.6em;
    @media only screen and (max-width: $break-point--360) {
        font-size: 3em;
    }
}

.vip-days-label {
    font-size: 2.2em;
    @media only screen and (max-width: $break-point--360) {
        font-size: 1.8em;
    }
}
