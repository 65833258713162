/*****************************
*       User stats skin    *
*****************************/

// Styles
.user-stats__container--wins-label {
  @include container--colored($container-dark);
}

.user-stats__text--wins-label {
  color: $accent--1;
}

.user-stats__text--wins-label--icon {
    margin-right: .3rem;
    font-size: $text__size--x-large;
}