.shop-boosters__container--remaining-time-text {
    .booster_text--remaining-time {
        @extend %text;
        @include chip-sign(2.4rem, $rent-timer);
        color: $color__white;

        &:before {
            color: $color__white;
        }
    }
}

.shop-boosters__wrapper {
    @extend %shop-wrapper;
}

.shop-boosters__box {
    @extend %shop-box;
}
