.rankings-tile__wrapper {
    transition: transform 0.6s ease-in-out, border-bottom-color 0.6s !important;
    transform-style: preserve-3d;

    &.rankings--tile__medium-tile {
        .rankings-tile-bclass {
            height: 12rem !important;
            width: 9rem !important;

            @media screen and (max-width: $break-point--480) {
                height: 8rem !important;
                width: 5rem !important;
            }
        }
    }

    &.rankings--tile__extra-small-tile {
        .rankings-tile-bclass {
            height: 9rem !important;
            width: 6rem !important;

            @media screen and (max-width: $break-point--480) {
                height: 8rem !important;
                width: 5rem !important;
            }
        }
    }
}

.rankings-tile__initial,
.rankings-tile__leaders {
    background-image: url(imageUrl("lobby/leaderboardBackground.svg"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #163670;
    box-shadow: 0 -3px 3px #5475ac, inset 0 0 12px #5475ac;
}

.rankings-tile__image--icon {
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: $break-point--480) {
        height: 80% !important;
    }
}

.rankings-tile__wrapper--texts {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: $font-weight-extra-bold !important;
    font-size: 2.6rem;
    text-transform: uppercase;

    >span {
        text-transform: lowercase;
    }

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--large;
    }
}

.rankings-tile__leaders {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rankings-tile__leaders--top {
    padding: .25rem 1.5rem;
    position: absolute;
    box-sizing: content-box;
    top: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    max-height: 3rem;
    border-radius: $container-border--radius;
}

.rankings-tile__leaders--top-img {
    width: 100%;
    height: 100%;
}

.rankings-tile__leaderboards--name {
    font-size: $text__size--normal;
    text-align: center;
    color: $color__white;
    font-weight: $font-weight-bold;
    white-space: nowrap;

    @media screen and (max-width: $break-point--360) {
        font-size: $text__size--small;
    }
}

.rankings-tile__professionals {
    font-size: 0.8rem;
}