.club--info--xp--bar__level--icon,
.club--info--xp--bar__container--ranking>.colored {
    color: $color__chips;
}

.club--info--xp--bar__points,
.club--info--xp--bar__container--ranking {
    @extend %text;
}

.club--info--xp--bar__level--value {
    font-size: $text__size--smaller;
}

.club--info--xp--bar__points-value {
    font-size: $text__size--large - 0.2rem !important;
}

.club--info--xp--bar__level {
    width: $text__size--x-large;
}

.club--info--xp--bar__level--icon {
    font-size: $text__size--x-large;
}