$default-like-cmp-config: (
    font-size: $text__size--large,
    like-button-color: $color--negative,
    like-button-color-hover: $color--negative,
    like-button-wrapper-color-dark: $main,
    like-button-wrapper-color-light: $color__white
);

@mixin like-component($config: $default-like-cmp-config) {
    .like-component-host {
        font-size: map-get($config, font-size);
    }

    .like__button {
        font-size: 1em !important;
        color: map-get($config, like-button-color);

        &:hover {
            color: map-get($config, like-button-color-hover);
        }

        .bc__button--icon-cmp {
            font-size: 1em !important;
        }

        > p {
            font-size: 0.8em !important;
        }
    }

    .like__button--wrapper {
        &.dark {
            .like__button--counter {
                color: map-get($config, like-button-wrapper-color-dark);
            }
        }

        &.light {
            .like__button--counter {
                color: map-get($config, like-button-wrapper-color-light);
            }
        }
    }
}
