/**************************************************
*             Gallery preview skin                *
**************************************************/

.gallery-preview__container--title {
  @extend %text;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color--white;
  > p {
    height: initial !important;
  }
}

.gallery-preview__container--image {
  margin: 0.5rem;
  margin-bottom: 1rem;
    &.with-hover {
    border: .1rem solid transparent;
    &:hover {
      border: .1rem solid $accent--1;
    }
  }
  @media only screen and (max-width: $break-point--360) {
    margin: 0;
    margin-bottom: 1.5rem !important;
  }
}

.gallery-preview__wrapper {
  background-color: rgba(0, 0, 0, 0.312);
  border-radius: .8rem;
}

.gallery-preview__image--gallery-add-photo__icon {
  color: $color--white !important;
}

.gallery-photo__container--single-photo-wrapper {
  background-color: #1d3a63;
  &.placeholder {
    padding: 1rem;
  }
}

.gallery-preview__image--gallery-add-photo {
  background-color: #1d3a63 !important;
  cursor: pointer;
  font-size: 6rem !important;
  @media only screen and (max-width: $break-point--440) {
    font-size: 5rem !important;
  }
}