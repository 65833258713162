.progress__container,
.progress__partition--wrapper {
    border-radius: $border--radius;
}

.progress__container {
    background-size: cover;
    background-repeat: no-repeat;
}

.progress__partition--container {
    background-color: rgba(255, 255, 255, 0.6);
}

.progress__container {
    border-radius: 1.5rem;
    border: 3px solid #391c70;
}

.progress__meter {
    background: linear-gradient(#7f68ce, #684bc7);
    border-radius: 1.5rem;
    box-shadow: inset 0px 1px 0px 0px #e4bff3, 0 3px 4px 0px black;
    border-bottom: 1px solid #281b97;
}

.progress__meter--inner {
    border-radius: 1.5rem;
    background-color: #523bb7;
    width: 85%;
    height: 85%;
    font-weight: $font-weight-bold;
    font-size: 0.9em;
}

.progress__partition-img {
    background-image: url(imageUrl("cash-back/loader.png"));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 1.5rem;
}

.progress__partition--container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 1.5rem;
}