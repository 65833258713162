.buttons-list-game__container--action-button {
    &.spectators {
        $normal: get-color($button--action, normal);
        background-color: darken($normal, 5%);

        &.dark {
            $normal: get-color($button--dark, normal);
            background-color: darken($normal, 5%);
        }
    }
    &.voiceMessageSpeaking {
        border: $active--color solid .2rem;
    }
}

.menu-buttons-tooltip {
    @include container--colored($container-dark, 0);

    .menu-buttons-tooltip__arrow {
        background-color: transparentize( $container-dark, 0);
        border: .1rem solid $accent--1;
    }

    .menu-buttons-tooltip__icon {
        display: block;
        color: $color__white;
    }
}

.buttons-list-game-container__spectatorsButton {
    flex-flow: column-reverse;
    font-size: 3rem!important;
    min-width: 4rem!important;
    padding: 0!important;
    white-space: nowrap;
    .bc__button--icon-cmp {
        font-size: $text__size--large !important;
        margin: 0 !important;
    }
}

.buttons-list-game__container--action-button {
    flex-flow: column-reverse;
    font-size: 3rem!important;
    min-width: 4rem!important;
    padding: 0!important;
    margin: 0;
    white-space: nowrap;
}