/*********************
* Base layout mixins *
*********************/
@use "sass:math";

// Main flex setup
@mixin flex( $direction: row, $centering: '' ) {
  display: flex;

  @if $direction == "column" {
    flex-flow: column wrap;
  } @else {
    flex-flow: row wrap;
  }

  @if $centering == center {
    align-items: center;
    justify-content: center;
  }
}

// Scrollbar
@mixin scrollbar-default($width, $color: rgba(255, 255, 255, .2), $background: rgba(0, 0, 0, .2), $darken:10%, $ie: true) {
  position: absolute;
  top: 0;
  right: 0;
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-button {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-thumb {
    background: $color;
    border: none;
    border-radius: 0;
    width: $width;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken($color, $darken);
  }

  &::-webkit-scrollbar-thumb:active {
    background: darken($color, $darken);
  }

  &::-webkit-scrollbar-track {
    background: $background;
    border: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track:hover {
    background: darken($background, $darken);
  }
  &::-webkit-scrollbar-track:active {
    background: darken($background, $darken);
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
    @if ($ie) {
      scrollbar-highlight-color: $color;
      scrollbar-face-color: $color;
      scrollbar-3dlight-color: $color;
      scrollbar-shadow-color: $color;
      scrollbar-darkshadow-color: $background;
      scrollbar-track-color: $background;
      scrollbar-arrow-color: $background;
    }
  }
}

// Touch scroll
@mixin touch-scroll() {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

// Background image
@mixin background-image( $image, $size-x: cover, $size-y: cover, $position-x: center, $position-y: center, $repeat: no-repeat ) {
  background-image: url( $image );
  @if ( $size-x == 'cover') {
    background-size: $size-x;
  } @else {
    background-size: $size-x $size-y;
  }
  background-position: $position-x $position-y;
  background-repeat: $repeat;
}

// Scroll Container
@mixin scrol-container() {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

@mixin touch-hover() {
  body.no-touch &:hover:not([disabled]), &:active {
     @content;
  }
}

// Mixin that helps containers child keep desired aspect ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: var(--aspect-ratio, math.div($height, $width) * 100%);
  }
  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@mixin icon( $content: $corg, $size: 1rem, $color: #fff, $where: $after) {
  &:#{$where} {
    content: "";
    background-image: svg-load(imageUrl($content), fill=#{$color});
    background-repeat: no-repeat;
    display: block;
    width: $size;
    height: $size;
  }
}

@mixin hidden-scrollbar() {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  /* Firefox */
  scrollbar-width: none;
}