/********************************
*    Buy item-store box skin    *
********************************/

.buy-item-store-box__loader {
    border-radius: $border--radius;
}

.utility-item__container {
    background: transparent!important;
}