@import '../../../../../styles/global/animations/bar-color-animation';

.progress-bar__progress--container {
    //background-color: transparentize($color__black, .5);
    border: 0.05rem solid transparentize($color__black, .5);

    &.progress-classic {
        :not(.overrided) {
            &:nth-last-child(1) {
                background-color: $accent--1;
            }

            &:nth-last-child(2) {
                background-image: none !important;
                animation: none !important;
                background-color: transparentize($color__black, .5) !important;
            }
        }
    }

    &.progress-good-bad-empty {
        :not(.overrided) {
            &:nth-last-child(1) {
                background-color: $accent--1;
            }

            &:nth-last-child(2) {
                background-color: $color--negative;
            }

            &:nth-last-child(3) {
                background-color: transparentize($color__black, .5);
            }
        }
    }

    &.progress-multiple {
        :not(.overrided) {
            &:nth-last-child(1) {
                background-color: $active--color;
            }

            &:nth-last-child(2) {
                background-color: $color--negative;
            }

            &:nth-last-child(3) {
                background-color: blue;
            }

            &:nth-last-child(4) {
                background-color: yellow;
            }

            &:nth-last-child(5) {
                background-color: deeppink;
            }

            &:nth-last-child(6) {
                background-color: cyan;
            }
        }
    }
}

.progress-bar__progress--partition {
    border-width: 0 .05rem;
    border-style: solid;
    border-color: $color__black;

    &.progress-with-animation {
        transition: width 1s ease-in-out;
    }
}