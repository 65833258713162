.offer-buy-item-list--item {
    margin: 2rem 5%;
    width: 90%;
    @media only screen and (max-width: $break-point--360) {
        margin: 2rem 1%;
        width: 98%;
    }
}

.offer-buy-item-list--content {
    > div {
        margin: 2rem 5%;
        width: 90% !important;
        @media only screen and (max-width: $break-point--360) {
            margin: 2rem 1%;
            width: 98% !important;
        }
    }
}