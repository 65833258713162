:root {
    @each $optionKey, $optionValue in $options {
        --custom-games-option-#{$optionKey}: #{svg-load(imageUrl($optionValue), fill=#{$accent--1})};
    }
}

@mixin label-icon($font-size ) {
    @each $optionKey, $optionValue in $options {
        &.#{$optionKey} {
            position: relative;
            margin: 0 1rem;

            &:before {
                content: "";
                background-image: var(--custom-games-option-#{$optionKey});
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: -2.4rem;
                transform: translateY(-50%);
                line-height: 24px;
                width: $font-size;
                height: $font-size;
            }
        }
    }
}