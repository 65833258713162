.client-players-counter--host {
    background-color: $black__transparent--03;
    padding: 1rem;
    border-radius: 2rem;
    @media only screen and (max-width: $break-point--768) {
        padding: .5rem;
    }
}

.players__number--players--icon {
    font-size: 1.4em !important;
}