.pull__refresh--spinner {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 4.5rem !important;
    font-size: 2rem !important;
    opacity: 0;
    z-index: 4;
    background-color: $main;
    border-radius: 50%;
    border: 1px solid $color--chips;
}

@keyframes hide-loader {
    100% {
        transform: translate(-50%, 0);
        opacity: 0;
    }
}

@keyframes show-loader {
    100% {
        transform: translate(-50%, 150%);
        opacity: 1;
    }
}

.pull__refresh--show-spinner {
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
    transform: translate(-50%, 150%);
    opacity: 1;
}

.pull__refresh--hide-spinner {
    animation: hide-loader .3s ease-in-out forwards;
}