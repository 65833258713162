$bet__color: $color--chips;

.entry-fee__host {
    font-weight: 600;
    text-transform: uppercase;
}

// Styles
.entry-fee__wrapper {
    .entry-fee__container {
        @extend %direction-change;

        .entry-fee__text--label {
            @extend %text;
            color: inherit;
        }
    }
}

.entry-fee__text--amount {
    font-size: 1em !important;
}