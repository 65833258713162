.club--leader--leave__custom-select__option {
    display: flex;
    align-items: center;
    padding: .5rem;
    border-radius: $radius--default;

    > p {
      @extend %text;
      font-size: 1.8rem;
    }

    &:hover {
      cursor: pointer;
      background-color: lighten($main, 20%);
    }
}

.club-leader-leave__custom-select {
  width: 30rem !important;
  margin: auto;
  @media only screen and (max-width: $break-point--380) {
    width: 25rem !important;
  }
}

.club-leader-leave__custom-select__option {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: $radius--default;

  > p {
      @extend %text;
      font-size: $text__size--large;
  }

  &:hover {
      cursor: pointer;
      background-color: lighten($main, 20%);
  }
}