/******************************
*     Phrases list skin     *
******************************/

.shop-exchange__container--vip-bonus-text {
    @extend %text;
    color: $accent--1;
}

.shop-exchange__wrapper {
    @extend %shop-wrapper;
    @include scrollbar(.5rem);
}
  
.shop-exchange__box {
    @extend %shop-box;
}