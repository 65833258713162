/**********************************
*      Settings drawer skin       *
**********************************/

avatars-list-drawer {
    @extend %container--bordered;
    border: none;
    border-radius: 0;
}

.avatars-list-drawer__container--top-bar-title {
    @media only screen and (max-width: $break-point--768) {
        height: 3rem !important;
        > p {
            font-size: $text__size--normal !important;
        }
    }
}

.avatars-list-drawer__wrapper {
    @include scrollbar();
    background-color: $black__transparent--02;
}

.avatars-list-drawer__text {
    font-weight: $font-weight-bold;
    font-size: $text__size--x-large;
}

.avatars-list-drawer__container--top-bar-title__new--design {
    display: none;
}