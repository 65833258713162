/*******************************
*        Search bar skin       *
*******************************/

// Styles

.search-bar__container--button-search {
  .search-bar__button--search {
    @media only screen and (max-width: $break-point--768),
    only screen and (max-width: $break-point--480){
      padding: 0;
      min-width: 3.5rem !important;
    }
  }
}

.search-bar__container--history {
    @include container--colored($main, 0);
    @include scrollbar();
    >p {
        &:hover,
        &.focused {
            background-color: light($main);
        }
    }
}
