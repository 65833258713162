.mini-game-double-up__won--text {
    font-size: 3.5em;
    color: $color__chips;
    font-weight: $font-weight-bold;
}

.mini-game-double-up__reward {
    color: $color__chips;
}

.mini-game-double-up__image {
    background-image: url(imageUrl('shop/items/chipPack_double-up.png'));
    background-size: contain;
}

.mini-game-double-up__buttons--wrapper {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    text-align: center;
}

.mini-game-double-up__container {
    overflow: auto;
    @include scrollbar();
    border: none !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.double-up-popup__custom-class {
    @media only screen and (max-height: $break-point--440) {
        height: 100% !important;
    }
}

.mini-game-double-up__bclass>p {
    font-size: 1.5rem;
}