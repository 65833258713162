.item-price__text {
    @extend %text;
    direction: ltr !important;
    position: relative;
    .item-price__currency {
        font-size: inherit;
        font-weight: inherit;
    }
}

.item-price-component-host {
    font-weight: 700;
    font-size: 2.4rem;
}