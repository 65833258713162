/****************************************
*       Notifications Dropdown skin     *
****************************************/

.dropdown__wrapper {
    @include scrollbar();
}

.dropdown__button--action-button {
    min-width: 12rem;
}


.dropdown__container {
    background-color: $main;
    box-shadow: 0 .4rem .7rem $black__transparent--08;
    height: 100%;
    &:after {
        @include separator(yellow);
    }
}