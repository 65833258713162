// WIN / LOOSE animation
.currency-item__container--coins-count-change {
  border-radius: $radius--default;
  color: $color__white;
  text-shadow: 0.1rem 0.1rem 0.1rem $black__transparent--08;
  display: flex;
  align-items: center;

  &.increment {
    background-color: $active--color;
  }

  &.decrement {
    background-color: transparentize($color--negative, .2);
  }
}

.currency-item-host {
  font-size: 2rem;

  @media only screen and (max-width: $break-point--480) {
    font-size: 1.4rem;
  }
}

.currency-item__text-partition {
  font-size: inherit;
}