$container-ribbon-background-color: #a90329;
$container-ribbon-upper-border-color: #ff6f95;
$container-ribbon-background-color-second: #6d0019;

$ribbon-text-shadow-color: #3b0003;

.item-label-host {
    font-size: 1.5rem;
}

.product-flag__with--label {
    background: linear-gradient(0deg, $container-ribbon-background-color-second 0%, $container-ribbon-background-color 100%);
    border: none;
    text-shadow: (0 0 0 $ribbon-text-shadow-color, 0.5px 0 0 $ribbon-text-shadow-color, 0 1px 0 $ribbon-text-shadow-color, 0.5px 1px 0 $ribbon-text-shadow-color);
    position: relative;
    overflow: hidden;
    padding: 0.5rem 1rem;
    font-size: inherit;
    text-align: center;
}

.itemProduct_flag__with--flag {
    @extend %background--image;
    background: transparent url(imageUrl("shop/store-ribbon-red.svg"));
    background-position: center;
    height: 6rem;
    background-size: contain;
    border-radius: 0;
    background-repeat: no-repeat;
    white-space: nowrap;

    >p {
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: $text__size--normal;
        text-transform: uppercase;
        word-break: break-word;

        @media only screen and (max-width: $break-point--768) {
            font-size: $text__size--smaller;
            line-height: 16px;
        }

        @media only screen and (max-width: $break-point--480) {
            font-size: $text__size--smaller - .1rem;
        }
    }
}

.shop-item-product-flag_daily-deal {
    margin-right: 4.5rem;
    white-space: nowrap;

    >span {
        display: flex;
        align-items: center;
        height: 100%;
        width: 5rem;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 10rem 10rem 0;
        background-color: #B8022B;
        justify-content: center;
    }
}

@media only screen and (max-width: $break-point--440) {
    .product-flag__with--label {
        padding: 0.6rem 1rem;
    }
}