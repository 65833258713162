/**********************************
*      Global feed skin           *
**********************************/

$chat__new-message-arrow--color: $accent--1;

.gf__container--chat {
  background-color: $color__white;
}

.gf__container--conversations {
    background-color: #efefef;
}

.gf__container--messages {
  @include scrollbar();
  background-color: $color__white;
}

.gf__wrapper--convs {
  @include scrollbar();
}

.gf__maintenance-text {
    color: $color--chips;
}