.rankings-scroll-tile-bclass {
    height: 11rem !important;
    width: 10rem !important;

    @media screen and (max-width: $break-point--1024) {
        height: 9rem !important;
        width: 8rem !important;
    }
}

.rankings-scroll-tile__wrapper {
    background-image: url(imageUrl("lobby/leaderboardBackground.svg"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #163670;
}

.rankings__empty--content {
    @extend %text;
}

.rankings-scroll-tile__wrapper {
    &.rankings-scroll-tile__extra-small-tile {
        @media screen and (max-width: $break-point--1024) {
            .rankings-scroll-tile__professionals {
                pointer-events: none;
            }
            .rankings-scroll-tile__title-container {
                display: none !important;
            }
        }

        .rankings__empty--content {
            > p {
                font-size: 1.8rem !important;
                @media screen and (max-width: $break-point--1024) {
                    font-size: 1.8rem !important;
                }
                @media screen and (max-width: $break-point--800) {
                    font-size: 1.6rem !important;
                }
                @media screen and (max-width: $break-point--680) {
                    font-size: 1.4rem !important;
                    padding: 0 1rem;
                }
                @media screen and (max-width: $break-point--380) {
                    font-size: 1.2rem !important;
                    padding: 0 1rem;
                }
                @media screen and (max-width: $break-point--1024) and (orientation: landscape) {
                    padding: 0 1rem;
                    font-size: 1.6rem !important;
                }
            }
            &.matchmaking {
                > p {
                    font-size: 2.4rem !important;
                    @media screen and (max-width: $break-point--1024) {
                        font-size: 1.8rem !important;
                    }
                    @media screen and (max-width: $break-point--800) {
                        font-size: 1.6rem !important;
                    }
                    @media screen and (max-width: $break-point--680) {
                        font-size: 1.4rem !important;
                        padding: 0 !important;
                    }
                    @media screen and (max-width: $break-point--380) {
                        font-size: 1.4rem !important;
                        padding: 0 !important;
                    }
                    @media screen and (max-width: $break-point--1024) and (orientation: landscape) {
                        padding: 0 !important;
                    }
                }
            }
        }

        .rankings-scroll-tile__leaders {
            margin-top: 0rem;
        }
        .rankings-scroll-tile__leaders--top {
            > p {
                font-size: $text__size--normal !important;
            }
        }
        .rankings-scroll-tile-bclass {
            height: 11rem !important;
            width: 10rem !important;
            @media screen and (max-width: $break-point--1366) {
                height: 9rem !important;
                width: 8rem !important;
            }
            @media screen and (max-width: $break-point--1024) {
                height: 8rem !important;
                width: 7rem !important;
            }
            @media screen and (max-width:  $break-point--980) and (max-height: $break-point--1024) {
                height: 6rem !important;
                width: 5rem !important;
            }
            @media screen and (max-width: $break-point--680) and (orientation: landscape) {
                height: 5rem !important;
                width: 4rem !important;
            }
            @media screen and (max-width: $break-point--360) {
                height: 5rem !important;
                width: 4rem !important;
            }
        }

    }

    &.rankings-scroll-tile__small-tile {
        .rankings-scroll-tile-bclass {
            height: 11rem !important;
            width: 10rem !important;
            @media screen and (max-width: $break-point--1366) {
                height: 9rem !important;
                width: 8rem !important;
            }
            @media screen and (max-width: $break-point--1024) {
                height: 8rem !important;
                width: 7rem !important;
            }
            @media screen and (max-width:  $break-point--980) and (max-height: $break-point--1024) {
                height: 8rem !important;
                width: 7rem !important;
            }
            @media screen and (max-width: $break-point--680) and (orientation: landscape) {
                height: 6rem !important;
                width: 5rem !important;
            }
            @media screen and (max-width: $break-point--440) {
                height: 7rem !important;
                width: 6rem !important;
            }
            @media screen and (max-width: $break-point--360) {
                height: 6rem !important;
                width: 5rem !important;
            }
        }

        .rankings__empty--content {
            > p {
                @media screen and (max-width: $break-point--440) {
                    font-size: 2rem !important;
                }
                @media screen and (max-width: $break-point--380) {
                    font-size: 1.8rem !important;
                }
            }
        }
    }
}

.rankings-scroll-tile__loader {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}