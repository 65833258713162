.club--points__host {
    font-size: $text__size--large;
}

.club--points__wrapper {
    color: $color__chips;
    font-weight: 600;

    &.backdrop {
        border-radius: $border--radius;
        background-color: $black__transparent--03;
    }
}

.club--points__value {
    font-size: inherit;
}
