/****************************************
*       Notifications Dropdown skin     *
****************************************/

.notifications-dropdown__container--notification {
    transition: background-color ease 1s;

    &.highlighted {
        background-color: light($main);
    }
    &:not(.highlighted) {
        background-color: transparent;
    }
}

.notifications-dropdown__overlay {
    background-color: $black__transparent--08;
}
