/******************************
*     shop text list skin     *
******************************/

.shop-text-list-host-container {
    display: block;
    margin-bottom: 2rem;
}

.shop-text-list__container--benefits-label {
    @extend %text;

    &:not(.simple-styling) {
        background-color: transparentize($container--1, .8);
    }
}

.shop-text-list__container--benefits-list {
    &:not(.simple-styling) {
        background-color: transparentize($color__black, .8);
    }
}

.shop-text-list__container--item-text-bullet {
    background-color: $accent--1;

    @if ($skin-font-family =="Cairo") {
        margin-top: 1.5rem !important;

        @media only screen and (max-width: $break-point--480) {
            margin-top: 1.1rem !important;
        }

        @media only screen and (max-width: $break-point--320) {
            margin-top: 1rem !important;
        }
    }
}

.shop-text-list__text--benefits-list-item-text {
    @extend %text;

    >span {
        display: inline;
        color: $accent--1;
    }
}

.shop-text-list__container--benefits-list-item {

    @at-root .lang-ar &,
    .lang-fa & {
        justify-content: flex-end;
        padding-left: 0;
        padding-right: 10%;
    }
}

.shop-text-list__container--item-text-bullet {

    @at-root .lang-ar &,
    .lang-fa & {
        order: 1;
        margin-left: .3rem;
    }
}

.shop-text-list__text--benefits-list-item-text {

    @at-root .lang-ar &,
    .lang-fa & {
        order: 0;
    }
}

.shop-text-list__icon--item-text-bullet {
    display: none !important;
}

.shop-text-list__text--benefits {
    font-size: $text__size--large;

    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--normal;
    }

    @media only screen and (max-width: $break-point--320) {
        font-size: $text__size--small;
    }
}