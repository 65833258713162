.invite-notification__container--title {
    color: $accent--1;
}

.invite-notification__text--notification-info {
    @extend %text;
    padding-right: 1rem;
    @at-root .lang-ar &,
    .lang-fa & {
        padding-right: 2rem;
    }
}

.invite-notification__container--image-circle-inner {
    border: .2rem solid $accent--1;
    background-color: $black__transparent--03;
}