.item-purchase-notification__container--title {
    color: $accent--1;
}

.item-purchase-notification__text--notification-info {
    @extend %text;
    padding-right: 1rem;

    @at-root .lang-ar &,
    .lang-fa & {
        padding-right: 2rem;
    }
}

.item-purchase-notification__promo-bundle-item-custom-class {
    padding: 1rem;
}

.item-purchase-notification__promo-bundle-item {
    font-size: 0.9rem;

    @media screen and (max-width: $break-point--320) {
        font-size: 0.7rem;
        width: 62px !important;
    }
}

.item-purchase-notification__promo-bundle-items--wrapper {
    @media screen and (max-width: $break-point--380) {
        gap: 0.7rem !important;
    }
}