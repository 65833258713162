.notification-sign-host {
    height: 2rem;
    min-width: 2rem;
    font-size: 1.2rem;

    &.host-border {
        height: 2.5rem;
        min-width: 2.5rem;
    }
}

.notification-sign__wrapper {
    background: $color__negative;
    color: $color__white;
    font-weight: 900;
}

.notification-sign__wrapper--border {
    border: 4px solid $color__white;
}