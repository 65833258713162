/******************************
*     Phrases list skin     *
******************************/

.shop-more__wrapper {
  @include scrollbar();
}

.utility-item--blockSlotModifier1 {
  background-color: transparentize($container--6, .75) !important;
}

.utility-item--nameChanger {
  background-color: transparentize($container--1, .75) !important;
}

.utility-item--reset-stats {
  background-color: transparentize($container--2, .75) !important;
}