.club-activity-filter--member-role {
    @extend %text;

    font-size: 1.4rem;
    padding-left: 1rem;
}

.club-activity-filter--member-username {
    padding-left: 1rem;
    font-size: 1.8rem;
    font-weight: $font-weight-semibold;
    color: #f5a724;
    margin-bottom: 0.5rem;

    @media screen and (max-width: $break-point--440) {
        max-width: 18rem;
    }

    @media screen and (max-width: $break-point--380) {
        max-width: 16rem;
    }

    @media screen and (max-width: $break-point--360) {
        max-width: 14rem;
    }

    @media screen and (max-width: $break-point--320) {
        max-width: 11rem;
    }
}
