.simple-message-input-host {
    background-color: #131e35;
}

.simple-message-input__wrapper {
    background-color: inherit;
}

.simple-message__input-field {
    @include scrollbar();

    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    padding: 1rem;
    color: black;
    resize: none;
}

.simple-message-input__button-send {
    margin: 0 !important;
}