/**************************
*     user info skin      *
**************************/

// Styles
// Username
.user-info__text--username {
    color: $color__white;

    &:not(.online) {
        &:before {
            background-color: $color__disabled;

        }
    }

    &.online {
        &:before {
            background-color: $active--color;
        }
    }
}

// Status
.user-info__text--status {
    @extend %text;
    color: $color--white;
}

// Likes
.user-info__text--likes__icon {
    color: $color--negative;
    font-size: $text__size--x-large  !important;
    margin-right: .5rem;
}

.user-info__text--likes {
    font-size: $text__size--large  !important;

    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--normal  !important;
    }
}

// Ratings
.user-info__container--rating__icon {
    color: $color__chips;
    font-size: $text__size--x-large  !important;
    margin-right: .5rem;
}

.user-info__text--rating {
    font-size: $text__size--large  !important;

    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--normal  !important;
    }
}

.user-info__container--remaining-booster {
    background-color: $accent--1;
    >p {
        @extend %text;
    }
}

// xpBoosters
.user-info__container--remaining-booster__icon {
    font-size: $text__size--normal  !important;
    margin-right: 0.5rem;
}

.user-info__cover--avatar--subscribed--wrapper {
    width: 100%;
}

.user-info__cover--avatar--subscribed {
    margin-top: 0.5rem;
    color: $color__chips;
    text-align: center;
    font-size: $text__size--normal;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.user-info__cover--avatar--subscribed--icon {
    font-size: $text__size--large;
    margin: 0 0.25rem;
}

.user-info__container--other-info {
    background-color: #07101c87 !important;
}

.user-info__rating {
    font-weight: $font-weight-bold;
    font-size: 1.8rem;
    color: $color--chips;
}

.user-info__rating--number {
    font-size: 1.8rem !important;
}

.user-info__rating-tooltip {
    font-size: 1.8rem;
}

.user-info__button--extra-action {
  &.without__icon {
    display: none;
  }
}