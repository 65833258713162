.notification-bottom-info__button--delete-button-color {
    color: $accent--1;

    &:disabled {
        opacity: .5;
    }
}

.notification-bottom-info__text--notification-date {
    @extend %text;
}