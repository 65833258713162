$color-last-seen: #FE2D1F;

.clubs--list--item__container {
    @include container--colored($container--3);

    &.highlighted {
        transition: background-color .3s ease;
        background-color: rgba(40, 188, 232, .25);
    }
}

.clubs--list--item__container--club-name-and-status {
    >p {
        &.club-name,
        &.club-points {
            color: $color--chips;
        }
    }
}

.clubs--list--item__label-last-seen {
    @extend %text;
    color: $color-last-seen;
}

.clubs--list--item__icon-last-seen {
    @extend %text;
    color: $color-last-seen;
}