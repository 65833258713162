.shop__container--text {
    @extend %text;
}

.shop__text--colored {
    color: $accent--1;
}

.shop__title--value {
    .amount {
        @extend %text;
    }
}

.shop__container--text__label {
    margin: 0 .4rem;
}