.create--club__form--wrapper {
    @include scrollbar();
}

.create--club__footer {
    position: relative;

    &:before {
        @include separator(yellow);
        top: 0;
    }
}