@use "sass:math";

.club--notification--coin-shard-reward {
    > number {
        font-size: $text__size--large;
        @include icon($club-xp, $text__size--large, $color__chips, $before);
        position: relative;
        color: $color__white;
        margin-left: $text__size--large + math.div($text__size--large, 6);
        &:before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -($text__size--large + math.div($text__size--large, 4));
            line-height: toPixels($text__size--large) + toPixels(0.6rem);
        }
    }

    @media only screen and (max-width: $break-point--768) {
        > number {
            font-size: $text__size--normal;
            @include icon($club-xp, $text__size--normal, $color__chips, $before);
            position: relative;
            color: $color__white;
            margin-left: $text__size--normal + math.div($text__size--normal, 6);
            &:before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -($text__size--normal + math.div($text__size--normal, 4));
                line-height: toPixels($text__size--normal) + toPixels(0.6rem);
            }
        }
    }
}

.club--notification--button--bclass {
    height: auto !important;
    min-height: 4rem !important;
    padding: 0.5rem !important;
}

.club--notification--coin-shard-reward {
    width: 15rem;
    display: flex;
    justify-content: center;
    > p {
        display: flex;
    }
}