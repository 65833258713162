/****************************
*   Forgotten pass skin     *
****************************/

// Styles
.fp__container {
  display: block !important;
  height: auto !important;
  max-height: initial !important;
  max-width: 40rem !important;
  @media only screen and (max-width: $break-point--1920) {
    max-width: 45rem !important;
  }
  @media only screen and (max-width: $break-point--1366) {
    max-width: 36rem !important;
  }
  @media only screen and (max-width: $break-point--768) {
    max-width: 30rem !important;
  }
  @media only screen and (max-height: $break-point--320 - 20) {
    height: 98% !important;
  }

  &.popup-container__wrapper--content {
    @extend %form__container;
    min-height: initial;
  }
}

.fp__title-container {
  @media only screen and (max-width: $break-point--768) {
    height: 5rem !important;
  }
}

.fp__container--form {
  @include scrollbar();
}

// Input email


.fp__container--error {
  @extend %container--error;
  .fp__text--hint {
    @extend %hint;
  }
  .fp__text--error {
    @extend %input-error;
  }
}
