.promo-tile__wrapper {
    @include hide-scrollbar();
}

.promo-tile__wrapper {
    background-image: url(imageUrl("promo-tile/promo-default.png"));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.promo-tile__coins, .promo-tile__gift, .promo-tile__text, .promo-tile__gems {
    display: none;
}