.image__slider-container {
    background-color: $black__transparent--09;
}

.image__slider--arrow-left-bclass {
    transform-origin: center left;

    i {
        width: 3rem;
        margin: auto auto auto 2rem;
    }
}

.image__slider--arrow-right-bclass {
    transform-origin: center right;

    i {
        width: 3rem;
        margin: auto 2rem auto auto;
    }
}