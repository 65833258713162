/*******************************************
*          Professionals avatars  skin     *
*******************************************/

// Variables
$large: 'large';
$medium: 'medium';
$small: 'small';
$extra--small: 'extra--small';

// Mixins
@mixin frame-image($source) {
    background-image: url($source);
    background-position: 0;
    background-size: 102% 100%;
    background-repeat: no-repeat;
}

@mixin glory($type) {
    .leaderboard-professional--avatar-container {
        position: relative;
        margin: 0;

        &:nth-of-type(1) {
            margin-top: 0;
            order: 2;
            @if $type == $large {
                width: 16rem;
                height: 16rem;
            } @else if $type == $medium {
                width: 13.5rem;
                height: 13.5rem;
            } @else if $type == $small {
                width: 11rem;
                height: 11rem;
            } @else if $type == $extra--small {
                width: 10rem;
                height: 10rem;
            }
        }

        &:nth-of-type(2) {
            order: 1;
            margin-top: .5rem;
            @if $type == $large {
                width: 14rem;
                height: 14rem;
            } @else if $type == $medium {
                width: 11.5rem;
                height: 11.5rem;
            } @else if $type == $small {
                margin-top: .3rem;
                width: 9rem;
                height: 9rem;
            } @else if $type == $extra--small {
                width: 8rem;
                height: 8rem;
            }
        }

        &:nth-of-type(3) {
            order: 3;
            margin-top: .5rem;
            @if $type == $large {
                width: 14rem;
                height: 14rem;
            } @else if $type == $medium {
                width: 11.5rem;
                height: 11.5rem;
            } @else if $type == $small {
                margin-top: .3rem;
                width: 9rem;
                height: 9rem;
            } @else if $type == $extra--small {
                width: 8rem;
                height: 8rem;
            }
        }

        .leaderboard-professionals--avatar-frame {
            position: absolute;
            top: 0;
            left: 5%;
            width: 90%;
            height: 90%;
        }
    }
}

@mixin glory__container($type) {
    @include glory($type);
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0 auto;
    @if $type == $large {
        width: 50rem;
        height: 18rem;
        @media only screen and (max-width: $break-point--900) {
            width: 45rem;
        }
    }
    @if $type == $medium {
        width: 40rem;
        height: 13rem;
    }
    @if $type == $small {
        width: 29rem;
        height: 14rem;
    }
    @if $type == $extra--small {
        width: 100%;
        height: 14rem;
    }
}

// Styles
.leaderboard-professional--wrapper,
.leaderboard-professional__container--ribbons {
    &.default {
        @media only screen and (min-height: $break-point--900) {
            @include glory__container($large);
        }
        @media only screen and (min-width: $break-point--480) {
            @include glory__container($large);
        }
        @media only screen and (max-height: $break-point--900) and (orientation: landscape) {
            @include glory__container($medium);
        }
        @media only screen and (max-height: $break-point--768) and (orientation: landscape) {
            @include glory__container($small);
        }
        @media only screen and (max-width: $break-point--1024) and (orientation: portrait) {
            @include glory__container($medium);
        }
        @media only screen and (max-width: $break-point--480) and (orientation: portrait) {
            @include glory__container($medium);
        }
        @media only screen and (max-width: $break-point--320) and (orientation: portrait) {
            @include glory__container($extra--small);
        }
        @media only screen and (max-height: $break-point--768) and (orientation: portrait) {
            @include glory__container($medium);
        }
        @media only screen and (max-height: $break-point--640) and (orientation: portrait) {
            @include glory__container($small);
            margin: .3rem auto 2rem;
        }
    }

    &.large {
        @include glory__container($large);
    }

    &.medium {
        @include glory__container($medium);

        &:not(.leaderboards) {
            @media only screen and (max-width: $break-point--440) {
                @include glory__container($small);
                margin: .3rem auto 2rem;
            }
        }
    }

    &.small {
        @include glory__container($small);
        margin: .3rem auto 2rem;
    }

    &.leaderboards {
        @media only screen and (min-width: $break-point--1280 + 1) {
            @include glory__container($large);
        }
        @media only screen and (max-width: $break-point--480) {
            @include glory__container($small);
            height: 15rem;
        }
        @media only screen and (max-width: $break-point--320) {
            @include glory__container($extra--small);
            height: 15rem;
        }
    }
}
