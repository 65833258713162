.knockout__wrapper {
    height: 70%;
    z-index: 2;
}

.knockout__tournament--tile-info--icon {
    color: $color__chips;

    font-size: 1.4rem;

    @media screen and (max-width: $break-point--768), screen and (max-height: $break-point--568) {
        font-size: 1.2rem;
    }
}

.knockout__tile--wrapper {
    border-radius: $border--radius;
    background-color: #192540;
    background-image:  url(imageUrl('tournaments_lobby/knockout_tile_bg.svg'));
    background-repeat: repeat;
    background-size: 25%;
    overflow: hidden;
}

.knockout__tournament--tile-info--text {
    font-size: 2.25rem;

    @media screen and (max-width: $break-point--768), screen and (max-height: $break-point--568) {
        font-size: 1.6rem;
    }
    >span {
        color: $color__chips;
    }
}

.knockout__tournament-tile__footer-text {
    position: absolute;
    bottom: 5px;
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
}

.knockout--item-container-config-bclass {
    margin: 0 !important;
    justify-content: center !important;
}

.knockout__completed--text {
    color: $color__chips;
}

.knockout__completed--prize {
    color: $color__chips  !important;
}

.knockout__tournament--footer-bar {
    background-color: $black__transparent--03;
    height: 5rem;
}

.knockout__win--games {
    >span {
        color: $color__chips;
    }
}

.knockout__tournament--tile-info {
    line-height: 1.25;
}

.knockout__tournament--tile-info,
.knockout__tournament-tile__footer-text,
.knockout__win--games {
    @extend %text;
}

.knockout__tournament--tile-info--icon {
    &.new-design {
        display: none !important;
    }
}

.knockout__bottom-bar--new-design {
    display: none;
}

.knockout--text-rows__wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.knockout__container--custom-bet {
    margin-top: 1rem;
    @media only screen and ( max-height: $break-point--680 ) {
        margin-top: 0.5rem;
    }
}

.knockout__container--wrapper-play {
    .button {
        width: 26rem;
    }
}

.knockout__container--wrapper-play.bottom {
    z-index: 2;
}

.knockout__win--games {
    font-size: 2rem;

    @media screen and (max-width: $break-point--768), screen and (max-height: $break-point--568) {
        font-size: $text__size--normal;
    }
}

.knockout__tile--image {
    display: none;
}

.knockout__tile--container {
    height: 100%;
}