.club-info-footer__container--item {
    background-color: $black__transparent--03;
}

.club--info--footer__icon {
    color: $color--chips;
}

.club--info--footer__friends-link {
    >p {
        text-decoration: underline;
    }
}

.club-info-footer__friends-list-avatar {
    flex-basis: 25%;
    flex-shrink: 0;
    min-width: 7.5rem;
    padding: .25rem;
    margin: 0 !important;

    @media only screen and (max-width: $break-point--568 - 1) {
        flex-basis: 33.3%;
        min-width: 5.5rem;
    }

    @media only screen and (max-width: $break-point--360) {
        min-width: 5rem;
    }
}