/***************************************
*      Blitz-tournament skin           *
***************************************/

.game-box__container--box {
    @include container--colored($container--4);
  &.final {
    @include container--colored($container--5);
  }
  > .box-title {
    .game-box__container--separator-left,
    .game-box__container--separator-right {
      &:before {
        @include separator(yellow);
      }
    }
  }
  > .box-footer {
    > .result-team-one {
      &.won {
        color: $color--success;
      }

      &.lost {
        color: $color--negative;
      }
    }
    > .result-team-two {
      &.won {
        color: $color--success;
      }

      &.lost {
        color: $color--negative;
      }
    }
  }
}

.game__avatar__container {
  >.player_result {
      margin-top: 1rem;
      color: $color--white;
      &.won {
        color: $color--success;
      }
      &.lost {
        color: $color--negative;
      }
      font-weight: $font-weight-bold;
  }
}

.game__box-team-container{
  >.player_result {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    color: $color--white;
    &.won {
      color: $color--success;
    }
    &.lost {
      color: $color--negative;
    }
    font-weight: $font-weight-bold;
  }
}