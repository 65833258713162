.item-box__image-wrapper {
    width: var(--item-component-img-width);
    height: var(--item-component-height);
}

.item-component {
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: $border--radius;
}

.item-box__container {
    border-radius: $radius--default;

    &:not(.collected-reward) {
        .day-container__collected-overlay-wrapper {
            display: none;
        }
    }

    &.current-reward {
        border: 0.2rem solid $color--selected;
    }
}

.item-box__value {
    font-weight: $font-weight-bold;
    white-space: initial;
}

.item-box__label-with-elevated-price {
    background-image: linear-gradient(to bottom, #37569a, #304a83, #2a3e6c, #243257, #1d2742) !important;
    border-bottom: 1px solid black !important;
    box-shadow: 0px -1px 0px 0px #588bd6 !important;
}

.item-box__image {
    height: var(--item-component-height);
}

.item-box__frame-img {
    padding-bottom: 1rem;
}

.item-box__item-label-for-frame {
    font-size: 1.2rem !important;
}

.item-box__item-label-for-deck {
    font-size: 1.4rem !important;
}

.item-box__value-for-translatable-value {
    text-transform: initial;
}

@media screen and (max-width: $break-point--440) {

    .item-box__item-label,
    .item-box__item-label-for-deck {
        font-size: 1.3rem !important;
    }

    .item-box__item-label-for-frame {
        font-size: 1.1rem !important;
    }
}

@media screen and (max-width: $break-point--380) {

    .item-box__item-label,
    .item-box__item-label-for-deck {
        font-size: 1.2rem !important;
    }

    .item-box__item-label-for-frame {
        font-size: 1.1rem !important;
    }

    .item-box__item-label,
    .item-box__item-label-for-deck,
    .item-box__item-label-for-frame {
        width: 75px !important;
    }
}