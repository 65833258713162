.cg-invite-popup__room-code-label {
    color: $color--chips;
}

.cg-invite-popup__backdrop {
    align-items: flex-start !important;
    z-index: $layer--4 !important;
}

.cg-invite-popup__content {
    top: 15%;

    @media screen and (max-height: $break-point--768) and (orientation: landscape) {
        top: 10%;
    }

    @media screen and (max-height: $break-point--380) and (orientation: landscape) {
        top: 5%;
    }

    @media screen and (max-height: $break-point--360) {
        top: 0;
        min-height: initial;
    }
}

.cg-invite-popup__share-button-bclass {
    padding: 0 3rem;

    > p {
        font-size: 1.6rem;
    }

    @media screen and (max-width: $break-point--768) {
        height: 3.5rem !important;
        > p {
            font-size: $text__size--normal !important;
        }
    }
}

.invite-page__top-tabs__bclass {
    font-size: $text__size--x-large !important;
}

.invite-page__top-tabs__bclass {
    background-color: rgba(0,0,0,.2);
    width: 5rem;
    margin: .5rem;

    &.top-tabs__selected {
        background-color: $color--chips;
    }
}
