// .box__image--wrapper {
//     &.tarneeb, &.trixcomplexsolo, &.trixcomplexpairs, &.trixsolo, &.trixpairs {
//         .room--box__image--icon {
//             background-image: url(imageUrl('lobby/default/training_room_cards.png'));
//         }
//     }
//     &.domino {
//         .room--box__image--icon {
//             background-image: url(imageUrl('lobby/default/training_room_dominos.png'));
//         }
//     }
//     &.sheeshsmall {
//         .room--box__image--icon {
//             background-image: url(imageUrl('lobby/default/training_room_pawns.png'));
//         }
//     }
//     &.ludo, &.sheshbesh {
//         .room--box__image--icon {
//             background-image: url(imageUrl('lobby/default/training_room_dice.png'));
//         }
//     }
//     &.baloot {
//         .room--box__image--icon {
//             background-image: url(imageUrl('lobby/new-lobby-icons/training-room-cards.png'));
//         }
//     }
    
// }

.room__wrapper--box__tile__ribbon {
    display: none;
}