.msg-text {
    color: $chat__message-text--color;
}

.transformed-link {
    max-width: 100%;
    word-wrap: break-word;
    color: $chat__link--color;
}

.youtube-player-component {
    border-radius: 1rem;
    overflow: hidden;
}