.team-stats__container--game-stats {
    @include container--colored($main);
}

.team-stats__button--replay {
    background-color: $black__transparent--02;
    border-radius: $radius--default;
    border: .1rem solid $accent--1;
}

.team-stats__button--replay {
    &.new-design {
        display: none;
    }
}

.team-stats__container--replay {
    &:not(.rematch-disabled) {
        .circle-arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $black--7;
            font-size: 3.8rem;
        }

        .play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $black--7;
            font-size: 1.5rem;
        }

        .leaved-icon {
            display: none;
        }

        &.hasLeft {
            .play-icon {
                display: none;
            }

            .circle-arrow {
                display: none;
            }

            .leaved-icon {
                display: initial;
                color: $color__white;
                font-size: 3.2rem;
            }
        }

        &.replay {
            .play-icon {
                color: $accent--1;
            }

            .circle-arrow {
                color: $accent--1;
            }
        }
    }
}

.team-stats__container--score {
    @extend %text;

    &.won {
        color: $accent--1;
    }
}

.team-stats__container-rays {
    &:before {
        background-image: var(--rays-animation);
        background-repeat: no-repeat;
        background-position: center;
        animation: rotateRays 10s linear infinite;
        will-change: transform;
    }
}