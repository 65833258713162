.currency__baloon--wrapper {
    border: 0.1rem solid $accent--1;
    background-color: $main;
    border-radius: $radius--default;
}

.currency__baloon--arrow {
    &:after {
        border: 0.1rem solid $color__chips;
        border-radius: 0.2rem;
        background-color: $main !important;
    }
}

.currency__baloon--ratio {
    border: 3px solid $color__chips;
    border-radius: 50%;
    background-color: $main !important;
}
