/********************************************
*             Room skin                     *
********************************************/

// Styles
.btt__wrapper {
    border-radius: $border--radius;
    &.royal4 {
      background-color: rgba(0, 255, 242, 0.25);
    }
    &.royal8 {
      background-color: rgba(0, 255, 242, 0.251);
    }
    &.royal16 {
      background-color: rgba(255, 0, 234, 0.25);
    }
}

.btt__container--top-bar {
  background-color: $black__transparent--03;

  &.middle {
    display: none !important;
  }
}

.btt__container--room-icon {
  background-image: url(imageUrl('tournaments_lobby/blitz_icon.png'));
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
}

.btt__text--place-value {
  @include chip-sign(1.4rem);
}

.tile__text--name {
  @extend %text;
}

.tile__text--description {
  @extend %text;
}

.btt__container__name {
  &:before {
    @include separator( orange );
  }
}
.btt__text--place-text {
  @extend %text;
}

.btt__container__name--new-design {
    display: none;
}

.btt__play--button-wrapper {
    display: none;
}

.btt__container__description--new-design {
    display: none;
}

.btt__container--img--new-design {
    display: none;
}

.btt__image {
  display: none;
}

.btt__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}