/**************************
*   Custom select skin    *
**************************/

// Variables

// Styles
.custom-select__container {

    &.custom--select__options--visible {
        .custom-select__input {
            color: $color__white;
            border: .1rem solid $color--selected;
        }
    }
}

.custom-select__input {
    @include input();
    height: 100%;
    padding: 0 1rem !important;
}

.custom-select__container--options {
    @include container--colored($container-dark);
    @include scrollbar();
    background-color: transparent;
    border: .1rem solid transparent;
}

.custom-select__error {
    @extend %text;
    background: $color__negative;
    color: $color__white;

    &:before {
        border-color: transparent transparent #b80e0e;
        border-style: solid;
        border-width: 0 .5rem .5rem;
    }
}