/*****************************
*    Default button styles   *
*****************************/
$button-border-radius: $border--radius;

// Placeholders
%button {
  position: relative;
  display: block;
  user-select: none;
  border: none;
  color: $button__text--color;
  border-radius: $button-border-radius;
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-size: inherit;
  &:focus {
    outline: 0;
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

// Helper functions
// Get desired color from buttons color map
@function get-color( $color, $shade: 'base', $map : $button-colors-map ) {
  $parent: map-get($map, $color);
  @return map-get($parent, $shade);
}

// Main button decorator
@mixin button_decorator( $type ) {
  @if ($type == $button--white) {
    background-color: $color--white;
  } @else if ($type != $button--transparent) {
    $light: get-color($type, light);
    $normal: get-color($type, normal);
    $dark: get-color($type, dark);
    background-color: darken($normal, 5%);
    border-top: .2rem solid $light;
    border-bottom: .3rem solid $dark;
    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 1rem);
      height: 50%;
      background-color: $normal;
      top: 0;
      left: .5rem;
      border-radius: $button-border-radius;
    }
    @include touch-hover() {
      transition: background-color $transition-value ease-in-out,
      border-top $transition-value ease-in-out,
      border-bottom $transition-value ease-in-out;
      border-top: .2rem solid lighten($light, 10%);
      border-bottom: .3rem solid lighten($dark, 10%);
      background-color: lighten($normal, 5%);
      &:before {
        transition: background-color $transition-value ease-in-out;
        background-color: lighten($normal, 10%);
      }
    }


    &:disabled {
      background-color: desaturate(lighten($normal, 20%), 40%);
      border-top: .2rem solid desaturate(lighten($light, 20%), 40%);
      border-bottom: .3rem solid desaturate(lighten($dark, 20%), 40%);
      &:before {
        background-color: desaturate(lighten($normal, 25%), 40%);
      }
      > p {
        text-shadow: none;
      }
    }
  } @else {
    border: none;
    background-color: transparent;
    &:hover {
      cursor: pointer;
    }

  }

}

// Button that contains only text
@mixin button--text( $type ) {
  @include button_decorator($type);
  > p {
    @extend %text;
    position: relative;
    display: block;
    font-size: $text__size--large;
    line-height: 108% !important;
    font-weight: 600;
    text-align: center;
    text-transform: initial;
    color: $color__white;
    margin: 0 .5rem;
      word-break: break-word;
    @if ($text-shadow-enabled == true) {
      text-shadow: $button__shadow--text;
    }
    @if ($type == $button--white) {
      color: #757777;
      text-shadow: none;
    }
  }

  @if ($type == $button--transparent) {
    > p {
        text-decoration: underline;
        text-decoration-style: solid;
    }

    &:disabled {
      > p {
          opacity: 0.5;
      }
    }
  }

  > .bc__spinner--cmp {
      width: 2em !important;
      font-size: $text__size--large !important;
  }
  @media only screen and (max-width: $break-point--768) {
    > p {
      font-size: $text__size--normal;
    }

    > .bc__spinner--cmp {
        font-size: $text__size--normal !important;
    }
  }
}

// Button that contains only icon
@mixin button--icon( $icon, $type, $icon-size: 2.4rem ) {
  @include button_decorator($type);
  font-size: inherit;
  @if ($type == $button--transparent) {
    background-color: transparent;
  }
}

// Button that contains text and icon
@mixin button--icon-text( $icon, $type) {
  @include button_decorator($type);
  position: relative;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
    > p {
    @extend %text;
    position: relative;
    display: block;
    font-size: $text__size--large;
    line-height: 108% !important;
    font-weight: 600;
    text-align: center;
    text-transform: initial;
    color: $color__white;
    @if ($text-shadow-enabled == true) {
      text-shadow: $button__shadow--text;
    }
    @if ($type == $button--white) {
      color: #757777;
      text-shadow: none;
    }
  }

  @if ($type == $button--transparent) {
    &:disabled {
      > p {
          opacity: 0.5;
      }
    }
  }

  > .bc__spinner--cmp {
    width: 2em !important;
    font-size: $text__size--large !important;
  }

  @media only screen and (max-width: $break-point--768) {
    > p {
      font-size: $text__size--normal;
    }
    > .bc__spinner--cmp {
        font-size: $text__size--normal !important;
    }
  }
  @media only screen and (max-width: $break-point--380) {
    padding: 0 1rem;
    > p {
      font-size: $text__size--small;
    }

    > .bc__spinner--cmp {
        font-size: $text__size--small !important;
    }
  }
}

// Button with no text only image from url
@mixin button--image( $type ) {
  @include button_decorator($type);
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
  }
}

// Button that contains text and image
@mixin button--image-text($type) {
  @include button_decorator($type);
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 2.5rem;
    position: relative;
    margin-right: 0.75rem;
  }
  > p {
    @extend %text;
    position: relative;
    display: block;
    font-size: $text__size--large;
    line-height: 108% !important;
    font-weight: 600;
    text-align: center;
    text-transform: initial;
    color: $color__white;
    margin: 0;
    @if ($text-shadow-enabled == true) {
      text-shadow: $button__shadow--text;
    }
    @if ($type == $button--white) {
      color: #757777;
      text-shadow: none;
    }
  }
  @media only screen and (max-width: $break-point--768) {
    > p {
      font-size: $text__size--normal;
    }
  }
}
