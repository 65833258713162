/******************************
*     Phrases list skin     *
******************************/

.shop-inventory__wrapper {
    @include scrollbar(.5rem);
}


.shop-inventory__container--item {
    &:hover {
        transform: scale(1.05);

        .bg-img {
            &:after {
                color: $accent--1 !important;
            }
        }
    }
}

.shop-inventory__text--title {
    @extend %text;
    color: $accent--1;
}

.shop-inventory__container--item {
    .shop-inventory__container--tile {

        @include container--colored($color__black, .5);

        .bg-img {
            &.frame {
                @include icon($add-frame, 5rem);
            }

            &.emojiSet {
                @include icon($icon-emoji, 5rem);
            }

            &.phraseSet {
                @include icon($in-game-emoji, 5rem);
            }

            &.deck,
            &.skatDeck,
            &.klaverjasDeck,
            &.deDeck,
            &.itDeck,
            &.chDeck,
            &.esDeck,
            &.tarotDeck {
                @include icon($add-deck, 5rem);
            }

            &.table {
                @include icon($add-table-background, 5rem);
            }

            &.pawn {
                @include icon($add-pawn, 5rem);
            }

            &.dice {
                @include icon($add-dice, 5rem);
            }

            &.board {
                @include icon($add-board, 5rem);
            }

            &.booster {
                @include icon($add-booster, 5rem);
            }

            &.messageColor {
                @include icon($font-color-change, 5rem);
            }

            &.nameColor {
                @include icon($name-color-change, 5rem);
            }

            &.profileBackground {
                @include icon($profile-background, 5rem);
            }

            &.voiceOver {
                @include icon($in-game-music, 5rem);
            }

            &:after {
                @media only screen and (max-width: $break-point--568) {
                    font-size: 4rem;
                }
            }
        }
    }
}

.shop-inventory__wrapper {
    @extend %shop-wrapper;
}