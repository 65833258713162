/**************************
*   Game history skin     *
**************************/

// Variables
$badge-won: imageUrl("game-history/game-history__won.png");
$badge-lost: imageUrl("game-history/game-history_lost.png");

// Styles
.game-history-box__wrapper {
  @include scrollbar();
}

.game-history-box__container--game-played {
  @include container--colored($container--4);
}

.game-history-box__text--no-games {
  @extend %stroked;
}

.game-history-box__container--upper-info {
  background-color: transparentize($color__black, 0.7);
}

.game-history-box__container--separator {
  &:before {
    @include separator(yellow);
  }
}

.game-history-box__container--room-type {
  > p {
    color: $accent--1;
    @extend %text;
  }
}

.game-history-box__text--player-score {
    color: $color--white;
}

.game-history-box__text--text-game-status {
  display: flex;
  align-items: center;
  .game-history-box__text--sign {
    margin-right: .3rem;
  }
    &.game-won {
        color: $active--color;
    }

    &.game-lost {
        color: $color__negative;
    }
}

.game-history-box__container--my-team {
  @at-root .lang-ar &,
  .lang-fa & {
    order: 3;
  }
}

.game-history-box__container--other-team {
  @at-root .lang-ar &,
  .lang-fa & {
    order: 1;
  }
}

.game-history-box__text--win-loss,
.game-history-box__text--my-score,
.game-history-box__text--points {
  color: $accent--1;
}

.game-history-box__text--chips {
    @media only screen and (min-width: $break-point--480) {
        font-size: $text__size--normal !important;
    }
    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--smaller !important;
    }
}

.game-history-box__text--my-score,
.game-history-box__text--text-game-status,
.game-history-box__text--chips {
    font-weight: $font-weight-normal !important;
}

.game-history-box__container--middle-info {
    >p {
        font-weight: $font-weight-bold;
        font-size: $text__size--normal;
    }
}

.game-history-box__container--footer {
  position: relative;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: $break-point--360) {
      font-size: $text__size--smaller;
  }

  @at-root .lang-ar &,
  .lang-fa & {
      flex-direction: row-reverse;
  }
}