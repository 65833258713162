.mr__wrapper {
    &.game-id__belot {
        background-image: url(imageUrl("lobby/room-bg-playnow-belot.svg"));
        background-color: #004d40;
    }
    &.game-id__tabla {
        background-image: url(imageUrl("lobby/room-bg-playnow-tabla.svg"));
        background-color: #762c12;
    }
    &.game-id__santase {
        background-image: url(imageUrl("lobby/room-bg-playnow-santase.svg"));
        background-color: #256dd8;
    }
    &.game-id__bace {
        background-image: url(imageUrl("lobby/bace/room_bg_matchmaking.svg"));
        background-color: #562b6d;
    }
    &.game-id__blato {
        background-image: url(imageUrl("lobby/room-bg-playnow-blato.svg"));
        background-color: #004d40;
    }
}