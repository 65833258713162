.top-bar__container--notification {
    &.premium-gift {
        position: relative;
        width: calc(100% - 2rem) !important;
        height: 20rem !important;
        margin: 1rem auto;
        max-width: 55rem;
        border-radius: $border--radius;
        border: .1em solid $accent--1;
    }
}

.top-bar-notif__container--actions {
    &.premium-gift {
        position: absolute;
        top: 0;
        right: 0;
        height: fit-content !important;
        padding: initial !important;
    }
}

.top-bar__container--player-avatar {
    .username-container {
        border-radius: .4rem;
        border: .1em solid $accent--1;
    }
}