.currency-component {
    font-weight: $font-weight-bold;
    font-size: 2.4rem;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
}

.currency-component-host {
    font-size: 2.4rem;
    font-weight: 700;
    position: relative;
    vertical-align: top;
    display: inline-flex;
}

.currency-component__cross {
    &.whiteCross {
        background-color: $color--white;
    }
    &.redCross {
        background-color: $color--negative;
    }
}