.tile-badge__outer {
    @include aspect-ratio(1, 1);
    background: linear-gradient(#ff1a00, #ce0400);
    box-shadow: 0 9px 13px 2px rgb(0 0 0 / 60%);
}

.tile-badge__inner {
    background: linear-gradient(#ce0400, #ff1a00);
}

.tile-badge__text {
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: $font-weight-extra-bold;
    text-shadow: 0px 2px 2px #b22519;
}