.tile__wrapper {
    &.highlighted {
        border: .1rem solid $accent--1;
        border-radius: $radius--default;
        overflow: hidden;
    }
}

.tile-counter {
    right: 0.5rem;
    top: 0.5rem;
  }