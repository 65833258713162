.responsive__buy-button {
    @media only screen and (max-width: $break-point--568) {
        font-size: 1.3rem !important;
    }
}

.buy--offer--button {
    max-width: 90%;
}

.offer-buy-vip-status-text {
    max-width: 90%;
    font-size: $text__size--normal !important;
    text-transform: uppercase;
}

.offer-buy-vip-status-text {
    color: $color--chips;
    font-weight: $font-weight-bold;
}

.buy--offer--button-bclass {
    > p {
        @media only screen and (max-width: $break-point--440) {
            font-size: 1.2rem !important;
        }
    }
}