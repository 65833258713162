.challenges-dropdown__container--friend {
    @include container--colored($main);
    transition: background-color ease 1s;

    &.highlighted {
        background-color: light($main);
    }
}

.challenges-dropdown__overlay {
    background-color: $black__transparent--08;
}

.challenges-dropdown__refresh-time,
.challenges-dropdown__more {
    @extend %text;
}

.challenges-dropdown__refresh-time {
    text-align: center;
}

.challenges-dropdown__refresh--timer {
    font-size: $text__size--small !important;
}

.challenges-dropdown__top-tabs {
    margin: 0 !important;
    display: initial !important;
    margin: 0 1rem;

    .tab {
        padding: .5rem 1rem;

        @media screen and (max-width: $break-point--568) {
            padding: .5rem 0.5rem;
        }
    }
}

.challenges-dropdown__top-tabs__scroll {
    visibility: hidden;
}

.challenges-dropdown__top-tabs__scrollbar {
    visibility: hidden;
}

.top-tabs__list-item--navigation {
    &.separator {
        &:before {
            background-color: $accent--1;
        }
    }
}

.challenges-dropdown__top-tabs__bclass {
    color: $color--white !important;
    background-color: #151835 !important;
    width: 11rem;
    margin: 0;
    transition: none !important;

    >p {
        text-decoration: none !important;
        font-size: $text__size--normal !important;
        text-transform: uppercase !important;
    }

    &.daily-challenges-dropdown__top-tabs__bclass__selected {
        background-color: $color--chips !important;
    }

    &.weekly-challenges-dropdown__top-tabs__bclass__selected {
        background-color: $color--chips !important;
    }
}

.challenges-dropdown__weekly-challenges {
    background-image: linear-gradient(to bottom, #0065e3, #007cec, #0091f2, #2ca5f5, #55b8f7);
    box-shadow: none !important;
}

.challenges-dropdown__container--weekly-challenge {
    border-radius: 2rem;
    background-image: linear-gradient(to bottom, #0061d6, #0a4fb7, #0a3e99, #062d7c, #001e60);
}

.challenges-dropdown__container--weekly-challenge .pib__wrapper {
    background-color: transparent;
}

.challenges-dropdown__top_tabs--notificaton-sign {
    position: absolute;
    top: 0;
    right: 0;
}

.challenge-box--progress-info-box--skippies-button {
    width: 70px;
    margin: 0 !important;
    border-radius: $container-border--radius;
    background-image: linear-gradient(to bottom, #345896, #2d4e88, #26457b, #1f3c6e, #183361);
    box-shadow: inset 0px -2px 2px 0px #00002a, inset 0px 2px 1px 0px #64acff;
}

.challenge-box--progress-info-box--reroll-button {
    width: 70px;
    margin: 0 !important;
}

.challenge-box--progres-info-box--currency-component {
    font-size: 2.2rem !important;
}