$font-size: 2rem;

.rewarded-game-bonus--tile__content--container {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &.wheelOfFortune {
        background-image: url(imageUrl("lobby/wheeloffortune3.jpg"));
    }
    &.scratchCard {
        background-image: url(imageUrl("lobby/ScratchCard_1.png"));
    }
}

.rewarded-game-bonus--tile__text--container {
    background-color: $black__transparent--07;
    z-index: 2;
}

.rewarded-game-bonus--tile__timer {
    font-size: $font-size !important;
    color: $color__white;

    @media only screen and (max-width: $break-point--768), (max-height: $break-point--440) {
        font-size: $text__size--normal !important;
    }
}

.rewarded-game-bonus--tile__button-bclass {
    @media only screen and (max-height: $break-point--440) {
        > p {
            font-size: $text__size--normal !important;
        }
    }
}