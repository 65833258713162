.user-input__input {
    background: $black__transparent--03;
    border: none;
    border-bottom: 1px solid #f3a522;

    &:active,
    &:focus {
        border: none;
        border-bottom: 1px solid #f3a522;
    }
}