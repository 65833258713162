.first-purchase-offer__container {
    border-radius: 10px;
    box-shadow: 0 6px 10px 0 #05061a78;
}

.first-bundle-offer-card {
    border-radius: 1.5rem;
}

.first-purchase-offer__title {
    font-size: 2.4rem;
    font-weight: $font-weight-bold;
    font-style: italic;
    text-transform: uppercase;
    color: #331c00;
    text-shadow: 0px 1px #eca1ff;
    @extend %text;
}

.first-purchase-offer__button {
    max-width: 18rem;
    display: block;
    margin: 0.5rem auto 1.5rem auto;
}

.first-purchase-offer--container__wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.first-purchase-offer__items {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.first-purchase-offer__item {
    width: 35%;
    position: relative;
    box-shadow: 0px 2px 1px 0px #efb7fa;
    font-size: 1.8rem;
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
    @include item-component((width: 50%));
}

.first-purchase-flag__container {
    background: linear-gradient(0deg, #070707 0%, #44484c 100%) !important;
    box-shadow: 0 2px 3px 0 #834718, 0 -0.5px 0px 0 #626569,
        0 -1px 0px 0 #a9adb1 !important;
}

.first-purchase-flag__text {
    text-align: center;
    padding: 0 1rem;
}

@media only screen and (max-width: $break-point--480) {
    .first-purchase-offer__item {
        font-size: 1.6rem;
        @include item-component((width: 45%));
    }

    .first-purchase-offer__title {
        font-size: 2rem;
    }

    .first-purchase-offer__button {
        max-width: 14rem;
    }
}

.first-purchase-offer--disable {
    pointer-events: none;
}
