.club--page__separator {
    position: relative;

    &:before {
        @include separator(yellow);
    }
}

.club--page__join--footer--text {
    @extend %text;
    text-align: center;
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--small;
    }

    > span {
        font-weight: 600;
    }
}

.club-page__top-tabs__bclass {
    font-size: $text__size--x-large !important;
    background-color: #151835;
    width: 4rem;
    margin: 0 .5rem !important;

    &.top-tabs__selected {
        background-color: $color--chips;
    }
}

.club-page__top-tabs__tab {
    display: flex;
    align-items: center;
    height: 5rem !important;
}