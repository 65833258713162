.cgr__cover--x {
    position: absolute;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    background-color: transparentize($color__black, 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;

}

.cgr__room-avatar--add-button {
    background-color: transparentize($color__black, .5) !important;
}

.cgr__room--bot {
    font-size: 5rem;
    color: $white__transparent--05;
}