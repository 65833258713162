.events-tile__wrapper,
.events-tile__initial {
    border-radius: $border--radius;
}

.events-tile__initial {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}
