.cgr__wrapper {
    background-color: #283b67;
    padding: 0;

    &.expanded {
        background-color: #283b67;
    }
}

.cgr__wrapper--header {
    background-color: #031236;
}