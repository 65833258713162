$skin-font-family: "RobotoRegular" !default;
$font-weight-thin: 100 !default; // Thin (Hairline)
$font-weight-extra-light: 200 !default; // Extra Light (Ultra Light)
$font-weight-light: 300 !default; // Light
$font-weight-normal: 400 !default; // Normal (Regular)
$font-weight-medium: 500 !default; // Medium
$font-weight-semibold: 600 !default; // Semi Bold (Demi Bold)
$font-weight-bold: 700 !default; // Bold
$font-weight-extra-bold: 800 !default; // Extra Bold (Ultra Bold)
$font-weight-black: 900 !default; // Black (Heavy)
$font-weight-extra-black: 950 !default; // Extra Black (Ultra Black)

// creates classes for the font preload workaround in the index.html
$fonts-list: ();
@mixin font-face($fontFamily, $fontWeight, $fontStyle, $preload: true) {
    @font-face {
        font-family: $fontFamily;
        font-weight: $fontWeight;
        font-style: $fontStyle;
        @content;
    }

    @if ($preload) {
        .font-preload-#{length($fonts-list)} {
            font-family: $fontFamily;
            font-weight: $fontWeight;
            font-style: $fontStyle;
        }

        $fonts-list: append($fonts-list, length($fonts-list), space) !global;
    }
}
