/**********************************
*      Matching styles skin       *
**********************************/

$bet__color: $color--chips;

// Styles
// My avatar
.avatars__matching__wrapper {
  @media only screen and (max-height: $break-point--380) {
    @include scrollbar();
  }
}

.avatars__matching__container--my-avatar {
  border-radius: $radius--default;
  background-color: dark( $main );
  border: .1rem solid dark($accent--1);
  .avatars__matching__image--my-avatar {
    border-radius: $radius--default;
  }
}

// Middle section
.avatars__matching__container--middle {
  @extend %text;
  border-radius: $radius--default;
  background: transparentize( $secondary, .1 );
  .avatars__matching__text--middle {
    color: $accent--1;
  }
}

// Spinners
.avatars__matching__container--motion {
  border: .1rem solid dark($accent--1);
  border-radius: $radius--default;
  background-color: $color__white;
}

.matching__label--bet {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  @extend %text;
  &:before {
    @include separator( orange );
    width: 90%;
    margin: 0 5%;
  }
  .avatars__text--bet {
    @extend %text;
    color: $color__white;
  }
}

.avatars__matching__bet__icon {
  fill: $color--chips !important;
  font-size: $text__size--x-large;
}

.avatars__matching__text-hint-text {
  @extend %text;
}

.avatars__matching__text--middle__svg {
  display: none !important;
}