// Images
$background__pattern: url( imageUrl( 'backgrounds/bg_pattern.svg' ) );
$game__logo: imageUrl('game-logo/logo.svg');
$game__host: imageUrl('game-host/host.png');

:root {
    --background__pattern: #{$background__pattern};
    --game__logo: #{$game__logo};
    --game__host: #{$game__host};
}

// Global
$before: 'before';
$after: 'after';

// Icons
$icon__size--default: 2.4rem;
$icon__args: $icon__size--default, $secondary, $before;

$text--shadow: 0 0 .5rem $black__transparent--05;
