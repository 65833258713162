.home-lobby__container--outer {
  @extend %container--aside !optional;
}

.home-lobby__chat {
    position: absolute;
    height: calc(100% - 10rem) !important;
    top: 6rem !important;
    width: calc((100% - #{$max-content-width}) / 2 - 20px) !important;
    z-index: $layer--3;

    @media screen and (max-width: $break-point--1440) {
        width: calc((100% - #{$max-content-width-1440}) / 2 - 20px) !important;
    }

    @media only screen and (max-width: $break-point--1366) {
        top: 5rem !important;
    }

    @media only screen and (max-width: $break-point--768) {
        top: 4rem !important;
    }

    @media screen and (max-height: $break-point--568) {
        top: 1% !important;
        height: 98% !important;
    }

    @media only screen and (max-width: ($break-point--568 - 1)) {
        width: 100% !important;
        left: 50%;
        transform: translateX(-50%) !important;
        top: 0% !important;
        height: 100% !important;
        min-width: initial !important;
        right: 0 !important;
    }
}

.home-lobby__container--inner {
    height: 100%;
    width: 100%;
}