/**********************************
*          Home page skin         *
**********************************/

// Styles

.home__wrapper {
    overflow-y: auto;
    @include scrollbar();
    overflow-x: hidden;
}

.home__image--logo {
    @include background-image($game__logo, auto, 100%, center, center);
}

.home__image--game-element {
    @include background-image($game__host, auto, auto, center, center);
    background-size: contain;
}

// Terms an privacy
.home__text--terms-privacy {
    @extend %text;
    color: $color__white;

    .twp__text {
        display: block !important;
    }
}

.home__link--terms {
    @extend %link;
}

.home__link--privacy {
    @extend %link;
}

.home__custom-select__icon {
    width: 2rem;
    margin-right: 1rem;
}

.home__custom-select__option {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: $radius--default;

    > p {
        @extend %text;
        font-size: $text__size--large;
    }

    &:hover {
        cursor: pointer;
        background-color: lighten($main, 20%);
    }
}