/*****************************************
*       Private feed skin                *
*****************************************/

$chat__new-message-arrow--color: $accent--1;

.pf-tab__container--chat {
    background-color: $color__white;
}

.pf-tab__container--messages {
    @include scrollbar();
    background-color: $color__white;
}

.pf-tab__wrapper--convs {
    @include scrollbar();
}

.pf-tab__container--conversations {
    background-color: #efefef;
}

.pf__maintenance-text {
    color: $color--chips;
}

.pf-tab__username {
    @extend %text;
    color: $color--chips;
    font-weight: $font-weight-bold;
}

.pf-tab__search-bar__input {
    background-color: $color--white !important;
    border: none !important;
    color: #969696 !important;
}