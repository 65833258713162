.pib__wrapper {
    @extend %container--bordered;
    min-height: initial;
    border-radius: 2rem;
    background-color: transparent;
}

.pib__container--title,
.pib__container--description {
    @extend %text;
}

.pib__reward-value {
    color: $color--chips;
}

.pib__button--bclass {
    padding: 0 2rem;
}

.pib__svg-icon-cmp {
    color: $accent--1;
}

.pib__button {
    p {
        font-size: $text__size--normal !important;
    }
}

.pib__container--reward--text {
    @extend %text;
}

.pib__container--reward--wrapper {
    width: 100%;
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}