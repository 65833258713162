@mixin ranked-leaderboard-cmp($map: $ranked-leaderboard) {
    $merged: map-merge-deep($ranked-leaderboard, $map);

    $list-max-height: map-get($merged, list-max-height);
    $overflow: map-get($merged, overflow);

    --ranked-games-leaderboard-list-max-height: #{$list-max-height};
    --ranked-games-leaderboard-list-overflow: #{$overflow};
}

$ranked-leaderboard: (
    list-max-height: initial,
    overflow: auto
);