/***********************
* Free rewards skin    *
***********************/

.free-rewards-popup__isNotApp {
    max-height: 77rem !important;
}

.free-rewards-popup__wrapper {
    background: $black__transparent--08;
}

.free-rewards-popup__container--content {
    @include scrollbar();
}

.free-rewards__container--close-redesign {
    display: none;
}

.blinking-star {
    @include icon($star, 0.5rem, $accent--1, $after);
    animation: blink-star 1.6s ease-in-out infinite;
    transform: translate(-50%, -50%);
    position: absolute;
}

@keyframes blink-star {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.mini-game--wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation: mini-game-visible 0.3s cubic-bezier(0.69, 0, 0, 1) forwards;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10002; // TODO: Remove after all popups are dynamically created
}

@keyframes mini-game-visible {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.free-rewards__top-tabs {
    margin: 0 !important;
    display: initial !important;
    margin: 0 1rem;

    .tab {
        padding: .5rem 1rem;

        @media screen and (max-width: $break-point--568) {
            padding: .5rem 0.5rem;
        }
    }
}

.free-rewards__top-tabs__scroll {
    visibility: hidden;
}

.free-rewards__top-tabs__scrollbar {
    visibility: hidden;
}

.free-rewards__top-tabs__bclass {
    color: $color--white !important;
    background-color: #151835 !important;
    width: 11rem;
    margin: 0;
    transition: none !important;

    >p {
        text-decoration: none !important;
        font-size: $text__size--normal !important;
        text-transform: uppercase !important;
    }

    &.free-rewards__top-tabs__bclass__selected {
        background-color: $color--chips !important;
    }
}

.top-tabs--wrapper {
    margin-bottom: 0.5rem;
}

.free-rewards__top_tabs--notificaton-sign {
    position: absolute;
    top: 0;
    right: 0;
}

.free-rewards-popup__title-bar {
    background-color: #131e35 !important;
}

.free-rewards-popup-top-tabs-item {
    >p {
        text-transform: uppercase;
    }
}