/*******************************
* PromoTournament info skin    *
*******************************/

// Variables
// Placeholders
%options_label {
  color: $color__white;
}

// Styles
.pi__wrapper {
  @include scrollbar();
}

.pi__container--name {
  &:before {
    @include separator( orange );
  }
  .pi__text--name {
    @extend %text;
    color: $color__white;
  }
}

.pi__text--promo {
  @extend %text;
  color: $color__white;
}

.pi__container--from {
    @extend %options_label;
    @extend %text;
}

.pi__container--to {
    @extend %options_label;
    @extend %text;
}

.pi__container--entry {
    @extend %text;
}

.pi__value {
  color: $accent--1;
}
