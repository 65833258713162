/*********************
* Empty Content skin *
*********************/

// Styles
.empty-content__container--no-notifications-image {
    @include background-image($game__host, auto, auto, center, center);
    background-size: contain;

    &:after {
        @include separator(yellow);
    }
}

.empty-content__text--no-notifications {
    @extend %text;
    @extend %stroked;

    &:not(.multi-line-white) {
        @extend %text;
        color: $accent--1;
        @at-root .lang-ar &,
    .lang-fa & {
            max-width: 25rem;
            text-align: center !important;
        }
    }

    &.multi-line-white {
        @extend %text;
        color: $accent--1;
        @at-root .lang-ar &,
    .lang-fa & {
            max-width: 25rem;
            text-align: center !important;
        }
    }
}
