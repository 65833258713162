/**********************************
*  Global feed conversation skin  *
**********************************/
// Colors
$chat__unread-messages--color: $color--negative;
$chat__topic-username--color: $main;
$chat__topic-sticky-username--color: $main;
$chat__topic-sticky-background--color: $color__white;
$chat__topic-message--color: $main;
$chat__topic--sticky-message--color: $main;
$chat__icons--color: $accent--1;
$chat__topic-message-sent-before--color: $black--7;
$chat__topic-unread-background--color: #d3d2d1;
$chat__icons-sticky--color: $accent--1;
$chat__topic-background--color: $color__white;
$chat__tpic-username-font-weight: 900;
$chat__topic-unread-username--color: $chat__topic-sticky-username--color;
$chat__topic-unread-message--color: $chat__topic-message--color;
$chat__topic-unread-message-sent-before--color: $chat__topic-message-sent-before--color;

.conversation--container {
    @include container--colored( $container-dark);
    background-color: $chat__topic-background--color;
    border: none;
    @include touch-hover() {
        background-color: darken($chat__topic-background--color, 10%);
        border: none;
    }
}

// User & last message
.text--username {
    color: $chat__topic-username--color;
    font-weight: $chat__tpic-username-font-weight;
}

.text--last-message {
    @extend %text;
    color: $chat__topic-message--color;
}

// Conversation options
.text--conv-time {
    color: $chat__topic-message-sent-before--color;
}

.chat--conv-icons__icon {
    min-width: 2rem !important;
}

.conv--icon {
    height: 100%;
    color: $accent--1;
    font-size: $text__size--large !important;
}

.conversation--unread {
    @include container--colored( $chat__topic-unread-background--color, 0);
    border: none;

    .text--username {
        color: $chat__topic-unread-message--color;
    }

    .text--conv-time {
        color: $chat__topic-unread-message-sent-before--color;
    }

    .text--last-message {
        color: $chat__topic-unread-username--color;
    }

    @include touch-hover() {
        @include container--colored( lighten($chat__topic-unread-background--color, 10%), 0);
        border: none;
    }
}

.button--arrow {
    color: $chat__icons--color;
    font-size: 3rem;
}

.icon--pin {
    color: $chat__icons-sticky--color;
    font-size: 2rem;
}

.button--delete-format {
    color: $chat__icons--color !important;
}

.button--like-format {
    color: $chat__icons--color !important;
}

.conversation__icon-text-value {
    color: $chat__topic-message--color  !important;
}

.conversation__like--icon {
    color: $chat__topic-message--color  !important;
}

.conversation__messages-count-icon {
    color: $chat__topic-message--color !important;
}

.conversation__enter {
    animation: conversationEnterAnimation .2s linear;
}

.conversation__exit {
    animation: conversationExitAnimation .3s linear;
}

@keyframes conversationEnterAnimation {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes conversationExitAnimation {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(100%);
    }
}

.dropdown--item--button {
    color: $chat__icons--color;
    > p {
        color: $main;
        text-shadow: none;
        font-size: $text__size--normal !important;
    }
}

.chat--conversation__dropdown {
    background-color: $color__white !important;
}

.dropdown--item {
    &:hover {
        background-color: darken($color__white, 20%) !important;
    }
}

.button--dropdown__toggle {
    color: $chat__icons--color;
    height: 3rem !important;
}

.chat--conversation__icon {
    font-size: $text__size--large !important;

    @media only screen and (max-width: $break-point--480) {
        font-size: 1.6rem;
    }
}

.conversation__action {
    &.dropdown--button {
        width: 100% !important;
    }
}

.dropdown--item--button {
    justify-content: flex-start !important;
}