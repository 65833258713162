.cbt__tutorial-content {
    @include hide-scrollbar()
}

.cbt__tutorial--host {
    display: flex;
    background-color: rgba(0, 28, 40, 0.9);
    height: 100%;
    width: 100%;
    justify-content: center;
}