$gray: #a6c5ca;

.promo-tt__wrapper {
    border-radius: 0.5rem;
}

// Top tabs
.promo-tt__container--top-bar {
  background-color: $black__transparent--03;
  display: flex;
  z-index: 1;
}

.promo-tt__text--top-item-value {
  @include chip-sign(1.4rem);
  &.prize {
    margin: 0;
    &:before { display: none }
  }
}

// Name
.promo-tt__container__name {
  &:before { @include separator( orange ); }
  .promo-tt__text--title {
      @extend %text;
      font-size: $text__size--large;
      line-height: 18px;
        @media only screen and (max-width: $break-point--480) {
            font-size: $text__size--normal;
            line-height: 14px;
        }
    }
}

.not-started {
  .timer__value {
    color: $accent--1;
  }
}

.promo-tt__text--top-item-label {
  @extend %text;
  &.normal {
    font-size: $text__size--normal !important;
    line-height: 20px;
    @media only screen and (max-width: $break-point--480) {
      font-size: $text__size--normal;
    }
  }
}

// Options trigger
.options-trigger {
  display: flex;
  border: .1rem solid $accent--1;
  background-color: $black__transparent--02;
  transition: background-color .3s ease-in-out;
  z-index: 2;
  .options-trigger__icon {
    width: 75%;
    color: $color--chips;
  }
  &:hover {
    background-color: lighten( $promo__popup--tabs, 3% );
  }
}

.promo-tt__container--state {
    @extend %text;
}

// Upper section
.promo-tt__container--upper {
    @extend %text;
    .promo-tt__container--upper-label {
        .promo-tt__timer__value {
            span {
                font-size: $text__size--x-large !important;

                @media only screen and (max-width: $break-point--768) {
                    font-size: $text__size--normal !important;
                }
            }
        }
    }
}

.promo-tt__invite--only-indicator {
    background-color: $black__transparent--03;
}

.promo-tt__invite--only-indicator-dot {
    background-color: $color__white;
    border: 2px solid $gray;
}

.promo-tt__play--button-wrapper {
    display: none;
}

.promo-tt__info--container {
    display: none;
}

.promo-tt_bottom-info {
    display: none;
    z-index: 1;
}

.promo-tt__image--background-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.promo-tt__container {
    z-index: 1;
    height: 100%;
}

.promo-tt {
    height: 100%;
}
