/***********************
* Settings popup skin  *
***********************/

.settings-popup__container--main {
    @extend %container--bordered;
}

.settings-popup__container--title {
    > p {
        @extend %text;
    }
}

.settings-popup__container--content-inner {
    @include scrollbar();
}

.settings-popup__userId {
    font-size: $text__size--normal;
    color: $color--chips;
    > span {
        color: $color__white;
        text-decoration: underline;
        cursor: pointer;
    }

    @media screen and (max-width:$break-point--380) {
        font-size: $text__size--small;
    }
}

.settings-popup__container--option {
    &:after {
        @include separator(orange);
        width: 90%;
        left: 5%;
    }
}

.settings-popup__userId--paragraph {
    @extend %text;
}

.settings-popup__container--copyright {
    font-size: $text__size--normal;
}

.settings-popup__social-media-links {
    &:after {
        @include separator(orange);
        width: 90%;
        left: 5%;
    }
}

.settings-popup__control-half {
    width: 49%;
    margin: 0 !important;
}

.settings-popup__link--privacy__terms {
    font-weight: $font-weight-bold;
    cursor: pointer;
}

.settings__top-tabs__bclass {
    width: 10rem !important;
    color: $color__white;
    background-color: #151835;
    margin: 0 .2rem;
    > p {
        color: $color__white;
        font-size: $text__size--normal;
        text-decoration: none;
    }
    &.settings-popup__top-tabs__current {
        background-color: $color--chips;
    }
}

.settings-popup_controls {
    &.logout_button, &.action_buttons, &.social {
        width: calc(100% - 4rem);
        margin-left: 2rem;
    }
}

.dev__image, .vivox__image {
    @media screen and (max-width:$break-point--380) {
        width: 100%;
    }
}