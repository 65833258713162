.club--profile--info__wrapper {
    border-radius: $border--radius;
}

.club-player-profile-info__container {
    background-color: $black__transparent--03;
    border-radius: $border--radius;
}

.club--profile--info__misc {
    font-size: $text__size--x-large;
}

.club--profile--info__text--username {
    color: $accent--1;
}

.club--profile--info__role {
    @extend %text;
    >span {
        color: $accent--1;
    }
}

.club--profile--info__no-info {
    font-size: $text__size--large;
    font-weight: $font-weight-medium;
}