
//$loader-icon-image: imageUrl('loaders/loader_1.svg');
//
//.loader__image {
//  @include background-image($loader-icon-image);
//}

.container__loader {
  z-index: $layer--4;
}
