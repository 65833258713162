.game-invite__container {
    @extend %container--bordered;
    width: 100%;
    height: 100%;
    min-height: initial;
}

.game-invite__description {
    color: $color--chips;
    font-size: 1.5em;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}