.restriction__container {
    > * {
        font-size: 1.6rem;
        padding: 1rem;
        margin: 0.5rem;
        @media only screen and (max-width: $break-point--768) {
            padding: 0.5rem;
        }
    }
}
