.ranked-season-tile-timer-wrapper {
    border-radius: $border--radius;
    background-color: $black__transparent--06;
}

.ranked-season-tile-timer {
    font-size: 1em!important;

    .time {
        font-size: 1em;
        color: white;
        font-weight: $font-weight-bold;
        text-shadow: 0 1px #540d13, 0 3px #a62230;
    }

    &.small-tile {
        .time {
            @media screen and (max-width: $break-point--568) {
                font-size: .8em!important;
            }

            @media screen and (max-width: $break-point--480) {
                font-size: .7em!important;
            }

            @media screen and (max-width: $break-point--380) {
                font-size: .6em!important;
            }
        }
    }
}