.free-reward-box__container--timer {
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;

    > timer {
        @extend %text;
        font-size: $text__size--normal;
        text-transform: lowercase;
        font-weight: $font-weight-normal;
    }
}

.free-reward-box__container--button-action {
    order: 3;
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}
