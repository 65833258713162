.cbti__hint {
    @extend %text;
    font-weight: $font-weight-bold;
    font-size: 2.5vh;
}

.cbti__step {
    font-weight: $font-weight-bold;
    font-style: italic;
    font-size: 1.4vh;
    opacity: 0.5;

    @media screen and (max-width: 768px) {
        font-size: 2.6vh;
    }
}
