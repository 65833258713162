.popup__outlet--wrapper {
    @extend %platform-skinned-popup;
    max-width: $popup-outlet-max-width !important;
    max-height: $popup-outlet-max-height !important;
}

.popup__outlet--wrapper-title {
    @extend %platform-skinned-popup-title-bar;
}

.popup__outlet--backdrop {
    z-index: $layer--4 !important;
}

.popup__outlet--wrapper-fullscreen {
    max-width: $max-content-width !important;

    @media screen and (max-width: $break-point--1440) {
        max-width: $max-content-width-1440 !important;
    }
}

.popup__outlet--wrapper-title {
    .popup-title-bar__text--static-title {
        @media screen and (max-width: $break-point--320) {
            font-size: 1.6rem !important;
        };
    }
}