.mr--scroll__wrapper {
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: center center !important;
    border-radius: $container-border--radius;

    &.mr--scroll__wrapper__counter-show {
        .mr--scroll__room-name {
            margin-top: 2rem;
            font-size: 2.6rem !important;
        }
    }

    &.belot {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-pattern.svg"));
    }

    &.tabla {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-dice-pattern.svg"));
    }

    &.santase {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-pattern.svg"));
    }

    &.bace {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-ludo-pattern.svg"));
    }

    &.blato {
        background-image: url(imageUrl("lobby/matchmaking-scroll-tile-pattern.svg"));
    }

    &.tier1 {
        background-color: #9aa7ab;
        box-shadow: inset 0 3px 3px #e1edf1, inset 0 0 12px #e1edf1;

        .mr--scroll__room--info {
            background-color: #232728;
            box-shadow: inset 0px 3px 9px 0px #0a0604;
        }

        .mr--scroll__room-name-p {
            text-shadow: 0 3px 1px #232728;
        }

        .mr--scroll__transactions {
            color: #232728;
        }
    }

    &.tier2 {
        background-color: #398ebf;
        box-shadow: inset 0 3px 3px #5ec4ff, inset 0 0 12px #5ec4ff;

        .mr--scroll__room--info {
            background-color: #001e30;
            box-shadow: inset 0px 3px 9px 0px #000504;
        }

        .mr--scroll__room-name-p {
            text-shadow: 0 3px 1px #001e30;
        }

        .mr--scroll__transactions {
            color: #001e30;
        }
    }

    &.tier3 {
        background-color: #bf8b39;
        box-shadow: inset 0 3px 3px #ffcf62, inset 0 0 12px #ffcf62;

        .mr--scroll__room--info {
            background-color: #361107;
            box-shadow: inset 0px 3px 9px 0px #110200;
        }

        .mr--scroll__room-name-p {
            text-shadow: 0 3px 1px #4c2311;
        }

        .mr--scroll__transactions {
            color: #361107;
        }
    }

    &.tier4 {
        background-color: #cf5353;
        box-shadow: inset 0 3px 3px #ff8888, inset 0 0 12px #ff8888;

        .mr--scroll__room--info {
            background-color: #430429;
            box-shadow: inset 0px 3px 9px 0px #150104;
        }

        .mr--scroll__room-name-p {
            text-shadow: 0 3px 1px #430429;
        }

        .mr--scroll__transactions {
            color: #430429;
        }
    }

    &.tier5 {
        background-color: #187a55;
        box-shadow: inset 0 3px 3px #44b188, inset 0 0 12px #44b188;

        .mr--scroll__room--info {
            background-color: #042806;
            box-shadow: inset 0px 3px 9px 0px #000301;
        }

        .mr--scroll__room-name-p {
            text-shadow: 0 3px 1px #073a09;
        }

        .mr--scroll__transactions {
            color: #042806;
        }
    }

    &.tier6 {
        background-color: #864eb9;
        box-shadow: inset 0 3px 3px #b986e7, inset 0 0 12px #b986e7;

        .mr--scroll__room--info {
            background-color: #340a51;
            box-shadow: inset 0px 3px 9px 0px #100106;
        }

        .mr--scroll__room-name-p {
            text-shadow: 0 3px 1px #340a51;
        }

        .mr--scroll__transactions {
            color: #340a51;
        }
    }

    &.tier7 {
        background-image: url(imageUrl("lobby/christmas-tile.png"));
        box-shadow: inset 0 3px 3px #ff6c6c, inset 0 0 12px #ff6c6c;

        .mr--scroll__room--info {
            background-color: #510a0a;
            box-shadow: inset 0px 3px 9px 0px #100106;
        }

        .mr--scroll__room-name-p {
            text-shadow: 0 3px 1px #500303;
        }

        .mr--scroll__transactions {
            color: #500303;
        }
    }
}

.mr--scroll__room-name {
    font-size: 3rem !important;
    color: $color__white;
    width: 100%;
    height: 20%;
    position: static !important;
    width: 100% !important;
    transform: translate(0, 0) !important;
}

.mr--scroll__room-name-p {
    color: #f6f6f6;
    font-weight: $font-weight-extra-bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
}

.mr--scroll__room--info {
    color: $color--white;
    border-radius: $border--radius;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    width: 27rem;
    height: 4.5rem;
    padding: 1rem 0;
    margin: auto;

    .mr--scroll__room--info--icon {
        color: #ffbc26;
    }
}

.mr--scroll__room--info--p {
    display: flex;
    align-items: center;
    margin: 0 .5rem;
    word-break: break-word;
}

.mr--scroll__room--info--icon {
    width: 2rem !important;
}

.mr--scroll__room--info--span {
    margin-left: 0.5rem;
    font-weight: $font-weight-bold;
    vertical-align: middle;
}

.mr--scroll-reward__label {
    font-weight: $font-weight-black !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mr--scroll-reward__value-label {
    font-weight: $font-weight-black !important;
}

.mr--scroll-bet__value-label {
    font-weight: $font-weight-black !important;
    font-size: $text__size--normal;
    margin-right: .5rem;
}

.mr--scroll-reward__value,
.mr--scroll-bet__value {
    font-weight: $font-weight-black !important;
}

.mr--scroll__restriction--cover__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black__transparent--05;
    display: none;
    border-radius: 1.2rem;

    &.mr--scroll__restriction--cover__container__not-permitted {
        display: block;
        background-color: $black__transparent--05;
    }
}

.mr--scroll__restriction--cover {
    visibility: hidden !important;
}

.mr--scroll__transactions {
    height: 25%;
    width: 90%;
    margin-bottom: 1rem;

    .mr--scroll__transactions--image {
        background-image: url(imageUrl("lobby/room_default.png"));
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 100%;
    }
}

.mr--scroll__button {
    width: 27rem !important;
    margin-left: calc((100% - 27rem) / 2) !important;
    margin-top: 1rem !important;
    margin-bottom: 7% !important;
    
    @media screen and (max-height: $break-point--1280) {
        margin-bottom: 5% !important;
    }
    
    @media screen and (max-width: $break-point--768) {
        margin-bottom: 6% !important;
    }

    &.isTouch {
        margin-bottom: 5% !important;

        @media screen and (max-width: $break-point--768) {
            margin-bottom: 7% !important;
        }
    }
}

.mr--scroll_room-second-separator {
    display: block !important;
    width: 75%;
    height: 2px;
    margin-bottom: 1rem;

    &.tier0-5 {
        background-color: #8c8075;
    }

    &.tier1 {
        background-color: #232728;
    }

    &.tier2 {
        background-color: #001e30;
    }

    &.tier3 {
        background-color: #844a30;
    }

    &.tier4 {
        background-color: #430429;
    }

    &.tier5 {
        background-color: #073a09;
    }

    &.tier6 {
        background-color: #340a51;
    }

    &.tier7 {
        background-color: #500303;
    }
}

.mr--scroll__container--wrapper-play {
    margin: 0 !important;
}

.mr--scroll__players-counter {
    padding: .5rem !important;
    color: $color--white;
    border-radius: 10rem;
    width: 7rem;
    left: 50%;
    transform: translateX(-50%);

    &.tier1 {
        background-color: #4d5b6c !important;
    }

    &.tier2 {
        background-color: #1c4e76 !important;
    }

    &.tier3 {
        background-color: #5f4d33 !important;
    }

    &.tier4 {
        background-color: #673140 !important;
    }

    &.tier5 {
        background-color: #0c4441 !important;
    }

    &.tier6 {
        background-color: #432e73 !important;
    }

    &.tier7 {
        background-color: #500303 !important;
    }
}

.mr--scroll__room--info {
    text-shadow: $mr-scroll-text-shadow;
    margin-bottom: 3rem !important;
}
