.ranked-stats-streak-text {
    color: #7ef51f;

    &.isNegative {
        color: #ff1414;
    }
}

.ranked-stats-streak--cross {
    background-color: #000000;
}

.ranked-stats-streak--cross-inner {
    background-color: #ff1414;
}