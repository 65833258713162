.player-stats__wrapper {
    @include container--colored($main);
}

.player-stats__container {
    &:not(:last-child) {
        &:after {
            @include separator(yellow);
        }
    }
}

.challenge-progress-info-box-custom-class {
    padding: 1rem 2rem;
    width: 100%;
}

.player-stats__text--title {
    &.balance {
        @media screen and (min-width:$break-point--440) {
            position: absolute;
            left: 0;
        }
    }
}

.player-stats-complete-updated-challenges-text {
    font-size: 2rem;
    text-align: center;
    visibility: hidden;
}

.player-stats-complete-updated-challenges-text-animate {
    visibility: visible;
    animation: animateCompleteUpdatedChallengesText 0.5s ease-in-out forwards;
}

@keyframes animateCompleteUpdatedChallengesText {
    0% {
        transform: scale(0);
    }

    33% {
        transform: scale(1.1);
    }

    66% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.player-stats-user-progress-container {
    padding: 0 2rem;
}

.player-stats-user-progress-container-with-transition {
    transition: transform 0.8s cubic-bezier(0.69, 0, 0, 1);
}