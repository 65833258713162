.ft__container--dropdown {
    .ft__text--dropdown-label {
      @extend %text;
      @include label-icon( 2.4rem );
      text-align: left;
    }
  }

.form-dropdown__custom-select {
    width: 25rem;
    height: 4rem;
    margin: auto;
}

.form-dropdown__custom-select__option {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: $radius--default;

    > p {
        @extend %text;
        font-size: $text__size--large;
    }

    &:hover {
        cursor: pointer;
        background-color: lighten($main, 20%);
    }
}