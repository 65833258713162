.ranked-lobby__title {
    position: relative;
    text-align: center;
    font-size: 4vh;
    text-transform: uppercase;
    padding: 0 1rem;
    font-weight: $font-weight-bold;
    text-shadow: 0 1px 0 #a97682, 0 4px 0 #270000, 0 7px 13px #000;

    @media screen and (max-width: $break-point--568) {
        font-size: 2.5rem;
    }
}

.ranked-lobby__description {
    color: #b9bffe;
    font-size: 1.6rem;
    text-shadow: 0 2px 1px #000;
    text-align: center;
    @extend %text;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.45rem;
    }
}