.shop-promo__item-content {
    border-radius: $radius--default;
}

.shop-promo__item {
    width: 45%;
    padding-bottom: 22.5%;

    @media screen and (max-width: $break-point--768) {
        width: 90%;
        padding-bottom: 45%;
    }
}