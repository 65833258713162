.bcsh-form__item-value, .bcsh-form__label {
    @extend %text;
}

.bcsh-explanation {
    text-align: center;
    @extend %text;
    font-size: $text__size--large;
}

.bcashy-form__popup {
    max-height: 80% !important;
}

.bcsh-wrapper {
    @include scrollbar();
}

.bcashy__custom-select__option {
    display: flex;
    align-items: center;
    padding: .5rem;
    border-radius: $radius--default;
    
    > p {
      @extend %text;
      font-size: 1.8rem;
    }
    
    &:hover {
      cursor: pointer;
      background-color: lighten($main, 20%);
    }
}