.gifts-tab__container--gift__default, .gifts-tab__container--gift__premium {
    width: 15.6%;
    margin: .5%;
    @media only screen and (max-width: $break-point--568) {
        width: 19%;
    }
    @media only screen and (max-width: $break-point--440) {
        width: 24%;
    }
    @media only screen and (max-width: $break-point--320) {
        width: 32.33%;
    }
    &.in-game {
        width: 7.333%;
        @media only screen and (max-width: $break-point--1366) {
            width: 9%;
        }
        @media only screen and (max-width: $break-point--1024) {
            width: 13.28%;
        }
        @media only screen and (max-width: $break-point--568) {
            width: 19%;
        }
        @media only screen and (max-width: $break-point--440) {
            width: 24%;
        }
        @media only screen and (max-width: $break-point--320) {
            width: 32.33%;
        }
    }
}

.gifts-tab__text--premium-gifts, .gifts-tab__text--premium-gifts-label {
    @extend %text;
    text-align: left;
    width: 100%;
}

.gifts-tab__text--premium-gifts {
    padding: 0 .5rem;
}