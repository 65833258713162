.game-tile__wrapper {
    border-radius: $container-border--radius;

    &.game-id__belot {
        background-image: url(imageUrl("games/belot.jpg"));
        box-shadow: inset 0 3px 3px #65cfff, inset 0 0 12px #65cfff;
    }
    &.game-id__santase {
        background-image: url(imageUrl("games/santase.jpg"));
        box-shadow: inset 0 3px 3px #7cd448, inset 0 0 12px #7cd448;
    }
    &.game-id__bace {
        background-image: url(imageUrl("games/bace.jpg"));
        box-shadow: inset 0 3px 3px #e8e5da, inset 0 0 12px #e8e5da;
    }
    &.game-id__blato {
        background-image: url(imageUrl("games/blato.jpg"));
        box-shadow: inset 0 3px 3px #9c66d0, inset 0 0 12px #9c66d0;
    }
    &.game-id__tabla {
        background-image: url(imageUrl("games/tabla.jpg"));
        box-shadow: inset 0 3px 3px #915426, inset 0 0 12px #915426;
    }
}