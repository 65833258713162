.dropdown-friends__container--friend {
    @include container--colored($main);
    transition: background-color ease 1s;

    &.highlighted {
        background-color: light($main);
    }
}

.dropdown-friends__text--username {
    color: $color__white;
    &:not( .online ) {
        &:before {
            background-color: $color__disabled;

        }
    }
    &.online {
        &:before {
            background-color: $active--color;
        }
    }
}

.dropdown-friends__container--send-message {
    min-width: 7rem;
}