/********************************
*      Buy vip box skin         *
********************************/

.vip-item--bg0 {
  background-color: transparentize($container--7, .75) !important;
}
.vip-item--bg1 {
  background-color: transparentize($container--6, .5) !important;
}
.vip-item--bg2 {
  background-color: transparentize($container--2, .8) !important;
}
.vip-item--bg3 {
  background-color: transparentize($container--1, .8) !important;
}
.vip-item--bg4 {
  background-color: transparentize($container--5, .8) !important;
}
.vip-item--bg5 {
  background-color: transparentize($container--3, .8) !important;
}
.vip-item--bg6 {
  background-color: transparentize($container--4, .8) !important;
}
