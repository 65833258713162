/****************************
*     Special emojis skin   *
****************************/

$title-background: imageUrl( 'game/emoticons/pink-gradient.png' );
// Styles
.emoji-tems__contaner--title {
  &:after {
    @include separator( orange );
  }
}

.emoji-items__container-emoji {
  &:before {
    @include separator( orange );
  }
}

.emoji-tems__contaner--title {
  position: relative;
  background-image: url( $title-background );
  background-repeat: repeat-y;
  background-size: 100% 100%;
  background-position: center;
}

.emoji-items__list {
  > .emoji-items__constainer-list {
    > .emoji-items__container--item {
      background-color: $dark-secondary;
      box-shadow: 0.2rem 0.2rem 0 0.1rem $black__transparent--03;
      border-radius: $radius--default;
    }
  }
}


