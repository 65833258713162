/***************************
*   Settings popup skin    *
***************************/

// Styles
.cg__container--reward {
  .cg__text--reward-value {
    @include chip-sign( 2.4rem );
    &:before {
      left: -2.6rem;
    }
  }
  .cg__text--reward-label {
    @extend %text;
  }
}

.cg__container--options {
  @include scrollbar();
}

.cg__container--toggle {
  .cg__text--toggle-label {
    @extend %text;
    @include label-icon( 2.4rem );
    text-align: left;
  }
}

.cg__container--options--info-desc {
    .twp__text {
        display: block !important;
    }
}

.cg__container--options--info--balance--wrapper {
    @extend %text;
}