$mr-scroll-text-shadow: 0 1px 1px rgb(0 0 0 / 40%);

.mr--scroll__tile-counter {
    font-size: $text__size--normal;
}

.mr--scroll-bet__value {
    font-size: 1.8rem;
}

.mr--scroll-reward__label {
    @extend %direction-change;
    font-size: 2.6rem !important;

    &:after {
        content: none;
    }

    .mr--scroll-reward__value {
        font-size: 2.6rem;
    }

    @media screen and (max-width: $break-point--1366) {
        font-size: 2.2rem !important;

        .mr--scroll-reward__value {
            font-size: 2.2rem !important;
        }
    }
}

.mr--scroll__room--info {
    text-shadow: $mr-scroll-text-shadow;
    margin-bottom: 5rem !important;
}

.mr--scroll__players-counter {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}

.mr--scroll__button {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 3.5rem !important;
}