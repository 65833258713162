.ranked-games-leaderboard-info-box__title--text {
    font-size: 1.1em;
    font-weight: $font-weight-normal;
}

.ranked-games-leaderboard-info-box__title, .ranked-games-leaderboard-info-box__value {
    padding: 1rem;

    @media screen and (max-width: $break-point--568) {
        padding: 0.75rem 0.5rem;
    }
}

.ranked-games-leaderboard-info-box {
    border-radius: $border--radius;
    width: 30%;
    max-width: 15rem;
    margin: 0 0.5rem;
    flex: 1 1 24%;

    @media screen and (max-width: $break-point--768) {
        margin: 1rem 0.5rem 0;
    }

    @media screen and (max-width: $break-point--480) {
        flex: 1 1 50%;
        margin: 0.5rem 0;
    }
}

.ranked-games-leaderboard-info-box__value {
    font-size: 1.8em;
    color: #c3deff;
    background-color: #101526;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.5em;
    }
}

.ranked-games-leaderboard-info-box {
    border: 0.1rem solid #f3a522;
}

.ranked-games-leaderboard-info-box__title--text {
    color: #326db5;
    font-weight: $font-weight-bold;
}

.ranked-games-leaderboard-info-box__title {
    background-color: #0c1020;
    text-transform: uppercase;
}

.ranked-games-leaderboard-info-box__delimiter {
    display: none;
    height: 2px;

    &:after {
        content: "";
        width: 100%;
        display: block;
        height: 1px;
        margin-top: 1px;
    }
}
