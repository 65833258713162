.challenge__container {
    border-radius: $border--radius;
    background-color: rgba(0, 0, 0, 0.3);
    min-height: initial;
}

.challenge__main--info__title {
    font-size: $text__size--large;
    font-weight: $font-weight-bold;
    @extend %text;
}

.challenge__rewards--container__reward {
    font-size: $text__size--large
}

.challenge__rewards--container__reward--label {
    font-weight: $font-weight-bold;
    @extend %text;
}

.challenge__current--level {
    border: 1px solid $color--chips;
}

.challenges__icon {
    color: $color--chips;
}

.challenge__progress__container {
    color: $color--chips;
}

.challenge__completed--label__container {
    font-weight: $font-weight-bold;
    font-size: $text__size--large;
    @extend %text;
}

.challenge__main--info__description {
    @extend %text;
}
