.menu--dropdown--item--wrapper-host {
    border-radius: $border--radius;
    padding: 0 1rem;
    margin: .5rem;

    &:hover {
        cursor: pointer;
        background-color: lighten($main, 20%);
    }
}
