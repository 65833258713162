.betting-game-tile__container {
    background-image: url(imageUrl("lobby/slotsBg.png"));
    background-size: cover;
}

.betting-game-tile__logo {
    background-image: url(imageUrl("lobby/slotsLogo.png"));
    background-size: contain;
    background-position: center;
}

.betting-game-tile__button__container {
    background-color: $black__transparent--07;
}

.betting-game-tile__title {
    display: none;
}

.betting-game-tile__button {
    height: 3.5rem !important;
    width: 12rem !important;

    @media only screen and (max-width: $break-point--768), (max-height: $break-point--440) {
        min-height: initial !important;
        height: 2rem !important;
    }
}

.betting-game-tile__button-bclass {
    @media only screen and (max-height: $break-point--440) {
        > p {
            font-size: $text__size--normal !important;
        }
    }
}