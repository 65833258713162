.shop-bank-transfer__container--title {
    > p {
        @extend %text;
        color: $accent--1;
    }
}

.shop-bank-transfer__container--packs {
    @extend %shop-wrapper;
    margin: 0 !important;

    .shop-bank-transfer__bank-pack {
        @extend %shop-box;
    }
}
