.club--notification--simple--text-p {
    @extend %text;
    font-size: $text__size--large;
    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--normal;
    }
    > span {
        color: $color--chips;
    }

    @if ($skin-font-family == "Cairo") {
        line-height: 1;
    }
}
