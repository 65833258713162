/***********************
*   Button timer skin  *
***********************/
.daily-bonus-box__container--day-container {
    border-radius: $radius--default;

    &:not(.collected-reward) {
        background-color: $black__transparent--02;

        .day-container__collected-overlay-wrapper {
            display: none;
        }
    }

    &.current-reward {
        border: 0.2rem solid $color--selected;
    }
}

.day-container__collected-overlay-wrapper {
    background-color: $black__transparent--03;
}

.button-timer__container--button {
    @extend %text;
    background-color: $black__transparent--02;
    border-radius: $border--radius;
}

.button-timer__timer {
    font-size: $text__size--normal !important;
    color: $color__white !important;
}
