@mixin curved-container-component($border-color, $bg-color) {
    --ranked-games-curved-border-color: #{$border-color};
    --ranked-games-curved-bg-color: #{$bg-color};
}

.curved-container-path__class {
    &.background {
        fill: var(--ranked-games-curved-bg-color) !important;
    }

    &.border {
        fill: var(--ranked-games-curved-border-color) !important;
    }
}

