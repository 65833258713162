.level-up-rewards--content {
    overflow: auto;
    padding: 0 1.5rem;
    @include scrollbar();
}

@media screen and (max-width: $break-point--440) {
    .level-up-rewards--content {
        padding: 0 1rem;
    }
}