/***********************
* Settings popup skin  *
***********************/

.settings-option__wrapper {
    .settings-option__top-section {
        > .option-name {
            .option-container {
                > p {
                    @extend %text;
                    color: $color__white;
                }

                > .settings-option__hint-inside {
                    > p {
                        @extend %text;
                        color: $white__transparent--05;
                    }
                }
            }

            .settings-option__icon {
                color: $color__white;
                font-size: $text__size--x-large;
                margin-right: .5rem;
            }
        }
    }

    > .settings-option__hint-outside {
        > p {
            @extend %text;
            color: $white__transparent--05;
        }
    }
}

.flag {
    display: inline-block;
    width: 4.2rem;
    height: 3.2rem;
    margin: 0 .5rem;
    border-radius: 3rem;
    box-shadow: 0.1rem 0.1rem 0.2rem #111;
    background-color: $dark-secondary;

    &:hover {
        cursor: pointer;
    }

    &.current__lang {
        position: relative;
        box-shadow: 0.1rem 0.1rem 0.2rem #111;
        background-color: $active--color
    }

    > p {
        line-height: 3.2rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: $text__size--large;
    }
}

.settings-option__custom-select__icon {
    width: 2rem;
    margin-right: 1rem;
}

.settings-option__custom-select__option {
    display: flex;
    align-items: center;
    padding: .5rem;
    border-radius: $radius--default;

    > p {
        @extend %text;
        font-size: $text__size--large;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &:hover {
        cursor: pointer;
        background-color: lighten($main, 20%);
    }
}

.settings-option__custom-select {
    width: 20rem;
    height: 4rem;
    @media screen and (max-width:$break-point--440) {
        width: 15rem;
    }
    @media screen and (max-width:$break-point--320) {
        width: 14rem;
    }
}