$font-size: 1.8rem;

.cashback--tile__content--container {
    background-image: url(imageUrl("lobby/cashBack.png"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.cashback--tile__text--container {
    background-color: $black__transparent--07;
    &.with__progress-bar {
        display: none;
    }
}

.cashback--tile__timer {
    font-size: $font-size !important;
    color: $color__white;

    @media only screen and (max-width: $break-point--768), (max-height: $break-point--440) {
        font-size: $text__size--normal !important;
    }
}

.cashback--tile__button-bclass {
    @media only screen and (max-height: $break-point--440) {
        > p {
            font-size: $text__size--normal !important;
        }
    }
}

.cashback--tile__progress {
    max-width: 95%;
    position: relative;
    margin: auto auto 1rem auto;
    height: 2.5rem;
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--568), (max-height: $break-point--568){
        height: 1.8rem;
        max-width: 85%;
        font-size: $text__size--smaller;
    }
}

.cashback--tile__badge {
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--smaller;
    }
}