.tut__popup--separator {
    @include separator(yellow);
    position: static;
    margin: 0.5rem 0;
}

.tut__popup--button {
    width: 50% !important;
    display: flex !important;
    justify-content: center !important;
}

.tut__text-content-p {
    @extend %text;
}