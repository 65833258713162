.menu--dropdown__elements--container {
    background-color: transparentize($main, .05);
}

.menu--dropdown__toggle--button--opened {
    color: $color--selected !important;
    margin: 0;
    width: 4rem;
}

