// Styles
.leaderboards-popup__container--content {
    @include scrollbar();
}

.lp__wrapper {
    z-index: 999 !important;
}

.leaderboards-popup__top-tabs__bclass {
    background-color: #151835 !important;
    height: 4rem;
    margin: 0 .5rem;
    width: 13rem;
    transition: none !important;
    > p {
        font-size: $text__size--normal;
    }
    &.top-tabs__selected {
        background-color: $color--chips !important;
    }
    @media screen and (max-width: $break-point--768) {
        height: 3.5rem;
    }
    @media screen and (max-width: $break-point--568) {
        width: 11rem;
    }
}

.leaderboards-popup-top-tabs__list-item--navigation {
    width: 100%;
    @media screen and (max-width: $break-point--568) {
        width: 8rem;
    }
}
