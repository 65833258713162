.creator-code__container {
    width: 80%;
    margin: 10rem 10% 2rem;
    padding: 10rem 2rem 0;
    background-color: #21386be6;
    border-radius: 0.8rem;
    @media only screen and (max-width: $break-point--568) {
        width: 90%;
        margin: 10rem 5% 2rem;
    }
    @media only screen and (max-width: $break-point--480) {
        padding: 10rem .5rem 0;
    }

    @media only screen and (max-width: $break-point--380) {
        margin: 7rem 5% 2rem;
    }
}

.creator-code__input {
    border: none !important;
    background-color: $color__white !important;
    color: $color__black;
    font-weight: $font-weight-bold;
    box-shadow: none !important;
    padding: 0 !important;
    text-align: center !important;
    @media only screen and ( max-width: $break-point--768) {
        padding: 0 !important;
    }
    &:focus {
        color: $color__black;
    }
}

.creator-code__icon {
    background-image: url(imageUrl("shop/affiliate-code_icon.png"));
    width: 50%;
    @media only screen and ( max-width: $break-point--568) {
        width: 40%;
    }
}

.creator-code__label {
    color: $color__white;
    font-weight: $font-weight-bold;
    margin: 0 6rem 2rem;
    @extend %text;

    @media only screen and (max-width: $break-point--568) {
        margin: 0 0 2rem;
    }
}

.creator-code__container--form {
    width: 60%;
    @media only screen and ( max-width: $break-point--568) {
        width: 75%;
    }

    @media only screen and ( max-width: $break-point--380) {
        width: 90%;
    }
}

.creator-code__time-left {
    color: $color__chips;
    @extend %text;
}

.creator-code__info {
    font-size: 1.6rem;
    @extend %text;
}

.creator-code__container--form__button__timer {
    font-size: 1em !important;
    color: $color__white !important;
}
